import { MinusCircleIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ArrowPathIcon, PencilIcon, TrashIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import InputModal from '../Common/InputModal';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import Popup from '../Common/Popup';
import axios from 'axios';
import { API_URL } from '../../imports'
import FetchingDataLoader from '../Common/FetchingDataLoader';
import { handleApiError } from '../Common/APIUtils';
import { formattedDate } from '../Common/CommonUtils';

const FieldsConfiguration = ({fields, setFields, processes, setSelectedProcess, edit}) => {

  const handleAddField = () => {
    setFields([
      ...fields,
      { key: "", type: "text", defaultValue: "", required: false },
    ]);
  };

  const handleFieldChange = (index, name, value) => {
    const updatedFields = [...fields];

    if (name === "type") {
      if (value === "select") {
        updatedFields[index] = {
          ...updatedFields[index],
          type: value,
          options: { captions: true, values: [] },
          defaultValue: "",
        };
      } else if (value === "boolean") {
        updatedFields[index] = {
          ...updatedFields[index],
          type: value,
          defaultValue: "",
        };
      } else {
        // Reset options for non-select types
        const { options, ...rest } = updatedFields[index];
        updatedFields[index] = { ...rest, type: value, defaultValue: "" };
      }
    } else if (name === "optionsType") {
      updatedFields[index].options.captions = value === "captions";
      updatedFields[index].options.values = [];
    } else if (name === "required") {
      updatedFields[index].required = value;
    } else {
      updatedFields[index][name] = value;
    }
    console.log(updatedFields)
    setFields(updatedFields);
  };

  const handleAddOption = (index, optionKey, optionValue) => {
    const updatedFields = [...fields];
    const options = updatedFields[index].options.values;

    if (updatedFields[index].options.captions) {
      options.push({ [optionKey]: optionValue });
    } else {
      options.push(optionValue);
    }

    updatedFields[index].options.values = options;
    setFields(updatedFields);
  };

  const handleRemoveOption = (fieldIndex, optionIndex) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].options.values.splice(optionIndex, 1);
    setFields(updatedFields);
  };

  const handleRemoveField = (indexToRemove) => {
    setFields(fields.filter((_, index) => index !== indexToRemove));
  };

  const toggleNestedFields = (index) => {
    const updatedFields = [...fields];
    if (updatedFields[index].nestedFields !== undefined && updatedFields[index].nestedFields !== null) {
      // Remove `nestedFields` key
      delete updatedFields[index].nestedFields;
    }
    else {
      // Add `nestedFields` key with default values
      updatedFields[index].nestedFields = [
        { key: '', type: 'text', required: false, defaultValue: '' }
      ];
    }
    setFields(updatedFields);
  };
  

  const handleNestedFieldChange = (fieldIndex, nestedIndex, name, value) => {
    const updatedFields = [...fields];
    const nestedField = updatedFields[fieldIndex].nestedFields[nestedIndex];
    
    if (name === "type") {
      if (value === "select") {
        updatedFields[fieldIndex].nestedFields[nestedIndex] = {
          ...nestedField,
          type: value,
          options: { captions: true, values: [] },
          defaultValue: "",
        };
      } else if (value === "boolean") {
        updatedFields[fieldIndex].nestedFields[nestedIndex]  = {
          ...nestedField,
          type: value,
          defaultValue: "",
        };
      } else {
        // Reset options for non-select types
        const { options, ...rest } = nestedField;
        updatedFields[fieldIndex].nestedFields[nestedIndex]  = { ...rest, type: value, defaultValue: "" };
      }
    } else if (name === "optionsType") {
      nestedField.options.captions = value === "captions";
      nestedField.options.values = [];
    } else if (name === "required") {
      nestedField.required = value;
    } else {
      nestedField[name] = value;
    }
    console.log(updatedFields)
    setFields(updatedFields);
  };

  const addNestedField = (fieldIndex) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].nestedFields.push({ key: '', type: 'text', required: false, defaultValue: '' });
    setFields(updatedFields);
  };

  
  const handleAddNestedOption = (index, nestedIndex, optionKey, optionValue) => {
    console.log(index, nestedIndex, optionKey, optionValue)
    const updatedFields = [...fields];
    const options = updatedFields[index].nestedFields[nestedIndex].options.values;

    if (updatedFields[index].nestedFields[nestedIndex].options.captions) {
      options.push({ [optionKey]: optionValue });
    } else {
      options.push(optionValue);
    }

    console.log(options)
    updatedFields[index].nestedFields[nestedIndex].options.values = options;
    setFields(updatedFields);
  };

  const handleRemoveNestedOption = (fieldIndex, nestedIndex, optionIndex) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].nestedFields[nestedIndex].options.values.splice(optionIndex, 1);
    setFields(updatedFields);
  };

  const handleRemoveNestedField = (fieldIndex, nestedIndexToRemove) => {
    const updatedFields = [...fields];
    // Remove the nested field from the nestedFields array of the specified field
    updatedFields[fieldIndex].nestedFields = updatedFields[fieldIndex].nestedFields.filter(
      (_, nestedIndex) => nestedIndex !== nestedIndexToRemove
    );
    setFields(updatedFields);
  };
  

  const handleSubmit = () => {
    console.log("Submitted Fields:", fields);
  };

  return (
    <div className="font-Geist h-[calc(100dvh-20rem)] overflow-y-auto pt-3 pb-5">
      {!edit && processes.length>1 && <div className="mb-5 w-full px-4">
          <label
              htmlFor="name"
              className="block text-sm tracking-wider text-gray-500 mb-1"
          >
              Process
          </label>
          <select
              id="process"
              name="process"
              className="block w-full max-w-full rounded-md border-0 py-2 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-gray-300 text-sm"
              onChange={(e) =>
                  setSelectedProcess(e.target.value)
              }
          >
              <option value="">Select Process</option>
              {processes.slice(1).map((process, index) => (
                  <option
                      key={index}
                      value={process.process_code}
                  >
                      {process.process_name}
                  </option>
              ))}
          </select>
      </div>}
      <label className="block text-sm tracking-wider text-gray-500 px-4 mb-1">Fields</label>
      <form
        className="space-y-3 px-4"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {fields.map((field, index) => (
          <div key={index} className="p-4 pb-5 border rounded-lg bg-gray-100">
            <div className="flex flex-wrap gap-4 items-center">
              <div className="flex w-full gap-3 items-end">
                <div className="flex flex-col w-8/12">
                  <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                    Field Name (Key)
                  </label>
                  <input
                    type="text"
                    className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 text-sm"
                    value={field.key}
                    onChange={(e) =>
                      handleFieldChange(index, "key", e.target.value)
                    }
                  />
                </div>
                <div className="flex flex-col w-4/12">
                  <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                    Field Type
                  </label>
                  <select
                    className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-xs text-sm"
                    value={field.type}
                    onChange={(e) =>
                      handleFieldChange(index, "type", e.target.value)
                    }
                  >
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="boolean">Boolean</option>
                    <option value="select">Select</option>
                    <option value="dict">Dictionary</option>
                    <option value="array">Array</option>
                  </select>
                </div>
              </div>
              {field.type === "dict" && field.nestedFields && field.nestedFields.length>0 && field.nestedFields.map((nestedfield, nestedfieldindex) => (
                <div key={nestedfieldindex} className="p-4 pb-5 border rounded-lg bg-white">
                  <div className="flex flex-wrap gap-4 items-center">
                    <div className="flex w-full gap-3 items-end">
                      <div className="flex flex-col w-8/12">
                        <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                          Field Name (Key)
                        </label>
                        <input
                          type="text"
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 text-sm"
                          value={nestedfield.key}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "key", e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col w-4/12">
                        <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                          Field Type
                        </label>
                        <select
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-xs text-sm"
                          value={nestedfield.type}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "type", e.target.value)
                          }
                        >
                          <option value="text">Text</option>
                          <option value="number">Number</option>
                          <option value="boolean">Boolean</option>
                          <option value="select">Select</option>
                          <option value="dict">Dictionary</option>
                          <option value="array">Array</option>
                        </select>
                      </div>
                    </div>
                    {nestedfield.type === "select" && (
                      <>
                        <div className="flex flex-col w-full">
                          <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                            Options Type
                          </label>
                          <select
                            className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                            onChange={(e) =>
                              handleNestedFieldChange(index, nestedfieldindex, "optionsType", e.target.value)
                            }
                            value={nestedfield.options.captions ? "captions" : "noCaptions"}
                          >
                            <option value="captions">With Title</option>
                            <option value="noCaptions">Without Title</option>
                          </select>
                        </div>
                        <div className="flex flex-col w-full">
                          <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600 mt-1">
                            Add Options
                          </label>
                          <div className="flex gap-2">
                            {nestedfield.options.captions ? (
                              <>
                                <input
                                  type="text"
                                  placeholder="Title"
                                  className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-xs text-sm"
                                  id={`caption-${index}-${nestedfieldindex}`}
                                />
                                <input
                                  type="text"
                                  placeholder="Value"
                                  className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-xs text-sm"
                                  id={`value-${index}-${nestedfieldindex}`}
                                />
                                <button
                                  type="button"
                                  className="inline-flex tracking-wider justify-center rounded-lg border border-blue-900 bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                  onClick={() =>
                                    handleAddNestedOption(
                                      index,
                                      nestedfieldindex,
                                      document.getElementById(`caption-${index}-${nestedfieldindex}`)
                                        .value,
                                      document.getElementById(`value-${index}-${nestedfieldindex}`).value
                                    )
                                  }
                                >
                                  New
                                </button>
                              </>
                            ) : (
                              <input
                                type="text"
                                placeholder="Value"
                                className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                                id={`value-only-${index}-${nestedfieldindex}`}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" && e.target.value.trim()) {
                                    e.preventDefault();
                                    handleAddNestedOption(index, nestedfieldindex, null, e.target.value);
                                    e.target.value = "";
                                  }
                                }}
                              />
                            )}
                          </div>
                          <ul className="mt-2">
                            {nestedfield.options.values.map((noption, noptIndex) => (
                              <li
                                key={noptIndex}
                                className="flex justify-between items-center p-2 border rounded-md bg-white mb-2"
                              >
                                {nestedfield.options.captions
                                  ? `${Object.keys(noption)[0]}: ${
                                      Object.values(noption)[0]
                                    }`
                                  : noption}
                                <button
                                  type="button"
                                  className="text-gray-500"
                                  onClick={() => handleRemoveNestedOption(index, nestedfieldindex, noptIndex)}
                                >
                                  <XMarkIcon className="h-4 w-4" strokeWidth={2} />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="flex flex-col w-full">
                          <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                            Default Value
                          </label>
                          <select
                            className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                            value={nestedfield.defaultValue}
                            onChange={(e) =>
                              handleNestedFieldChange(index, nestedfieldindex, "defaultValue", e.target.value)
                            }
                          >
                            <option value="">Select a default value</option>
                            {nestedfield.options.values.map((noption, noptIndex) => (
                              <option
                                key={noptIndex}
                                value={
                                  nestedfield.options.captions
                                    ? Object.values(noption)[0]
                                    : noption
                                }
                              >
                                {nestedfield.options.captions
                                  ? Object.keys(noption)[0]
                                  : noption}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                    {nestedfield.type === "boolean" && (
                      <div className="flex flex-col w-full">
                        <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                          Default Value
                        </label>
                        <select
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                          value={nestedfield.defaultValue}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "defaultValue", e.target.value)
                          }
                        >
                          <option value="">Select a default value</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      </div>
                    )}
                    {nestedfield.type === "number" && (
                      <div className="flex flex-col w-full">
                        <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                          Default Value
                        </label>
                        <input
                          type="number"
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                          value={nestedfield.defaultValue}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "defaultValue", e.target.value)
                          }
                          placeholder="Enter default number"
                        />
                      </div>
                    )}
                    {nestedfield.type === "dict" || nestedfield.type === "array" ? (
                      <div className="flex flex-col w-full">
                        <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                          Default Value
                        </label>
                        <textarea
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                          value={nestedfield.defaultValue}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "defaultValue", e.target.value)
                          }
                          placeholder={`Enter default ${nestedfield.type}`}
                        />
                      </div>
                    ) : nestedfield.type === "text" ? (
                      <div className="flex flex-col w-full">
                        <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                          Default Value
                        </label>
                        <input
                          type="text"
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                          value={nestedfield.defaultValue}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "defaultValue", e.target.value)
                          }
                          placeholder="Enter default text"
                        />
                      </div>
                    ) : null}
                    <div className="flex justify-end mt-1 w-full gap-2">
                      {field.nestedFields.length-1===nestedfieldindex && <button
                        type="button"
                        className="inline-flex tracking-wider justify-center rounded-lg border border-blue-900 bg-blue-50 px-3 py-2 text-sm font-medium text-blue-900 focus:outline-none focus-visible:ring-0 cursor-default lg:cursor-pointer"
                        onClick={()=>addNestedField(index)}
                      >
                        Add Nested Field
                      </button>}
                      <div className="flex items-center gap-2 bg-white p-2 ring-1 ring-gray-300 rounded-md w-fit">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border text-indigo-600 focus:ring-gray-300"
                          checked={nestedfield.required}
                          onChange={(e) =>
                            handleNestedFieldChange(index, nestedfieldindex, "required", e.target.checked)
                          }
                        />
                        <span className="text-xs lg:text-sm tracking-wider text-gray-600">
                          Required
                        </span>
                      </div>
                      <button
                        onClick={() => handleRemoveNestedField(index, nestedfieldindex)}
                        className="inline-flex tracking-wider justify-center items-center rounded-lg border border-red-600 bg-red-50 p-2 text-sm font-medium text-red-600 focus:outline-none focus-visible:ring-0 cursor-default lg:cursor-pointer"
                      >
                        <MinusCircleIcon
                          className="h-4 w-4 text-red-600"
                          strokeWidth={2}
                        />
                        &nbsp;Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {field.type === "select" && (
                <>
                  <div className="flex flex-col w-full">
                    <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                      Options Type
                    </label>
                    <select
                      className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                      onChange={(e) =>
                        handleFieldChange(index, "optionsType", e.target.value)
                      }
                      value={field.options.captions ? "captions" : "noCaptions"}
                    >
                      <option value="captions">With Title</option>
                      <option value="noCaptions">Without Title</option>
                    </select>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600 mt-1">
                      Add Options
                    </label>
                    <div className="flex gap-2">
                      {field.options.captions ? (
                        <>
                          <input
                            type="text"
                            placeholder="Title"
                            className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-xs text-sm"
                            id={`caption-${index}`}
                          />
                          <input
                            type="text"
                            placeholder="Value"
                            className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-xs text-sm"
                            id={`value-${index}`}
                          />
                          <button
                            type="button"
                            className="inline-flex tracking-wider justify-center rounded-lg border border-blue-900 bg-blue-100 px-3 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                            onClick={() =>
                              handleAddOption(
                                index,
                                document.getElementById(`caption-${index}`)
                                  .value,
                                document.getElementById(`value-${index}`).value
                              )
                            }
                          >
                            New
                          </button>
                        </>
                      ) : (
                        <input
                          type="text"
                          placeholder="Value"
                          className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                          id={`value-only-${index}`}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && e.target.value.trim()) {
                              e.preventDefault();
                              handleAddOption(index, null, e.target.value);
                              e.target.value = "";
                            }
                          }}
                        />
                      )}
                    </div>
                    <ul className="mt-2">
                      {field.options.values.map((option, optIndex) => (
                        <li
                          key={optIndex}
                          className="flex justify-between items-center p-2 border rounded-md bg-white mb-2"
                        >
                          {field.options.captions
                            ? `${Object.keys(option)[0]}: ${
                                Object.values(option)[0]
                              }`
                            : option}
                          <button
                            type="button"
                            className="text-gray-500"
                            onClick={() => handleRemoveOption(index, optIndex)}
                          >
                            <XMarkIcon className="h-4 w-4" strokeWidth={2} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col w-full">
                    <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                      Default Value
                    </label>
                    <select
                      className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                      value={field.defaultValue}
                      onChange={(e) =>
                        handleFieldChange(index, "defaultValue", e.target.value)
                      }
                    >
                      <option value="">Select a default value</option>
                      {field.options.values.map((option, optIndex) => (
                        <option
                          key={optIndex}
                          value={
                            field.options.captions
                              ? Object.values(option)[0]
                              : option
                          }
                        >
                          {field.options.captions
                            ? Object.keys(option)[0]
                            : option}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {field.type === "boolean" && (
                <div className="flex flex-col w-full">
                  <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                    Default Value
                  </label>
                  <select
                    className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                    value={field.defaultValue}
                    onChange={(e) =>
                      handleFieldChange(index, "defaultValue", e.target.value)
                    }
                  >
                    <option value="">Select a default value</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              )}
              {field.type === "number" && (
                <div className="flex flex-col w-full">
                  <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                    Default Value
                  </label>
                  <input
                    type="number"
                    className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                    value={field.defaultValue}
                    onChange={(e) =>
                      handleFieldChange(index, "defaultValue", e.target.value)
                    }
                    placeholder="Enter default number"
                  />
                </div>
              )}
              {field.type === "dict" || field.type === "array" ? (
                <div className="flex flex-col w-full">
                  <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                    Default Value
                  </label>
                  <textarea
                    className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                    value={field.defaultValue}
                    onChange={(e) =>
                      handleFieldChange(index, "defaultValue", e.target.value)
                    }
                    placeholder={`Enter default ${field.type}`}
                  />
                </div>
              ) : field.type === "text" ? (
                <div className="flex flex-col w-full">
                  <label className="text-xs xl:text-sm tracking-wider ms-1 mb-0.5 text-gray-600">
                    Default Value
                  </label>
                  <input
                    type="text"
                    className="block w-auto md:w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:max-w-full text-sm"
                    value={field.defaultValue}
                    onChange={(e) =>
                      handleFieldChange(index, "defaultValue", e.target.value)
                    }
                    placeholder="Enter default text"
                  />
                </div>
              ) : null}
              <div className="flex justify-end mt-1 w-full gap-2">
              {field.type === "dict" &&
               <button
               type="button"
               className={`inline-flex tracking-wider justify-center items-center rounded-lg border ${(!field.nestedFields || field.nestedFields.length<0)?'border-blue-900 bg-blue-50 text-blue-900 focus-visible:ring-blue-500 focus-visible:ring-2 focus-visible:ring-offset-2':'border-red-600 bg-red-50 text-red-600 focus-visible:ring-0'} px-3 py-2 text-sm font-medium  focus:outline-none cursor-default lg:cursor-pointer`}
               onClick={()=>toggleNestedFields(index)}
             >
               {(field.nestedFields && field.nestedFields.length>0)?<><MinusCircleIcon
                    className="h-4 w-4 text-red-600"
                    strokeWidth={2}
                  />
                  &nbsp;Remove All Nested Fields</>:'Add Nested Field'}
             </button>}
                {(fields.length-1===index) && <button
                  type="button"
                  className="inline-flex tracking-wider justify-center items-center rounded-lg border border-blue-600 bg-blue-50 p-2 text-sm font-medium text-blue-600 focus:outline-none focus-visible:ring-0 cursor-default lg:cursor-pointer"
                  onClick={handleAddField}
                >
                  Add Field
                </button>}
                <div className="flex items-center gap-2 bg-white p-2 ring-1 ring-gray-300 rounded-md w-fit">
                  <input
                    type="checkbox"
                    className="h-4 w-4 rounded border text-indigo-600 focus:ring-gray-300"
                    checked={field.required}
                    onChange={(e) =>
                      handleFieldChange(index, "required", e.target.checked)
                    }
                  />
                  <span className="text-xs lg:text-sm tracking-wider text-gray-600">
                    Required
                  </span>
                </div>
                <button
                  onClick={() => handleRemoveField(index)}
                  className="inline-flex tracking-wider justify-center items-center rounded-lg border border-red-600 bg-red-50 p-2 text-sm font-medium text-red-600 focus:outline-none focus-visible:ring-0 cursor-default lg:cursor-pointer"
                >
                  <MinusCircleIcon
                    className="h-4 w-4 text-red-600"
                    strokeWidth={2}
                  />
                  &nbsp;Remove Field
                </button>
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default function DynamicParamConfiguration() {
  let [isOpen, setIsOpen] = useState(false)
  let [edit, setEdit] = useState(false);
  const [Loader, setLoader] = useState(true)
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  const [fields, setFields] = useState([{ key: "", type: "text", defaultValue: "", required: false }]);
  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState("");

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  function closeModal() {
    setIsOpen(false);
    setSelectedProcess("");
    setFields([]);
  }

  function openModal(e, option) {
    if (option === 'edit') {
      setSelectedProcess(e.process_code);
      axios.get(`${API_URL}/processes/fields/${e.process_code}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(
                "access_token"
            )}`,
        },
      })
      .then((response) => {
          setFields(response.data.data);
      });
      setEdit(true);
    }
    else if (option === 'create') {
      setSelectedProcess('');
      setFields([{ key: "", type: "text", defaultValue: "", required: false }]);
      setEdit(false);
    }
    setIsOpen(true);
  }

  const ReloadData = async (loadprops) => {
    setLoader(loadprops);
    axios.get(`${API_URL}/processes/fields`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem(
                "access_token"
            )}`,
        },
    })
    .then((response) => {
        setData(response.data.data);
        setProcesses(response.data.not_have_fields);
    })
    .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
    })
    .finally(() => {
      setTimeout(() => {
        setLoader(false);
      }, 100);
    });
  };

  const handleCreateState = async () => {
    setIsOpen(false);
    try {
      await axios
        .patch(`${API_URL}/processes/fields/${selectedProcess}`, {fields: fields}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const handleEditState = async () => {
    try {
      await axios
        .patch(`${API_URL}/processes/fields/${selectedProcess}`, {fields: fields}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpen(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpen(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
    setSelectedProcess("");
  }

  function openConfirmPopup(e) {
    setSelectedProcess(e.process_code);
    setConfirmationMsg(`Are you sure that you want to delete - ${e.process_name} fields configuration?`);
    setIsOpenConfirmPopup(true);
  }

  const handleDeleteState = async () => {
    try {
      await axios
        .delete(`${API_URL}/processes/fields/${selectedProcess}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenConfirmPopup(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenConfirmPopup(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'index',
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: '#',
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'process_name',
        enableColumnFilter: false,
        size: 700,
        header: () => <span>Process</span>,
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter: false,
        size: 50,
        header: 'Created Date',
        cell: ({ row }) => (
          <div>{formattedDate(row.original.creation_date)}</div>
        )
      },,
      {
        accessorKey: 'modification_date',
        enableColumnFilter: false,
        size: 50,
        header: 'Modified Date',
        cell: ({ row }) => (
          <div>{formattedDate(row.original.modification_date)}</div>
        )
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
            <button className="p-1 rounded-md border" onClick={() => openModal(row.original, 'edit')}>
              <PencilIcon className='h-4 text-blue-800' />
            </button>
            <button className="p-1 rounded-md border" onClick={() => openConfirmPopup(row.original)}>
              <TrashIcon className='h-4 text-red-600' />
            </button>
          </div>
        )
      },
    ],
    []
  );

  const [data, setData] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const tableContainerRef = React.useRef(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: rows.length,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const renderTableCellIndex = (row) => {
    return (row.index + 1);
  };

  useEffect(() => {
    axios
        .get(`${API_URL}/processes/fields`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                )}`,
            },
        })
        .then((response) => {
            setData(response.data.data);
            setProcesses(response.data.not_have_fields);
        })
        .catch((error) => {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
  }, []);


  return (
    <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={handleDeleteState}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <InputModal isOpen={isOpen} closeModal={closeModal} title={edit?"Update Fields":"Configure Fields"} savefn={edit === false ? handleCreateState : handleEditState}
        modalInputs={
          <FieldsConfiguration fields={fields} setFields={setFields} processes={processes} setSelectedProcess={setSelectedProcess} edit={edit}/>
        }
        maxw="max-w-2xl"
        outerTouchClose={false}
        inputcls="px-0"
      />
      <div className='flex justify-end m-3 gap-2'>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => ReloadData(true)}>
            <ArrowPathIcon className='h-5 text-green-700' />
          </button>
          {processes.length>1 && <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => openModal('e', 'create')}>
            <PlusCircleIcon className='h-5' />
          </button>}
      </div>
      {Loader && <FetchingDataLoader />}
      {!Loader &&
        <>
          <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto'>
            <table className='flex-inline w-full border-collapse'>
              <thead className='shadow bg-gray-100'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className='border p-2 whitespace-nowrap'
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={`${header.column.getCanSort()
                                  ? 'cursor-pointer select-none justify-start'
                                  : 'justify-center'
                                  } flex`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] || null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  rows.length === 0 ?
                    (
                      <tr>
                        <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                          No Data Found
                        </td>
                      </tr>
                    )
                    :
                    (
                      <>
                        {paddingTop > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingTop}px` }} />
                          </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                          const row = rows[virtualRow.index];
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td className='border p-2 whitespace-nowrap' key={cell.id}>
                                    {cell.column.id === "index" && (
                                      <center>{renderTableCellIndex(row)}</center>
                                    )}
                                    {cell.column.id !== "index" &&
                                      flexRender(
                                        cell.column
                                          .columnDef
                                          .cell,
                                        cell.getContext()
                                      )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {paddingBottom > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
                          </tr>
                        )}
                      </>
                    )
                }
              </tbody>
            </table>
          </div>
        </>
      }
    </div>
  );
}
