import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedUniqueValues
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ArrowPathIcon, PencilIcon, TrashIcon, PlusCircleIcon, FunnelIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import InputModal from '../Common/InputModal';
import { initialpageSize } from '../Common/Contants';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import Popup from '../Common/Popup';
import axios from 'axios';
import { API_URL } from '../../imports'
import { DebouncedInput, StatusAdminFilter } from '../Common/Filters';
import FetchingDataLoader from '../Common/FetchingDataLoader';
import { handleApiError } from '../Common/APIUtils';
import { RiLockPasswordLine } from "react-icons/ri";
import PageSizeDropdown from '../Common/PageSizeDropdown';
import { formattedDate } from '../Common/CommonUtils';
import { Menu, Transition } from '@headlessui/react';
import { Portal } from "react-portal";
import Pagination from '../Common/Pagination';

export default function UserMgmt() {
  let [isOpen, setIsOpen] = useState(false)
  let [rowData, setRowData] = useState([])
  let [edit, setEdit] = useState(false);
  const [Loader, setLoader] = useState(true)
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [activeRole, setActiveRole] = useState([])
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0 });
  const [activeDropdown, setActiveDropdown] = useState(null);
  const buttonRefs = useRef({});
  const dropdownRef = useRef(null);

  const calculatePosition = useCallback((id) => {
    const buttonRef = buttonRefs.current[id];
    if (buttonRef) {
      const rect = buttonRef.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
      });
      setActiveDropdown((prev) => (prev === id ? null : id));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    const handleKeyDown = (event) => {
      const keysToCloseDropdown = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp'];
      if (keysToCloseDropdown.includes(event.key)) {
        setActiveDropdown(null);
      }
    };
    const handleScroll = () => {
      setActiveDropdown(null);
    };
    if (activeDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [activeDropdown]);


  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  function closeModal() {
    setIsOpen(false);
    setRowData([]);
  }

  function openModal(e, option) {
    setRowData(e);
    if (option === 'edit') {
      setEdit(true);
    }
    else if (option === 'create') {
      setEdit(false);
    }
    setIsOpen(true);
  }

  const ReloadData = async (loadprops) => {
    setLoader(loadprops);
    axios
      .get(`${API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          client: sessionStorage.getItem('client')
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        openPopup();
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 100);
      });
  };

  const handleCreateState = async () => {
    setIsOpen(false);
    let create_form = { email: rowData.email, name: rowData.name, gender: rowData.gender, ...(localStorage.getItem('role') === 'ADMIN' && { role_code: rowData.role_code }) };
    try {
      await axios
        .post(`${API_URL}/users`, create_form, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          },
        })
        .then((response) => {
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const handleEditState = async () => {
    let statu = { email: rowData.email, name: rowData.name, gender: rowData.gender, ...(localStorage.getItem('role') === 'ADMIN' && { role_code: rowData.role_code }) };
    try {
      await axios
        .patch(`${API_URL}/users/${rowData.id}`, statu, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          },
        })
        .then((response) => {
          setIsOpen(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpen(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeStatusModal() {
    setIsOpenStatusModal(false);
  }

  function openStatusModal(e) {
    setRowData(e);
    setModalTitle(`Update User Status - ${e.user_id}`)
    setIsOpenStatusModal(true);
  }

  const handleStatusState = async () => {
    let statusData = { status: rowData.status === false || rowData.status === 'false' ? false : true };
    try {
      await axios
        .patch(`${API_URL}/users/status/${rowData.id}`, statusData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          },
        })
        .then((response) => {
          setIsOpenStatusModal(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenStatusModal(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [resetPasswordState, setResetPasswordState] = useState(false);

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(e, option) {
    setRowData(e);
    if (option === 'resetpassword') {
      setResetPasswordState(true);
      setConfirmationMsg(`Are you sure that you want to reset password for ${e.user_id} - ${e.name}?`);
    }
    else {
      setResetPasswordState(false);
      setConfirmationMsg(`Are you sure that you want to delete ${e.user_id} - ${e.name}?`);
    }
    setIsOpenConfirmPopup(true);
  }

  const handleDeleteState = async () => {
    try {
      await axios
        .delete(`${API_URL}/users/${rowData.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenConfirmPopup(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenConfirmPopup(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const handleResetPassword = async () => {
    try {
      await axios.get(`${API_URL}/auth/autoresetpassword/${rowData.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          setIsOpenConfirmPopup(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenConfirmPopup(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [sorting, setSorting] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'index',
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: '#',
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'user_id',
        enableColumnFilter: false,
        size: 50,
        header: () => <span>User ID</span>
      },
      {
        accessorKey: 'name',
        enableColumnFilter: false,
        size: 250,
        header: () => <span>Name</span>,
      },
      {
        accessorKey: 'email',
        enableColumnFilter: false,
        size: 300,
        header: () => <span>Email</span>,
      },
      {
        accessorKey: 'creation_by',
        enableColumnFilter: false,
        size: 50,
        header: 'Created By',
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter: false,
        size: 50,
        header: 'Created Date',
        cell: ({ row }) => (
          <div>{formattedDate(row.original.creation_date)}</div>
        )
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        enableColumnFilter: true,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex justify-center cursor-default lg:cursor-pointer'><div onClick={() => openStatusModal(row.original)} className={`${row.original.status === true ? 'bg-green-300  text-green-900' : 'bg-red-300 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-xl`}>{row.original.status === true ? "Active" : "Inactive"}</div></div>
        )
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
            <Menu as="div">
              <div ref={(el) => (buttonRefs.current[row.original.id] = el)}>
                <Menu.Button className="border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    calculatePosition(row.original.id)
                  }
                  }
                >
                  <EllipsisHorizontalIcon className="h-4 text-black" />
                </Menu.Button>
              </div>
              <Portal>
                <Transition
                  as={Fragment}
                  show={activeDropdown === row.original.id}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-30 right-8 md:right-12 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{
                      top: `${dropdownPosition.top}px`,
                    }}
                    ref={dropdownRef}
                  >
                    <button
                      className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-blue-800 w-full text-sm"
                      onClick={() => {
                        openModal(row.original, "edit")
                        setActiveDropdown(null)
                      }}
                    >
                      <PencilIcon className="h-4 text-blue-800 mr-2" />
                      <span>Edit</span>
                    </button>
                    {localStorage.getItem('role') === 'ADMIN' &&
                      <button className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-teal-800 w-full text-sm"
                        onClick={() => {
                          openConfirmPopup(row.original, 'resetpassword')
                          setActiveDropdown(null)
                        }}>
                        <RiLockPasswordLine strokeWidth={0.01} className='h-4 text-teal-800 mr-2' />
                        <span>Reset Password</span>
                      </button>}
                    {localStorage.getItem('role') === 'ADMIN' &&
                      <button className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-red-800 w-full text-sm"
                        onClick={() => {
                          openConfirmPopup(row.original)
                          setActiveDropdown(null)
                        }}>
                        <TrashIcon className='h-4 text-red-800 mr-2' />
                        <span>Delete</span>
                      </button>}
                  </Menu.Items>
                </Transition>
              </Portal>
            </Menu>
          </div>
        )
      },
    ].filter(Boolean),
    [activeDropdown, calculatePosition, dropdownPosition.top]
  );

  const [data, setData] = useState([]);

  const [globalFilter, setGlobalFilter] = React.useState('');
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(initialpageSize);


  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
  });

  const tableContainerRef = React.useRef(null);
  // Modify the pagination section
  const pageCount = Math.ceil(data.length / pageSize);
  const pageNumbers = [];

  for (let i = 0; i < pageCount; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (e) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    setPageIndex(0)
  };

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: pageSize,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const renderTableCellIndex = (row) => {
    return (
      table.getFilteredRowModel().rows.indexOf(row) + 1 ||
      table.getSortedRowModel().rows.indexOf(row) + 1 ||
      row.index + 1
    );
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      axios
        .get(`${API_URL}/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          },
        })
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    const fetchActiveUserRole = async () => {
      setLoader(true);
      try {
        const response = await axios.get(`${API_URL}/user_role/active`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const activeRoles = response.data.data;
        console.log(activeRoles)
        setActiveRole(activeRoles);
      } catch (error) {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        openPopup();
      } finally {
        setLoader(false);
      }
    };
    fetchActiveUserRole();
    fetchInitialData();
  }, []);

  return (
    <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={!resetPasswordState ? handleDeleteState : handleResetPassword}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <InputModal isOpen={isOpen} closeModal={closeModal} title={edit === false ? 'Create User' : 'Edit User'} savefn={edit === false ? handleCreateState : handleEditState}
        modalInputs={
          <div className='space-y-4'>
            <div>
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <input type="text" className='block w-full rounded-md border py-1.5 mt-1 ring-0 sm:text-sm sm:leading-6' defaultValue={rowData.name} onChange={(e) => setRowData({ ...rowData, name: e.target.value })} />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <input type="text" className='block w-full rounded-md border py-1.5 ring-0 sm:text-sm sm:leading-6 mt-1' defaultValue={rowData.email} onChange={(e) => setRowData({ ...rowData, email: e.target.value })} />
            </div>
            <div>
              <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">
                Gender
              </label>
              <select
                id="gender"
                name="gender"
                className="block mt-1 w-auto md:w-full md:tracking-wider rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                defaultValue={rowData.gender}
                onChange={(e) => setRowData({ ...rowData, gender: e.target.value })}
              >
                <option value="">Select Gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>
            {localStorage.getItem('role') === 'ADMIN' && <div>
              <label htmlFor="userlevel" className="block text-sm font-medium leading-6 text-gray-900">
                User Role
              </label>
              <select
                id="userlevel"
                name="userlevel"
                className="block mt-1 w-auto md:w-full md:tracking-wider rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                defaultValue={rowData.role_code}
                onChange={(e) => setRowData({ ...rowData, role_code: e.target.value })}
              >
                <option value="">Select Role Type</option>
                {activeRole.map((role) => (
                  <option key={role.id} value={role.role_code}>
                    {role.role_name}
                  </option>
                ))}
              </select>
            </div>}
          </div>
        }
      />
      <InputModal isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value="true"
                checked={rowData.status === true || rowData.status === "true"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                Active
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value="false"
                checked={rowData.status === false || rowData.status === "false"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                Inactive
              </label>
            </div>
          </div>
        }
      />
      <div className='flex justify-between m-3 gap-2'>
        <div>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder="Search..."
          />
        </div>
        <div className='flex gap-2'>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => ReloadData(true)}>
            <ArrowPathIcon className='h-5 text-green-700' />
          </button>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                <span className="absolute -inset-1.5" />
                <FunnelIcon className="h-5 w-auto" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                  {data.length > 0 &&
                    table
                      .getHeaderGroups()
                      .map((headerGroup) => (
                        <div
                          key={headerGroup.id}
                          className="space-y-1"
                        >
                          {headerGroup.headers.map(
                            (header) => (
                              <div
                                key={header.id}
                              >
                                {header.column.getCanFilter() &&
                                  header.column.id === "status" ? (
                                  <div>
                                    <label
                                      htmlFor="push-everything"
                                      className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                    >
                                      Status
                                    </label>
                                    <StatusAdminFilter
                                      column={
                                        header.column
                                      }
                                      table={
                                        table
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => openModal('e', 'create')}>
            <PlusCircleIcon className='h-5' />
          </button>
        </div>
      </div>
      {Loader && <FetchingDataLoader />}
      {!Loader &&
        <>
          <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto'>
            <table className='flex-inline w-full border-collapse'>
              <thead className='shadow bg-gray-100'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className='border p-2 whitespace-nowrap'
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={`${header.column.getCanSort()
                                  ? 'cursor-pointer select-none justify-start'
                                  : 'justify-center'
                                  } flex`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] || null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  rows.length === 0 ?
                    (
                      <tr>
                        <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                          No Data Found
                        </td>
                      </tr>
                    )
                    :
                    (
                      <>
                        {paddingTop > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingTop}px` }} />
                          </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                          const row = rows[virtualRow.index];
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td className='border p-2 whitespace-nowrap' key={cell.id}>
                                    {cell.column.id === "index" && (
                                      <center>{renderTableCellIndex(row)}</center>
                                    )}
                                    {cell.column.id !== "index" &&
                                      flexRender(
                                        cell.column
                                          .columnDef
                                          .cell,
                                        cell.getContext()
                                      )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {paddingBottom > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
                          </tr>
                        )}
                      </>
                    )
                }
              </tbody>
            </table>
          </div>
          {
            rows.length > 0 && (
              <div className='flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3'>
                <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  pageNumbers={pageNumbers}
                  pageCount={table.getPageCount()}
                />
                <PageSizeDropdown
                  dataLength={table.getPrePaginationRowModel().rows.length}
                  pageSize={pageSize}
                  handlePageChange={handlePageChange}
                  totalRows={table.getPrePaginationRowModel().rows.length}
                />
              </div>
            )
          }
        </>
      }
    </div>
  );
}