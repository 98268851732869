import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from '../Common/Popup';
import Accordion from '../Common/AccordionItem';
import { API_URL } from '../../imports';
import { handleApiError } from '../Common/APIUtils';
import ConfirmationPopup from '../Common/ConfirmationPopup';

const BackgroundProcess = () => {
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [noData, setNoData] = useState(false);
    const [groupedTasks, setGroupedTasks] = useState([]);
    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState('');
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedTab, setSelectedTab] = useState('Transcription');

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${API_URL}/background_tasks`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                    params: {
                      client: sessionStorage.getItem('client')
                    }
                });
                const tasks = response.data.data;

                if (tasks.length === 0) {
                    setNoData(true);
                } else {
                    const grouped = tasks.map((task) => ({
                        projectCode: task.project_code,
                        projectName: task.project_name,
                        creationDate: task.creation_date,
                        transcriptionTasks: task.TRANSCRIPT || [],
                        rawAnswerTasks: task.RAW || [],
                        refinedTasks: task.REFINED || []
                    }));
                    setGroupedTasks(grouped);
                }

            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsOpenPopup(true);
            }
        };
        fetchTasks();
    }, []);

    const handleCancel = (projectName, projectCode, processType) => {
        const project = groupedTasks.find(p => p.projectCode === projectCode);
        if (!project) {
            setMsg('Project not found.');
            setIsSuccess(false);
            setIsOpenPopup(true);
            return;
        }

        // Determine which task list to check based on processType
        const tasksToCancel = processType === 'Transcription' ? project.transcriptionTasks : processType === 'Raw Answers' ? project.rawAnswerTasks : project.refinedTasks;

        // Check if all tasks are already canceled
        const allCanceled = tasksToCancel.every(task => task.status === 'canceled');
        if (allCanceled) {
            setMsg('All processes already canceled.');
            setIsSuccess(false);
            setIsOpenPopup(true);
            return;
        }

        // Check if there are any tasks with a 'waiting' status
        const hasWaitingTasks = tasksToCancel.some(task => task.status === 'waiting');
        if (hasWaitingTasks) {
            setConfirmMsg('Are you sure you want to cancel the process?');
            setSelectedProject({ projectName, projectCode, processType });
            setIsOpenConfirmPopup(true);
        } else {
            performCancel(projectName, projectCode, processType);
        }
    };

    const performCancel = async (projectName, projectCode, processType) => {
        try {
            const response = await axios.post(`${API_URL}/background_tasks/cancel`, { projectName, projectCode, processType }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            if (response.data.status === 'success') {
                const updatedTasks = groupedTasks.map(p => {
                    if (p.projectCode === projectCode) {
                        const updatedList = p[`${processType.toLowerCase()}Tasks`].map(task =>
                            task.status === 'waiting' ? { ...task, status: 'canceled' } : task
                        );

                        return {
                            ...p,
                            [`${processType.toLowerCase()}Tasks`]: updatedList,
                        };
                    }
                    return p;
                });

                setGroupedTasks(updatedTasks);
                setMsg(response.data.message);
                setIsSuccess(true);
            } else {
                setMsg(response.data.message || 'Cancellation failed.');
                setIsSuccess(false);
            }
        } catch (error) {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
        } finally {
            setIsOpenPopup(true);
        }
    };

    const handleConfirmation = (action) => {
        if (action === 'cancel') {
            performCancel(selectedProject.projectName, selectedProject.projectCode, selectedProject.processType);
        }
        setIsOpenConfirmPopup(false);
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    // Filter tasks based on the selected tab
    const filteredTasks = groupedTasks.filter(task => {
        if (selectedTab === 'Transcription') {
            return task.transcriptionTasks.length > 0;
        } else if (selectedTab === 'Raw Answers') {
            return task.rawAnswerTasks.length > 0;
        } else if (selectedTab === 'Refined') {
            return task.refinedTasks.length > 0;
        }
        return false;
    }).map(task => {
        return {
            ...task,
            tasks: selectedTab === 'Transcription' ? task.transcriptionTasks : selectedTab === 'Raw Answers' ? task.rawAnswerTasks : task.refinedTasks
        };
    });

    return (
        <>
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={() => setIsOpenPopup(false)}
                isSuccess={isSuccess}
            />
            <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmMsg}
                closeConfirmPopup={() => setIsOpenConfirmPopup(false)}
            />
            <div className="flex flex-col md:flex-row">
                {/* Tab buttons for small screens */}
                <div className="w-full flex justify-between bg-gray-100 md:hidden">
                    <button
                        onClick={() => handleTabClick('Transcription')}
                        className={`px-3 py-1 text-sm font-medium ${selectedTab === 'Transcription' ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'}`}
                    >
                        Transcription
                    </button>
                    <button
                        onClick={() => handleTabClick('Raw Answers')}
                        className={`px-3 py-1 text-sm font-medium ${selectedTab === 'Raw Answers' ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'}`}
                    >
                        Raw Answers
                    </button>
                    <button
                        onClick={() => handleTabClick('Refined')}
                        className={`px-3 py-1 text-sm font-medium ${selectedTab === 'Refined' ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'}`}
                    >
                        Refined
                    </button>
                </div>

                {/* Sidebar for larger screens */}
                <div className="hidden md:flex md:w-1/5 bg-gray-100 resp-sidebar p-2 shadow-md flex-col gap-2">
                    <button
                        onClick={() => handleTabClick('Transcription')}
                        className={`py-2 text-sm font-medium ${selectedTab === 'Transcription' ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'}`}
                    >
                        Transcription
                    </button>
                    <button
                        onClick={() => handleTabClick('Raw Answers')}
                        className={`py-2 text-sm font-medium ${selectedTab === 'Raw Answers' ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'}`}
                    >
                        Raw Answers
                    </button>
                    <button
                        onClick={() => handleTabClick('Refined')}
                        className={`py-2 text-sm font-medium ${selectedTab === 'Refined' ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'}`}
                    >
                        Refined
                    </button>
                </div>

                {/* Main Content */}
                <div className="overflow-auto w-full lg:p-10 mt-2 lg:mt-0">
                    {noData ? (
                        <div className="text-center mt-4">
                            No running projects found.
                        </div>
                    ) : (
                        <>
                            <h2 className="text-lg tracking-wider font-semibold py-2">
                                {selectedTab === 'Transcription' ? 'Transcription Process' : selectedTab === 'Raw Answers' ? 'Raw Process' : 'Refined Process'}
                            </h2>
                            <Accordion
                                groupedTasks={filteredTasks}
                                onCancel={handleCancel}
                                selectedTab={selectedTab}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default BackgroundProcess;
