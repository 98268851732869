import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Admin from "./components/Admin/Admin";
import Projects from "./components/Projects/Projects";
import UserMgmt from "./components/Admin/UserMgmt";
import LogsMgmt from "./components/Admin/LogsMgmt";
import ActivityLog from "./components/Admin/ActivityLog";
import ProjectSetup from "./components/Admin/ProjectSetup";
import ProjectAssignment from "./components/Admin/ProjectAssignment";
import ProcessTiles from "./components/Projects/ProcessTiles";
import AddFiles from "./components/Projects/AddFiles";
import ViewFiles from "./components/Projects/ViewFiles";
import ProjectConfiguration from "./components/Projects/ProjectConfiguration";
import Transcription from "./components/Projects/Transcription";
import ProtectedRoutes from "./ProtectedRoutes";
import PageNotFound from "./PageNotFound";
import ProfileUpdate from "./components/Auth/ProfileUpdate";
import PasswordUpdate from "./components/Auth/PasswordUpdate";
import GetIdeas from "./components/Projects/GetIdeas";
import Raw from "./components/Projects/Raw";
import Refined from "./components/Projects/Refined";
import TransposeFlatteningMapping from "./components/Projects/TransposeFlatteningMapping";
import ServerUrlMgmt from "./components/Admin/ServerUrlMgmt";
import ProjectReport from "./components/Common/ProjectReport";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import Newprocess from "./components/Projects/Newprocess";
import QDBPrompts from "./components/Projects/QDBPrompts";
import CopyTranscripts from "./components/Common/CopyTranscripts";
import MergeTranscriptsCSV from "./components/Common/MergeTranscriptsCSV";
import ProjectFilter from "./components/Projects/ProjectFilter";
import store from "./store";
import { Provider } from "react-redux";
import ProjectGroup from "./components/Admin/ProjectGroup";
import ProjectTag from "./components/Admin/ProjectTag";
import ClosedProjectTriggers from "./components/Admin/ClosedProjectTriggers";
import ServerMonitoring from "./components/Admin/ServerMonitoring";
import NonAdminActivityLog from "./components/Common/NonAdminActivityLog";
import ApiReport from "./components/Common/ApiSummaryReport";
import ClientMgmt from "./components/Admin/ClientMgmt";
import ProjectManager from "./components/ProjectManager";
import AlterTable from "./components/Admin/AlterTable";
import AlterTableLogs from "./components/Admin/AlterTableLogs";
import ConfigMgmt from "./components/Admin/ConfigMgmt";
import ApiDocument from "./components/Admin/ApiDocument";
import BackgroundProcess from "./components/Projects/BackgroundProcess";
import UserRoleMgmt from "./components/Admin/UserRoleMgmt";
import DynamicParamConfiguration from "./components/Admin/DynamicParamConfiguration";
import ClientAssignment from "./components/Admin/ClientAssignment";
import Directory from "./components/Common/Directory";

const projectManagementTabs = [
    {
        id: 1,
        title: "Project Group",
        content: <ProjectGroup />,
    },
    {
        id: 2,
        title: "Project Tag",
        content: <ProjectTag />,
    },
    {
        id: 3,
        title: "Project Management",
        content: <ProjectSetup />,
    },
    {
        id: 4,
        title: "Project Assignment",
        content: <ProjectAssignment />,
    },
];

const profileTabs = [
    {
        id: 1,
        title: "Update Profile",
        content: <ProfileUpdate />,
    },
    {
        id: 2,
        title: "Update Password",
        content: <PasswordUpdate />,
    },
];

const AlterTableTabs = [
    {
        id: 1,
        title: "Execution",
        content: <AlterTable />,
    },
    {
        id: 2,
        title: "Logs",
        content: <AlterTableLogs />,
    },
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <Router>
            <Routes>
                {/*Login Page*/}
                <Route path="/" element={<App />} />

                {/*Page Not Found*/}
                <Route path="*" element={<PageNotFound />} />

                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route
                    path="/resetpassword/:token"
                    element={<ResetPassword />}
                />

                {/*All the routes are nested under ProtectedRoutes to enhance access only if it has the access token or logged in*/}
                <Route exact path="/" element={<ProtectedRoutes />}>
                    {/*Project Manager*/}
                    <Route
                        exact
                        path="/projectmanager"
                        element={
                            <Layout title="Dashboard" component={<ProjectManager />} />
                        }
                    />
                    {/*Admin Pages*/}
                    <Route
                        exact
                        path="/admin"
                        element={
                            <Layout title="Dashboard" component={<Admin />} />
                        }
                    />
                    <Route
                        exact
                        path="/closed_project_triggers"
                        element={
                            <Layout
                                title = "Closed Project Triggers"
                                component={<ClosedProjectTriggers />}
                                resetLoading={false}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/param_fields_configuration"
                        element={
                            <Layout
                                title = "Param Fields Configuration"
                                component={<DynamicParamConfiguration />}
                                resetLoading={false}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/project_management"
                        element={
                            <Layout
                                tabs={projectManagementTabs}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/user_management"
                        element={
                            <Layout
                                title="User Management"
                                component={<UserMgmt />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/client_assignment"
                        element={
                            <Layout
                                title="Client Assignment"
                                component={<ClientAssignment/>}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/settings/directory"
                        element={
                            <Layout
                                title="All Directories"
                                component={<Directory/>}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/system_configuration"
                        element={
                            <Layout
                                title="System Configuration"
                                component={<ConfigMgmt />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/sql_execution"
                        element={
                            <Layout
                                tabs={AlterTableTabs}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/client_management"
                        element={
                            <Layout
                                title="Client Management"
                                component={<ClientMgmt />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/user_role_management"
                        element={
                            <Layout
                                title="User Role Management"
                                component={<UserRoleMgmt />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/logs_management"
                        element={
                            <Layout
                                title="Logs Management"
                                component={<LogsMgmt />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/activity_log"
                        element={
                            <Layout
                                title="Activity Log"
                                component={<ActivityLog />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/apiDocument"
                        element={
                            <Layout
                                title="Version Upgrade Documentation"
                                component={<ApiDocument />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/instanceservicemonitoring"
                        element={
                            <Layout
                                title="Instance and Service Monitoring"
                                component={<ServerMonitoring />}
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/serverUrl"
                        element={
                            <Layout
                                title="Service Status"
                                component={<ServerUrlMgmt />}
                                resetLoading={true}
                            />
                        }
                    />

                    {/*Non-Admin Pages*/}
                    <Route
                        exact
                        path="/projects"
                        element={
                            <Layout
                                title="Project"
                                component={<Projects />}
                                additionalMdButtons={<ProjectFilter />}
                                additionalSmButtons={<ProjectFilter />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/nonadmin_activity_log"
                        element={
                            <Layout
                                title="User Activity Log"
                                component={<NonAdminActivityLog />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projectreport"
                        element={
                            <Layout
                                title="Project Report"
                                component={<ProjectReport />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/apireport"
                        element={
                            <Layout
                                title="API Call Summary Reports"
                                component={<ApiReport />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/backgroundprocess"
                        element={
                            <Layout
                                title="Background Process"
                                component={<BackgroundProcess />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/copytranscripts"
                        element={
                            <Layout
                                mainTitle="Copy Transcripts"
                                component={<CopyTranscripts />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/qdbprompts"
                        element={
                            <Layout
                                title="QDB Prompts"
                                component={<QDBPrompts />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname"
                        element={
                            <Layout
                                component={<ProcessTiles />}
                                subTitleChange={true}
                                additionalMdButtons={
                                    <>
                                        <AddFiles />
                                        <ViewFiles />
                                        <ProjectConfiguration />
                                        <MergeTranscriptsCSV />
                                    </>
                                }
                                resetLoading={true}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname/transcription"
                        element={
                            <Layout
                                title="Transcription"
                                mainTitleChange={true}
                                homeButton={true}
                                component={<Transcription />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname/getideas"
                        element={
                            <Layout
                                title="Get Ideas"
                                mainTitleChange={true}
                                homeButton={true}
                                component={<GetIdeas />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname/raw"
                        element={
                            <Layout
                                title="Raw Answers"
                                mainTitleChange={true}
                                homeButton={true}
                                component={<Raw />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname/refined"
                        element={
                            <Layout
                                title="Refined Answers"
                                mainTitleChange={true}
                                homeButton={true}
                                component={<Refined />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname/TransposeFlatteningMapping"
                        element={
                            <Layout
                                title="Transpose, Flattening & Mapping"
                                mainTitleChange={true}
                                homeButton={true}
                                component={<TransposeFlatteningMapping />}
                            />
                        }
                    />
                    <Route
                        exact
                        path="/projects/:projectname/:processname"
                        element={
                            <Layout
                                title={sessionStorage.getItem("processName")}
                                mainTitleChange={true}
                                homeButton={true}
                                component={<Newprocess />}
                            />
                        }
                    />

                    {/*Authorized Common Pages*/}
                    <Route
                        exact
                        path="/profileinfo"
                        element={
                            <Layout
                                mainTitle="Profile Information"
                                tabs={profileTabs}
                                resetLoading={true}
                            />
                        }
                    />
                </Route>
            </Routes>
        </Router>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
