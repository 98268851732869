import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Menu, Tab, Transition } from "@headlessui/react";
import axios from "axios";
import { API_URL } from "../../imports";
import { handleApiError } from "../Common/APIUtils";
import Popup from "./Popup";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ProcessLayout({
    tabs,
    startProcess,
    server,
    serverStatus,
    serviceUrl,
    serverLoadState,
    batching,
    handleBatchChange,
}) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showOptions, setShowOptions] = useState(false);
    let [isOpenPopup, setIsOpenPopup] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    const [batches, setBatches] = useState([]);

    const handleShowBatch = () => {
        handleBatchChange(null);
        if (showOptions === true) {
            sessionStorage.removeItem("batch");
        }
        setShowOptions(!showOptions);
    };

    const [selectedBatchprop, setSelectedBatchprop] = useState("All");

    const handleSelectedBatch = async (selectedBatch) => {
        setSelectedBatchprop(selectedBatch);
    };

    const BatchwiseAudioFiles = () => {
        sessionStorage.setItem("batch", selectedBatchprop);
        handleBatchChange(selectedBatchprop);
    };

    useEffect(()=>{
        const GetNumberOfBatches = async () => {
            axios.get(
                    `${API_URL}/Batching/getNumberOfBatches/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    setBatches(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        };
        GetNumberOfBatches();
        sessionStorage.removeItem("batch");
    },[openPopup])

    useEffect(() => {
        if (startProcess) {
            // Set the active tab to the second tab when the process starts
            setSelectedIndex(1);
        }
    }, [startProcess]);

    return (
        <div className="border border-gray-300 rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            {server && (
                <div className="overflow-auto w-[calc(100vw-2rem)] md:w-full p-3 border-b box-border">
                    <div
                        className={`flex gap-2 ${
                            batching && batches.length > 0 && selectedIndex===0 ? "justify-between" : "justify-end"
                        }`}
                    >
                        <div
                            className={`gap-3 ${batching && batches.length > 0 && selectedIndex===0 ? "flex" : "hidden"}`}
                        >
                            <div className="ring-1 ring-inset border border-gray-300 ring-gray-300 rounded-md flex text-sm cursor-default lg:cursor-pointer">
                                <div
                                    onClick={handleShowBatch}
                                    className={`border-r rounded-s-md px-3 pt-2 ${
                                        showOptions === false
                                            ? "bg-indigo-800 text-white pb-2"
                                            : "bg-white text-black"
                                    }`}
                                >
                                    All
                                </div>
                                <div
                                    onClick={handleShowBatch}
                                    className={`px-3 pt-2 rounded-r-md ${
                                        showOptions === true
                                            ? "bg-indigo-800 text-white"
                                            : "bg-white text-black pb-2"
                                    }`}
                                >
                                    Batches
                                </div>
                            </div>
                            {showOptions && selectedIndex===0 && (
                                <>
                                    <select
                                        id="batch"
                                        name="batch"
                                        className="block w-auto md:w-full md:tracking-wider rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs text-sm sm:leading-6"
                                        value={selectedBatchprop}
                                        onChange={(e) => handleSelectedBatch(e.target.value)}
                                    >
                                        <option value="All">
                                            Select Batch
                                        </option>
                                        {batches.map((batch, index) => (
                                            <option key={index} value={batch}>
                                                {batch}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border tracking-widest"
                                        onClick={BatchwiseAudioFiles}
                                    >
                                        Next
                                    </button>
                                </>
                            )}
                        </div>
                        {server && (
                            <div className="flex">
                            <div className="inline-flex justify-center pt-2 pb-2 text-sm font-medium text-blue-900 whitespace-nowrap">
                                {server}:
                                {!serverLoadState ? (
                                    <p className="text-green-800 tracking-widest font-bold ps-1">
                                        {serverStatus ? "ON" : "OFF"}
                                    </p>
                                ) : (
                                    <p className="text-red-600 tracking-widest font-bold ps-1">
                                        Loading
                                    </p>
                                )}
                            </div>
                            <Menu as="div" className="relative">
                                <div>
                                    <Menu.Button className="relative justify-center pt-2 pb-2 pl-1 text-blue-800 rounded-md text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                    <span className="absolute -inset-1.5" />
                                    <InformationCircleIcon className="h-5 w-auto" strokeWidth={1.8}/>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="fixed right-7 z-30 mt-2 w-fit px-2 py-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div>
                                            {!serverLoadState ? (
                                                <a href={serviceUrl} target="_blank" without rel="noreferrer" className="underline text-sm text-blue-600">{serviceUrl}</a>
                                            ) : (
                                                <p className="text-blue-600 tracking-widest text-sm ps-1">
                                                    Loading...
                                                </p>
                                            )}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <Tab.Group
                as="div"
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
            >
                <Tab.List className="flex justify-start border-b overflow-auto">
                    <div className="flex gap-2 md:gap-3">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.id}
                                className={({ selected }) =>
                                    classNames(
                                        "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                        "ring-white focus:outline-none focus:ring-0",
                                        selected
                                            ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                            : "text-black pb-3 lg:pb-3.5"
                                    )
                                }
                            >
                                <p className="text-sm pt-4 tracking-wider">
                                    {tab.title}
                                </p>
                            </Tab>
                        ))}
                    </div>
                </Tab.List>
                <Tab.Panels
                    className={`overflow-auto ${
                        server
                            ? "resp-process-files-h"
                            : "resp-noserverbatch-files-h"
                    }`}
                >
                    {tabs.map((tab, idx) => (
                        <Tab.Panel
                            key={idx}
                            className={classNames(
                                "bg-white",
                                "ring-white focus:outline-none focus:ring-0"
                            )}
                        >
                            {tab.content}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}
