import React, { useState, useCallback, useEffect } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { DebouncedInput, IndeterminateCheckbox } from "./Filters";
import FetchingDataLoader from "./FetchingDataLoader";
import {
    ArrowPathIcon,
    EyeIcon,
    ArrowDownTrayIcon,
    FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import ReadModal from "./ReadModal";
import { API_URL } from "../../imports";
import axios from "axios";
import Popup from "./Popup";
import { handleApiError } from "./APIUtils";
import DownloadingLoader from "./DownloadingLoader";
import ResponseLoader from "./ResponseLoader";
import SingleDownloadLoader from "./SingleDownloadLoader";
import TabwiseJSONResponseTable from "./TabwiseJSONResponseTable";

export default function FlatteningMappingProcess() {
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [sorting, setSorting] = React.useState([]);
    const [rowSelection, setRowSelection] = React.useState({});
    let [modalTitle, setModalTitle] = useState(false);
    let [isOpenRead, setIsOpenRead] = useState(false);
    const [Loader, setLoader] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    let [jsonValue, setJsonValue] = useState({});
    const [data, setData] = useState([]);
    let [downloadLoader, setDownloadLoader] = useState(false);
    let [singleDownloadLoader, setSingleDownloadLoader] = useState(false);
    let [responseLoader, setResponseLoader] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    function closeReadModal() {
        setIsOpenRead(false);
    }

    const openReadModal = useCallback(
        (e) => {
            setResponseLoader(true);
            axios
                .post(
                    `${API_URL}/flattening_mapping/response/${sessionStorage.getItem('projectCode')}`,
                    {
                        filename: e.filename
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    const decodedJsonString = atob(response.data.response);
                    console.log(decodedJsonString)
                    setJsonValue(JSON.parse(decodedJsonString));
                    setModalTitle(`Flattening & Mapping of ${e.filename}`);
                    setResponseLoader(false);
                    setIsOpenRead(true);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setResponseLoader(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [setModalTitle, openPopup, setIsOpenRead]
    );

    const handleSingleExcelDownload = useCallback(
        (file) => {
            setSingleDownloadLoader(true);
            axios
                .post(
                    `${API_URL}/flattening_mapping/download/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        files_list: [file.filename]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    const binaryContent = atob(response.data.data);

                    // Create a Uint8Array from the binary content
                    const uint8Array = new Uint8Array(binaryContent.length);
                    for (let i = 0; i < binaryContent.length; i++) {
                        uint8Array[i] = binaryContent.charCodeAt(i);
                    }

                    // Create a Blob from the Uint8Array
                    const blob = new Blob([uint8Array], {
                        type: "text/csv",
                    }); // Adjust type based on your file type

                    // Create a URL object from the Blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element
                    const link = document.createElement("a");
                    link.href = url;
                    link.download =
                        "Flattening & Mapping of " +
                        file.filename +
                        ".xlsx";

                    // Append the link to the document body
                    document.body.appendChild(link);

                    setSingleDownloadLoader(false);

                    // Trigger the download
                    link.click();

                    // Cleanup
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setSingleDownloadLoader(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [openPopup]
    );

    const DownloadMultipleExcelAPI = async (fileNames) => {
        setRowSelection({});
        axios
            .post(
                `${API_URL}/flattening_mapping/download/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    files_list: fileNames
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then(async (response) => {
                setDownloadLoader(false);

                const binaryContent = atob(response.data.data);

                // Create a Uint8Array from the binary content
                const uint8Array = new Uint8Array(binaryContent.length);
                for (let i = 0; i < binaryContent.length; i++) {
                    uint8Array[i] = binaryContent.charCodeAt(i);
                }

                // Create a Blob from the Uint8Array
                const blob = new Blob([uint8Array], {
                    type: "text/csv",
                }); // Adjust type based on your file type

                // Create a URL object from the Blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element
                const link = document.createElement("a");
                link.href = url;
                link.download = `Flattening & Mapping of ${sessionStorage.getItem("projectName")}.xlsx`;

                // Append the link to the document body
                document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setDownloadLoader(false);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };
    const handleMultipleDownloadExcel = () => {
        setDownloadLoader(true);
        // Filter the Files based on the rowSelection
        const selectedFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
            const fileNames = selectedFiles.map((file) => file.filename);
            DownloadMultipleExcelAPI(fileNames);
        } else if (selectedFiles.length === data.length) {
            DownloadMultipleExcelAPI("All");
        } else {
            DownloadMultipleExcelAPI("All");
        }
    };

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "filename",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>File Name</span>,
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <button
                            className="p-1 rounded-md border disabled:text-gray-200 text-green-800"
                            onClick={() => openReadModal(row.original)}
                        >
                            <EyeIcon className="h-4" />
                        </button>
                        <button
                            className="p-1 rounded-md border"
                            onClick={() => handleSingleExcelDownload(row.original)}
                        >
                            <ArrowDownTrayIcon className="h-4 text-blue-600" />
                        </button>
                    </div>
                ),
            },
        ],
        [openReadModal, handleSingleExcelDownload]
    );

    useEffect(() => {
        setLoader(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/Transpose/closedrefinedfiles/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };
        fetchData();
    }, [openPopup]);

    const ReloadData = useCallback(() => {
        setLoader(true);
        const reloadTranscribedData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/Transpose/closedrefinedfiles/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setData(response.data.data);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };
        reloadTranscribedData();
    }, [openPopup]);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    const tableContainerRef = React.useRef(null);

    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();

        return (
            <input
                type="text"
                value={columnFilterValue || ""}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    return (
        <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex justify-between m-3 gap-2">
                <DebouncedInput
                    value={globalFilter ?? ""}
                    onChange={(value) => setGlobalFilter(String(value))}
                    placeholder="Search..."
                />
                <button
                    className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                    onClick={ReloadData}
                >
                    <ArrowPathIcon className="h-5 text-green-700" />
                </button>
            </div>
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalBodyClass="p-2 overflow-auto"
                paddingYModal="pt-4"
                modalInputs={
                    <TabwiseJSONResponseTable data={jsonValue}/>
                }
            />
            {singleDownloadLoader && <SingleDownloadLoader />}
            {responseLoader && <ResponseLoader />}
            {Loader && <FetchingDataLoader />}
            {!Loader && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="overflow-auto resp-tfmtable-h border-y"
                    >
                        <table className={`flex-inline w-full border-collapse`}>
                            <thead className="bg-gray-200">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${
                                                                    header.column.getCanSort()
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={
                                                                            table
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {rows.length === 0 ? (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            colSpan={
                                                table.getHeaderGroups()[0]
                                                    .headers.length
                                            }
                                            style={{ textAlign: "center" }}
                                        >
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {paddingTop > 0 && (
                                            <tr>
                                                <td
                                                    className="border p-2"
                                                    style={{
                                                        height: `${paddingTop}px`,
                                                    }}
                                                />
                                            </tr>
                                        )}
                                        {virtualRows.map((virtualRow) => {
                                            const row = rows[virtualRow.index];
                                            return (
                                                <tr
                                                    key={row.id}
                                                    className="bg-white transition duration-300 ease-in-out hover:bg-gray-100"
                                                >
                                                    {row
                                                        .getVisibleCells()
                                                        .map((cell) => {
                                                            return (
                                                                <td
                                                                    className="border p-2 whitespace-nowrap"
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                >
                                                                    {cell.column
                                                                        .id ===
                                                                        "index" &&
                                                                        renderTableCellIndex(
                                                                            row
                                                                        )}
                                                                    {cell.column
                                                                        .id !==
                                                                        "index" &&
                                                                        flexRender(
                                                                            cell
                                                                                .column
                                                                                .columnDef
                                                                                .cell,
                                                                            cell.getContext()
                                                                        )}
                                                                </td>
                                                            );
                                                        })}
                                                </tr>
                                            );
                                        })}
                                        {paddingBottom > 0 && (
                                            <tr>
                                                <td
                                                    className="border p-2"
                                                    style={{
                                                        height: `${paddingBottom}px`,
                                                    }}
                                                />
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!singleDownloadLoader &&
                        !responseLoader &&
                        data.length > 0 &&
                        rows.length > 0 && (
                            <div className="flex gap-2 justify-start m-3">
                                <button
                                    type="button"
                                    onClick={handleMultipleDownloadExcel}
                                    disabled={downloadLoader}
                                    className={`inline-flex justify-center rounded-lg border border-transparent bg-blue-100 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer ${
                                        downloadLoader ? "px-2" : "px-3"
                                    }`}
                                >
                                    {!downloadLoader && (
                                        <>
                                            <FolderArrowDownIcon
                                                className="h-4"
                                                title="Download Excel file"
                                            />{" "}
                                            &nbsp;Download Excel
                                        </>
                                    )}
                                    {downloadLoader && <DownloadingLoader />}
                                </button>
                            </div>
                        )}
                </>
            )}
        </div>
    );
}
