import React from "react";
import TransposeProcess from "../Common/TransposeProcess";
import ProcessLayout from "../Common/ProcessLayout";
import FlatteningMappingProcess from "../Common/FlatteningMappingProcess";

function TransposeFlatteningMapping() {

    const tabs = [
        {
            id: 1,
            title: "Transpose",
            content: (
                <TransposeProcess/>
            ),
        },
        {
            id: 2,
            title: "Flattening & Mapping",
            content: (
                <FlatteningMappingProcess/>
            ),
        }
    ];

    return (
        <>
            <ProcessLayout startProcess={false} tabs={tabs} batching={false}/>
        </>
    );
}

export default TransposeFlatteningMapping;
