import React, { useState } from 'react'
import Popup from '../Common/Popup';
import { handleApiError } from '../Common/APIUtils';
import { API_URL } from '../../http';
import axios from 'axios';

export default function AlterTable() {
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);

    const [sql, setSql] = useState('');
    const [Loader, setLoader] = useState(false)

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoader(true);
        try {
            await axios.post(`${API_URL}/alter`, JSON.stringify({ sql }), { headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}`, 'Content-Type': 'application/json'}}).then((response) => {
                setMsg(response.data.message);
                setIsSuccess(true);
                setSql('');
                setLoader(false);
                openPopup();
            });
        } catch (error) {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            setLoader(false);
            openPopup();
        }
    };

  return (
    <div className="border border-gray-300 rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
        <Popup
            isOpen={isOpenPopup}
            msg={msg}
            closeModal={closePopup}
            isSuccess={isSuccess}
        />
        <form onSubmit={handleSubmit} className='space-y-2 pb-2'>
            <textarea value={sql} onChange={(e) => setSql(e.target.value)} disabled={Loader} placeholder=' Type (or) Paste your SQL Command here...' autoFocus className='block w-full h-[calc(100vh-15.5rem)] overflow-auto resize-none rounded-t-md border-x-0 border-t-0 border-b border-gray-300 py-2 ring-0 focus:ring-0 focus:border-b focus:border-gray-300 sm:text-sm sm:leading-6'/>
            <button disabled={Loader} type="submit" className="mx-2 inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font- tracking-wider text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">Execute SQL</button>
        </form>
    </div>
  )
}
