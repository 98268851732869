import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../imports";
import Popup from "./Popup";
import { handleApiError } from "./APIUtils";
import ReadModal from "./ReadModal";

const processData = (inputData, targetProcessName) => {
    const filteredData = inputData?.filter(item => item.process_name === targetProcessName);

    const result = filteredData?.map(item => ([
        {
            name: 'Success',
            value: item.success < 0 ? Math.abs(item.success) : item.success,
        },
    ]));

    return result ? result.flat() : [];
};

export default function ApiSummaryReport() {
    const [isOpenRead, setIsOpenRead] = useState(false);
    const [jsonContent, setJsonContent] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fileCount, setFileCount] = useState(0);
    const [projects, setProjects] = useState([]);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [msg, setMsg] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [tempSelectedProject, setTempSelectedProject] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [clickedSuccessIndex, setClickedSuccessIndex] = useState(null);


    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    function closeReadModal() {
        setIsOpenRead(false);
    }

    // function openReadModal(processName, filenames = [], creationBy = [], projectCode, creationDates = []) {
    //     let jsonData = [];

    //     if (filenames.length === 0) {
    //         jsonData = null;
    //     } else {
    //         jsonData = filenames.map((filename, index) => ({
    //             filename: filename,
    //             creation_by: creationBy[index] || 'Unknown',
    //             project_code: projectCode,
    //             creation_date: creationDates[index] || 'Unknown'
    //         }));
    //     }

    //     setJsonContent(jsonData);
    //     setModalTitle(`Success Messages for ${processName}`);
    //     setIsOpenRead(true);
    // }

    function openReadModal(index, processName, filenames = [], creationBy = [], projectCode, creationDates = []) {
        let jsonData = [];

        if (filenames.length === 0) {
            jsonData = null;
        } else {
            jsonData = filenames.map((filename, idx) => ({
                filename: filename,
                creation_by: creationBy[idx] || 'Unknown',
                project_code: projectCode,
                creation_date: creationDates[idx] || 'Unknown'
            }));
        }

        setJsonContent(jsonData);
        setModalTitle(`Success Messages for ${processName}`);
        setIsOpenRead(true);
        setClickedSuccessIndex(index);
    }


    const handleSelection = async () => {
        setSelectedProject(tempSelectedProject);
        axios
            .get(`${API_URL}/entries/projectreport-success/${tempSelectedProject}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                setData(response.data.data.tbody);
                setFileCount(response.data.data.file_count);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        const fetchProcess = async (projectCode) => {
            axios
                .get(`${API_URL}/entries/projectreport-success/${projectCode}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                })
                .then((response) => {
                    setData(response.data.data.tbody);
                    setFileCount(response.data.data.file_count);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const fetchProjects = async () => {
            axios
                .get(`${API_URL}/projectusers/projects`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                    params: {
                      client: sessionStorage.getItem('client')
                    }
                })
                .then((response) => {
                    setProjects(response.data.data);
                    const firstProjectCode = sessionStorage.getItem("projectCode") ||
                        response.data.data[0].project_code;
                    setTempSelectedProject(firstProjectCode);
                    setSelectedProject(firstProjectCode);
                    fetchProcess(firstProjectCode);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                });
        };

        fetchProjects();
    }, []);

    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-auto h-[calc(100vh-12.2rem)] listboxscroll">
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div className="overflow-auto h-[calc(100vh-8rem)]">
                        {jsonContent === null ? (
                            'No data available'
                        ) : (
                            <table className="border-hidden w-auto">
                                <thead>
                                    <tr className="font-bold uppercase text-[16px] text-black whitespace-nowrap">
                                        <th className="p-2 border border-gray-300 bg-gray-200">
                                            Project Code
                                        </th>
                                        <th className="p-2 border border-gray-300 bg-gray-200">
                                            Filename
                                        </th>
                                        <th className="p-2 border border-gray-300 bg-gray-200">
                                            Created By
                                        </th>
                                        <th className="p-2 border border-gray-300 bg-gray-200">
                                            Created Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jsonContent.map((item, index) => (
                                        <tr key={index} className={`bg-white hover:bg-gray-100 whitespace-nowrap mb-10 lg:mb-0 text-left`}>
                                            <td className={`w-full lg:w-auto p-2 text-gray-800 border`}>
                                                {item.project_code}
                                            </td>
                                            <td className={`w-full lg:w-auto p-2 text-gray-800 border`}>
                                                {item.filename}
                                            </td>
                                            <td className={`w-full lg:w-auto p-2 text-gray-800 border`}>
                                                {item.creation_by}
                                            </td>
                                            <td className={`w-full lg:w-auto p-2 text-gray-800 border`}>
                                                {item.creation_date}
                                            </td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                }
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={false}
            />
            <>
                <div>
                    <div className="border border-gray-300 border-collapse rounded-md mb-2 mx-2 my-3 w-auto md:w-[calc(100vh-14rem)]">
                        <div className="p-1 ps-2 border-b border-gray-300 bg-gray-100 rounded-t-md">
                            <div className="flex flex-col md:flex-row gap-3 p-3 border-b">
                                <select
                                    className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={tempSelectedProject}
                                    onChange={(e) => setTempSelectedProject(e.target.value)}
                                >
                                    {projects
                                        ?.filter(item => item.project_status === 1)
                                        .map((item, index) => (
                                            <option key={index} value={item.project_code}>
                                                {item.project_name}
                                            </option>
                                        ))}
                                </select>
                                <button
                                    onClick={handleSelection}
                                    className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <table className="border-hidden w-full">
                                <thead>
                                    <tr className="font-bold uppercase text-[16px] text-black lg:whitespace-nowrap">
                                        <th className="p-2 border border-gray-300 bg-gray-200">
                                            Process
                                        </th>
                                        <th className="p-2 border border-gray-300 bg-gray-200">
                                            Execution
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                                            <td
                                                className="w-full lg:w-auto p-3 text-gray-800 border border-b block lg:table-cell relative lg:static"
                                                colSpan={9}
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <div
                                                        aria-label="Loading..."
                                                        role="status"
                                                    >
                                                        <svg
                                                            width={24}
                                                            height={24}
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            viewBox="0 0 24 24"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="animate-spin w-4 h-4 stroke-slate-500"
                                                        >
                                                            <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
                                                        </svg>
                                                    </div>
                                                    <span className="text-xs font-medium text-slate-500">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {data.map((itm, index) => (
                                                <tr
                                                    key={itm.process_name}
                                                    className={`bg-white lg:hover:bg-gray-100 lg:whitespace-nowrap mb-10 lg:mb-0 ${data.length === index + 1 && 'rounded-bl-md'}`}
                                                >
                                                    <td className={`w-full lg:w-auto p-2 text-gray-800 text-left border ${data.length === index + 1 && 'rounded-bl-md'}`}>
                                                        {itm.process_name}
                                                    </td>
                                                    <td
                                                        className={`w-full lg:w-auto p-2 text-gray-800 text-center border ${itm.success > 0 ? 'cursor-pointer hover:text-blue-500' : 'cursor-not-allowed'} ${clickedSuccessIndex === index ? 'text-blue-500' : ''}`}
                                                        onClick={itm.success > 0 ? () => openReadModal(index, itm.process_name, itm.filenames, itm.creation_by, itm.project_code, itm.creation_date) : null}
                                                    >
                                                        {itm.success}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}
