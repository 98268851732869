import { Tab } from '@headlessui/react';
import React, { useState } from 'react'
import JSONLogListTable from './JSONLogListTable';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function TabwiseJSONResponseTable({data}) {
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    return (
    <Tab.Group
        as="div"
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        className="border border-gray-300 rounded-md"
    >
        <Tab.List className="flex justify-start border-b grayscroll overflow-auto">
            <div className="flex gap-2 md:gap-3">
                {Object.keys(data).map((tab, index) => (
                    <Tab
                        key={index}
                        className={({ selected }) =>
                            classNames(
                                "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                "ring-white focus:outline-none focus:ring-0",
                                selected
                                    ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                    : "text-black pb-3 lg:pb-3.5"
                            )
                        }
                    >
                        <p className="text-sm pt-4 tracking-wider">
                            {tab}
                        </p>
                    </Tab>
                ))}
            </div>
        </Tab.List>
        <Tab.Panels className='h-[calc(100dvh-12.5rem)] md:h-[calc(100dvh-10.5rem)] lg:h-[calc(100dvh-9.5rem)] grayscroll'>
            {Object.keys(data).map((tab, index) => (
                <Tab.Panel
                    key={index}
                    className={classNames(
                        "bg-white",
                        "ring-white focus:outline-none focus:ring-0"
                    )}
                >
                    <JSONLogListTable 
                        searchbar={false} showIndex={false} overflowHeight="h-[calc(100dvh-12.5rem)] md:h-[calc(100dvh-10.5rem)] lg:h-[calc(100dvh-9.5rem)] grayscroll" 
                        data={data[tab]}
                    />
                </Tab.Panel>
            ))}
        </Tab.Panels>
    </Tab.Group>
  )
}

export default TabwiseJSONResponseTable