import React from 'react';
import {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronDoubleRightIcon,
    ChevronRightIcon,
} from "@heroicons/react/20/solid";

const Pagination = ({
  pageIndex,
  setPageIndex,
  pageNumbers,
  pageCount,
}) => {
  return (
    <nav
      className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900"
      aria-label="Pagination"
    >
      <button
        title="First Page"
        onClick={() => setPageIndex(0)}
        className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <button
        title="Previous Page"
        onClick={() => setPageIndex(pageIndex - 1)}
        disabled={pageIndex === 0}
        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      {pageNumbers.map((pageNumber, index) => {
        const isCurrent = pageNumber === pageIndex;
        const isBoundary = pageNumber === 0 || pageNumber === pageCount - 1;
        const isCloseToCurrent = Math.abs(pageNumber - pageIndex) <= 1;

        if (isBoundary || isCloseToCurrent) {
          return (
            <button
              key={index}
              onClick={() => setPageIndex(pageNumber)}
              className={`relative inline-flex items-center px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                isCurrent ? 'bg-gray-200' : ''
              }`}
            >
              {pageNumber + 1}
            </button>
          );
        } else if (pageNumber === 1 || pageNumber === pageCount - 2) {
          return (
            <button
              key={index}
              className="relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              ...
            </button>
          );
        }

        return null;
      })}
      <button
        title="Next Page"
        onClick={() => setPageIndex(pageIndex + 1)}
        disabled={pageIndex === pageCount - 1}
        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <button
        title="Last Page"
        onClick={() => setPageIndex(pageCount - 1)}
        className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </nav>
  );
};

export default Pagination;
