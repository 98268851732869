import React, { useCallback, useEffect, useState} from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ArrowPathIcon} from '@heroicons/react/24/outline';
import Popup from '../Common/Popup';
import axios from 'axios';
import { API_URL } from '../../imports';
import { handleApiError } from '../Common/APIUtils';
import NoDataFound from '../Common/NoDataFound';

export default function ExecutionTime() {
  let [rowData, setRowData]=useState({});
  let [edit, setEdit]=useState({});
  let [show, setShow] =useState(true);
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const ReloadData = useCallback(async (process_code) => {
    axios.get(`${API_URL}/processexectime/${sessionStorage.getItem("projectCode")}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }).then((response) => {
          setData(response.data.data);
          if(response.data.data.length===0){
            setShow(false);
          }
          else{
            setShow(true);
            if(!process_code){
                // Update the process states based on the fetched execution times
                const updatedRowData = {};
                response.data.data.forEach(item => {
                  updatedRowData[item.process_code] = item.ptime;
                });

                // Set the updated process state execution times
                setRowData(updatedRowData);

                // Update the process states
                const updatedProcessData = {};
                response.data.data.forEach(item => {
                  updatedProcessData[item.process_code] = false;
                });

                // Set the updated process edit state
                setEdit(updatedProcessData);
            }
            else{
                updateEdit(process_code, false);
            }
          }
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setShow(false);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        });
  },[]);

  // Function to update rowData for a specific process
  const updateRowData = (processCode, value) => {
    setRowData(prevRowData => ({
      ...prevRowData,
      [processCode]: value
    }));
  };

  // Function to update edit for a specific process
  const updateEdit = (processCode, value) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [processCode]: value
    }));
  };

  // Function to cancel edit & set to default process exec_time
  const cancelEdit = (processCode, exec_time) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [processCode]: false
    }));
    setRowData(prevRowData => ({
      ...prevRowData,
      [processCode]: exec_time
    }));
  };

  const handleUpdateProcessTime = useCallback(async(process_code) => {
    if(rowData[process_code]<101 && rowData[process_code]>0)
    {
    try {
        await axios.put(`${API_URL}/processexectime/${sessionStorage.getItem("projectCode")}`, {process_code: process_code, ptime: rowData[process_code]}, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  },
                })
                .then((response) => {
                  ReloadData(process_code);
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            openPopup();
        }
    } else {
      setMsg('Process Minutes must be between 1 and 30');
      setIsSuccess(false);
      openPopup();
    }
  },[ReloadData, rowData]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "index",
        size: 20,
        header: "#",
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'process_name',
        size: 1000,
        header: () => <span>Process Name</span>
      },
      {
        accessorKey: 'ptime',
        header: 'Execution Time (Minutes)',
        size: 100,
        cell:({ row }) => (
            <>
            {!edit[row.original.process_code] && <center title='Click to update process time' className='cursor-default lg:cursor-pointer' onClick={()=>updateEdit(row.original.process_code, true)}>{row.original.ptime}</center>}
            {edit[row.original.process_code] && <div className='flex gap-2 justify-center'><input autoFocus type='number' min={1} max={100} className="p-1 border rounded border-gray-400 w-14" id={row.original.process_code} value={rowData[row.original.process_code]} onChange={(e)=> updateRowData(row.original.process_code, e.target.value)}/><button className='rounded-md cursor-default lg:cursor-pointer py-1.5 px-2 inline-flex text-sm border border-transparent bg-blue-100 text-blue-900' onClick={()=>handleUpdateProcessTime(row.original.process_code)}>update</button><button className='rounded-md cursor-default lg:cursor-pointer py-1.5 px-2 bg-red-100 text-red-900 inline-flex text-sm border border-transparent'  onClick={()=>cancelEdit(row.original.process_code, row.original.ptime)}>cancel</button></div>}
            </>
        )
      },
    ],
    [edit, rowData, handleUpdateProcessTime]
  );

  const [data,setData]=useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableContainerRef = React.useRef(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: data.length,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  useEffect(() => {
     const fetchInitialData = async () => {
            axios
                .get(`${API_URL}/processexectime/${sessionStorage.getItem("projectCode")}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                })
                .then((response) => {
                    setData(response.data.data);
                    if(response.data.data.length===0){
                      setShow(false);
                    } 
                    else {
                      // Update the process states based on the fetched execution times
                      const updatedRowData = {};
                      response.data.data.forEach(item => {
                        updatedRowData[item.process_code] = item.ptime;
                      });

                      // Set the updated process state execution times
                      setRowData(updatedRowData);

                      // Update the process states
                      const updatedProcessData = {};
                      response.data.data.forEach(item => {
                        updatedProcessData[item.process_code] = false;
                      });

                      // Set the updated process edit state
                      setEdit(updatedProcessData);
                    }
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setShow(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
      };
      fetchInitialData();
    }, []);

  return (
    <div className="border bg-white font-GoogleSans tracking-wider overflow-hidden">
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <div className='flex justify-end my-2 mx-3 gap-2'>
      <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={()=>ReloadData()}>
      <ArrowPathIcon className='h-5 text-green-700'/>
      </button>
      </div>
      {!show &&<NoDataFound />}
      {show && (
      <>
      <div ref={tableContainerRef} className='resp-table-h-config border-y overflow-auto'>
        <table className='flex-inline w-full border-collapse'>
          <thead className='shadow bg-gray-100'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className='border p-2 whitespace-nowrap'
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                        <div
                          className={`${
                            header.column.getCanSort() && header.column.id!=='index' && header.column.id!=='ptime'
                              ? 'cursor-pointer select-none justify-start'
                              : 'justify-center'
                          } flex`}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className='border p-2 whitespace-nowrap' key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
        </>
        )}
    </div>
  );
}