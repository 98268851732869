import React, { Fragment, useEffect, useState } from "react";
import { ixologo } from "../../imports";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { Menu, Transition } from "@headlessui/react";
import axios from 'axios';
import { API_URL } from '../../imports';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Header({ mainTitleChange, mainTitle }) {
    const [data, setData] = useState({ name: "" })
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("logout-event", "logout");
        navigate("/");
    };

    const [title, setTitle] = useState("Meghnad Automation");

    useEffect(() => {
        if (mainTitleChange === true) {
            setTitle(sessionStorage.getItem("projectName"));
        } else if (mainTitle) {
            setTitle(mainTitle);
        } else {
            setTitle("Meghnad Automation");
        }
    }, [mainTitleChange, mainTitle]);

    useEffect(() => {
        const handleLogoutEvent = (event) => {
            // Check if logout-event key is changed
            if (event.key === "logout-event") {
                // Clear localStorage
                localStorage.clear();
                // Redirect to login page
                navigate("/");
            }
        };
        // Add the listener to the window object
        window.addEventListener("storage", handleLogoutEvent);
        // Return a cleanup function to remove the listener
        return () => {
            window.removeEventListener("storage", handleLogoutEvent);
        };
    }, [navigate]);

    useEffect(() => {
        const fetchInitialData = async () => {
            axios
                .get(`${API_URL}/auth/profileinfo`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                })
                .then((response) => {
                    const profileInfo = response.data.data;
                    setData(profileInfo);
                })
                .catch((error) => {
                    console.log("username not found")
                });
        };
        fetchInitialData();
    }, []);


    const processProfileName = (name) => {
        const wholeNameLength = name.length;
        const words = name.split(" ");
        const sptCnt = words.length;
        let displayName;

        if (wholeNameLength > 10 && sptCnt > 1) {
            displayName = words.map((word) => word[0]).join("");
        } else {
            displayName = name;
        }
        return displayName;
    };

    return (
        <header className="bg-white select-none">
            <nav
                className="mx-auto flex w-full border-b items-center justify-between p-2 bg-zinc-50"
                aria-label="Global"
            >
                <div className="flex">
                    <a href="/" className="p-1.5 px-2 focus:outline-none">
                        <img
                            className="h-10 w-auto scale-110"
                            src={ixologo}
                            alt="Company Logo"
                        />
                    </a>
                </div>
                <div className="hidden md:flex text-lg font-bold text-indigo-950 tracking-widest md:mr-20 xl:mr-16">
                    {title}
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="relative mx-3">
                    <div className="flex flex-row gap-2">
                        <span className="uppercase">{processProfileName(data.name)}</span>
                        <Menu.Button className="relative flex cursor-default lg:cursor-pointer rounded-md p-1 text-sm  ring-gray-800">
                            <span className="absolute -inset-1.5" />
                            <FaUserCircle className="h-4 w-auto" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={() => navigate("/profileinfo")}
                                        className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                    >
                                        My Profile
                                    </div>
                                )}
                            </Menu.Item>
                            {(localStorage.getItem("role") === "NONADMIN") && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            onClick={() =>
                                                navigate("/settings/directory")
                                            }
                                            className={classNames(
                                                active ? "bg-gray-100" : "",
                                                "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                        >
                                            Switch Directory
                                        </div>
                                    )}
                                </Menu.Item>
                            )}
                            {localStorage.getItem("role") !== "ADMIN" && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            onClick={() =>
                                                navigate("/nonadmin_activity_log")
                                            }
                                            className={classNames(
                                                active ? "bg-gray-100" : "",
                                                "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                        >
                                            Activity Log
                                        </div>
                                    )}
                                </Menu.Item>
                            )}
                            {(localStorage.getItem("role") === "ADMIN" ||
                                localStorage.getItem("level") === "2") && (
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/instanceservicemonitoring")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                Instance Monitoring
                                            </div>
                                        )}
                                    </Menu.Item>
                                )}
                            {localStorage.getItem("role") === "ADMIN" && (
                                <>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/serverUrl")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                Service URL
                                            </div>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/apiDocument")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                Version Upgrade Documentation
                                            </div>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/system_configuration")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                System Configuration
                                            </div>
                                        )}
                                    </Menu.Item>
                                </>
                            )}
                            {localStorage.getItem("role") !== "ADMIN" && (
                                <>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/projectreport")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                Project Report
                                            </div>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/apireport")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                API Summary
                                            </div>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/backgroundprocess")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                Background Process
                                            </div>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/copytranscripts")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                Copy Transcript
                                            </div>
                                        )}
                                    </Menu.Item>
                                    {sessionStorage.getItem("projectCode") && 
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() =>
                                                    navigate("/qdbprompts")
                                                }
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700"
                                                )}
                                            >
                                                QDB Prompts
                                            </div>
                                        )}
                                    </Menu.Item>}
                                </>
                            )}
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={handleLogout}
                                        className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                    >
                                        Sign out
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </nav>
        </header>
    );
}

export default Header;
