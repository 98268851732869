import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ArrowDownCircleIcon,
    ArrowDownTrayIcon,
    ArrowPathIcon,
    EyeIcon,
    FunnelIcon,
    ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import Popup from "./Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import { DebouncedInput, IndeterminateCheckbox, StatusFilter } from "./Filters";
import ReadModal from "./ReadModal";
import LogTable from "./LogTable";
import { handleApiError } from "./APIUtils";
import FetchingDataLoader from "./FetchingDataLoader";
import ClosingDataLoader from "./ClosingDataLoader";
import { formattedDate, generateProcessCodeFileNameAlreadyDT } from "./CommonUtils";
import { Menu, Transition } from "@headlessui/react";
import DropdownModal from "./DropdownModal";
import IndexBasedRangeSelection from "./IndexBasedRangeSelection";
import DownloadRawRefinedResponseModal from "./DownloadRawRefinedResponseModal";

export default function FilewiseStatusLogs({
    fetchFilewiseAPIURL,
    fileDetailsAPIURL,
    CloseAPIURL,
    process_code,
    singleCSVDownloadAPI,
    multipleCSVDownloadAPI,
    multipleLogsDownloadAPI,
    startIndex,
    endIndex,
    setStartIndex,
    setEndIndex,
    rowSelection,
    setRowSelection
}) {
    let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    let [modalTitle, setModalTitle] = useState(false);
    let [detailsState, setDetailsState] = useState(false);
    let [fileInfoData, setFileInfoData] = useState([]);
    const [Loader, setLoader] = useState(true);
    const [closeLoader, setCloseLoader] = useState(false);
    const [jsonValue, setJsonValue] = useState(null);

    function closePopup() {
        setIsOpenPopup(false);
        setCloseLoader(false);
    }

    const openPopup = useCallback(() => {
        setIsOpenPopup(true);
    }, [setIsOpenPopup]);

    function closeReadModal() {
        setIsOpenRead(false);
    }

    const FileDetailsData = useCallback(
        async (filename) => {
            axios
                .post(
                    `${API_URL}/${fileDetailsAPIURL}`,
                    { filename: filename },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    const keyOrder = [
                        "Status",
                        "Status Code",
                        "Status Message",
                        "Params",
                        "QuestionDB",
                        "Merged",
                        "Finalized",
                        "Processed By",
                        "Start Date",
                        "Execution Date",
                        "Response",
                        "id",
                    ];

                    // Map over the entries and create objects with the desired key order
                    const formattedData = response.data.data.map((entry) => {
                        const formattedEntry = {};
                        keyOrder.forEach((key) => {
                            formattedEntry[key] = entry[key];
                        });
                        return formattedEntry;
                    });
                    setFileInfoData(formattedData);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [setFileInfoData, setMsg, setIsSuccess, openPopup, fileDetailsAPIURL]
    );

    const getProcessResponse = useCallback(
        async (id) => {
            axios
                .post(
                    `${API_URL}/entries/getresponse`,
                    {
                        id: id,
                        process_code: process_code,
                        proj_code: sessionStorage.getItem("projectCode"),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    // Decode the base64 string
                    const decodedJsonString = atob(response.data.data);
                    setJsonValue(JSON.parse(decodedJsonString));
                    // setJsonValue(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [process_code, openPopup]
    );

    const openReadModal = useCallback(
        (e, options) => {
            setRowData(e);
            if (options === "response") {
                setModalTitle(`Response of ${e.filename}`);
                setDetailsState(false);
                getProcessResponse(e.id);
            } else {
                setModalTitle(` ${e.filename}`);
                setDetailsState(true);
                FileDetailsData(e.filename);
            }
            setIsOpenRead(true);
        },
        [
            setRowData,
            setModalTitle,
            setDetailsState,
            FileDetailsData,
            setIsOpenRead,
            getProcessResponse,
        ]
    );

    const ReloadData = async () => {
        setRowSelection({});
        setStartIndex(undefined);
        setEndIndex(undefined);
        setLoader(true);
        axios
            .get(`${API_URL}/${fetchFilewiseAPIURL}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            });
    };

    const handleSingleDownloadCSV = useCallback(
        async (file) => {
            axios
                .post(
                    `${API_URL}/${singleCSVDownloadAPI}`,
                    { id: file.id },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    // Decode the base64-encoded content to binary
                    const binaryContent = atob(response.data.download_content);

                    // Create a Uint8Array from the binary content
                    const uint8Array = new Uint8Array(binaryContent.length);
                    for (let i = 0; i < binaryContent.length; i++) {
                        uint8Array[i] = binaryContent.charCodeAt(i);
                    }

                    // Create a Blob from the Uint8Array
                    const blob = new Blob([uint8Array], { type: "text/csv" }); // Adjust type based on your file type

                    // Create a URL object from the Blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${generateProcessCodeFileNameAlreadyDT(
                        file.filename,
                        file.creation_date
                    )}.csv`;

                    // Append the link to the document body
                    document.body.appendChild(link);

                    // Trigger the download
                    link.click();

                    // Cleanup
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        },
        [openPopup, singleCSVDownloadAPI]
    );

    const DownloadCSVAPI = async (filenames, qdbsSelected) => {
        setRowSelection({});
        axios
            .post(
                `${API_URL}/${multipleCSVDownloadAPI}`,
                { files_list: filenames, qdbs: qdbsSelected },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                // Decode the base64-encoded content to binary
                const binaryContent = atob(response.data.download_content);

                // Create a Uint8Array from the binary content
                const uint8Array = new Uint8Array(binaryContent.length);
                for (let i = 0; i < binaryContent.length; i++) {
                    uint8Array[i] = binaryContent.charCodeAt(i);
                }

                // Create a Blob from the Uint8Array
                const blob = new Blob([uint8Array], { type: "text/csv" }); // Adjust type based on your file type

                // Create a URL object from the Blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element
                const link = document.createElement("a");
                link.href = url;
                link.download = `${process_code}_${sessionStorage.getItem(
                    "projectName"
                )}.csv`;

                // Append the link to the document body
                document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };

    const handleDownloadCSV = (qdbs) => {
        // Filter the Files based on the rowSelection
        const selectedFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
            const files = selectedFiles.map((file) => file.filename);
            DownloadCSVAPI(files,qdbs);
        } else if (selectedFiles.length === data.length) {
            DownloadCSVAPI("All",qdbs);
        } else {
            DownloadCSVAPI("All",qdbs);
        }
    };

    
    const DownloadLogsAPI = async (files_list, latest) => {
        setRowSelection({});
        axios
            .post(
                `${API_URL}/${multipleLogsDownloadAPI}`,
                { files_list: files_list, latest: latest },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                // Decode the base64-encoded content to binary
                const binaryContent = atob(response.data.download_content);

                // Create a Uint8Array from the binary content
                const uint8Array = new Uint8Array(binaryContent.length);
                for (let i = 0; i < binaryContent.length; i++) {
                    uint8Array[i] = binaryContent.charCodeAt(i);
                }

                // Create a Blob from the Uint8Array
                const blob = new Blob([uint8Array], { type: "text/csv" }); // Adjust type based on your file type

                // Create a URL object from the Blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element
                const link = document.createElement("a");
                link.href = url;

                if(latest===true){link.download = `${process_code}_${sessionStorage.getItem("projectName")}_latest_logs.csv`;}
                else{link.download = `${process_code}_${sessionStorage.getItem("projectName")}_logs.csv`;}

                // Append the link to the document body
                document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };

    const handleDownloadLogs = (latest) => {
        // Filter the Files based on the rowSelection
        const selectedFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if(latest===true){
            if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
                const fileIds = selectedFiles.map((file) => file.id);
                DownloadLogsAPI(fileIds,true);
            } else if (selectedFiles.length === data.length) {
                DownloadLogsAPI("All",true);
            } else {
                DownloadLogsAPI("All",true);
            }
        }
        else{
            if (selectedFiles.length > 0 && selectedFiles.length !== data.length) {
                const filenames = selectedFiles.map((file) => file.filename);
                DownloadLogsAPI(filenames,false);
            } else if (selectedFiles.length === data.length) {
                DownloadLogsAPI("All",false);
            } else {
                DownloadLogsAPI("All",false);
            }
        }
    };

    const JsonDownloadButton = ({ id, JSONfileName, disabledState }) => {
        const downloadJson = () => {
            axios
                .post(
                    `${API_URL}/entries/getresponse`,
                    {
                        id: id,
                        process_code: process_code,
                        proj_code: sessionStorage.getItem("projectCode"),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    // Decode the base64 string
                    const decodedJsonString = atob(response.data.data);
                    const blob = new Blob(
                        [JSON.stringify(JSON.parse(decodedJsonString))],
                        {
                            type: "application/json",
                        }
                    );
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = JSONfileName + ".json";
                    link.click();
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        };

        return (
            <button
                className="p-1 rounded-md border disabled:text-gray-300"
                onClick={downloadJson}
                disabled={disabledState}
            >
                <p className="flex gap-1" title="json file download">
                    <ArrowDownCircleIcon className="h-5" />
                </p>
            </button>
        );
    };

    const cols = [
        { id: "status", name: "Status" },
        { id: "qdb_code", name: "QDB" },
        { id: "status_code", name: "Status Code" },
        { id: "status_message", name: "Status Message" },
        { id: "closing", name: "Finalized" },
        { id: "start_date_time", name: "Start Date" },
        { id: "creation_date", name: "Execution Date" },
        { id: "creation_by", name: "Processed By" },
        { id: "id", name: "Actions" },
    ];

    const columnNames = cols.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
    }, {});

    const [sorting, setSorting] = React.useState([]);

    // Update rowSelection based on start and end index inputs
    const handleSetSelection = () => {
        if (startIndex !== null && endIndex !== null && startIndex <= endIndex) {
            const newSelection = {};
            for (let i = startIndex-1; i <= endIndex-1; i++) {
            newSelection[i] = true;  // Mark these indexes as selected
            }
            setRowSelection(newSelection);
        }
    };

    const initialcolumnVisibility = { status_code: false, creation_by: false };

    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "filename",
                enableColumnFilter: false,
                size: 650,
                header: () => <span>File Name</span>,
                cell: ({ row }) => (
                    <div
                        title="Click to get File Details"
                        onClick={() =>
                            openReadModal(row.original, "filedetails")
                        }
                        disabled={row.original.response ? false : true}
                        className="text-blue-600 cursor-default lg:cursor-pointer disabled:text-black"
                    >
                        {row.original.filename}
                    </div>
                ),
            },
            {
                accessorKey: "qdb_code",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>QDB</span>,
            },
            {
                accessorKey: "status",
                enableColumnFilter: true,
                filterFn: "equals",
                size: 50,
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            className={`${row.original.status === "success" &&
                                "bg-green-500"
                                } ${row.original.status === "error" && "bg-red-500"
                                } ${row.original.status === "Unprocessed" &&
                                "bg-yellow-500"
                                }  ${row.original.status !== "success" &&
                                row.original.status !== "error" &&
                                row.original.status !== "Unprocessed" &&
                                "bg-yellow-500"
                                } 
                            text-white text-xs w-fit px-2 py-1 font-bold rounded-xl flex flex-nowrap`}
                        >
                            {row.original.status && row.original.status}
                            {!row.original.status && "Unprocessed"}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "status_code",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Status Code</span>,
                cell: ({ row }) => <center>{row.original.status_code}</center>,
            },
            {
                accessorKey: "status_message",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Status Message</span>,
                cell: ({ row }) => (
                    <span title={row.original.status_message}>
                        {row.original.status_message !== null
                            ? row.original.status_message.slice(0, 20) +
                            (row.original.status_message.length > 20
                                ? "..."
                                : "")
                            : null}
                    </span>
                ),
            },
            {
                accessorKey: "closing",
                enableColumnFilter: false,
                size: 50,
                header: () => <span>Finalized</span>,
                cell: ({ row }) => (
                    <center>{row.original.closing === true ? 1 : ""}</center>
                ),
            },
            {
                accessorKey: "start_date_time",
                enableColumnFilter: false,
                size: 100,
                header: "Start Date",
                cell: ({ row }) => <div>{formattedDate(row.original.start_date_time)}</div>,
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 100,
                header: "Execution Date",
                cell: ({ row }) => <div>{formattedDate(row.original.creation_date)}</div>,
            },
            {
                accessorKey: "creation_by",
                enableColumnFilter: false,
                size: 50,
                header: "Processed By",
                cell: ({ row }) => (
                    <span
                        className="capitalize"
                        title={row.original.creation_by}
                    >
                        {row.original.creation_by !== null
                            ? row.original.creation_by.slice(0, 12) +
                            (row.original.creation_by.length > 12
                                ? "..."
                                : "")
                            : null}
                    </span>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <button
                            className="p-1 rounded-md border disabled:text-gray-300 text-green-800"
                            disabled={row.original.status_code ? false : true}
                            onClick={() =>
                                openReadModal(row.original, "response")
                            }
                        >
                            <EyeIcon className="h-4" />
                        </button>
                        <JsonDownloadButton
                            id={row.original.id}
                            JSONfileName={
                                row.original.status_code === "0" &&
                                row.original.creation_date &&
                                generateProcessCodeFileNameAlreadyDT(
                                    row.original.filename,
                                    row.original.creation_date
                                )
                            }
                            disabledState={
                                row.original.status_code === "0" &&
                                    row.original.qdb_type === "S"
                                    ? false
                                    : true
                            }
                        />
                        <button
                            className="p-1 rounded-md border disabled:text-gray-300 text-blue-800"
                            title="csv file download"
                            disabled={
                                row.original.status_code &&
                                    row.original.status_code === "0" &&
                                    row.original.qdb_type === "S"
                                    ? false
                                    : true
                            }
                            onClick={() =>
                                handleSingleDownloadCSV(row.original)
                            }
                        >
                            <ArrowDownTrayIcon className="h-4" />
                        </button>
                    </div>
                ),
            },
        ],
        [openReadModal, handleSingleDownloadCSV]
    );

    const [data, setData] = useState([]);

    const CloseAPI = async (closeData) => {
        setLoader(true);
        setCloseLoader(true);
        setRowSelection({});
        axios
            .post(
                `${API_URL}/${CloseAPIURL}`,
                {
                    fileslist: closeData,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setMsg(response.data.message);
                setIsSuccess(true);
                setTimeout(() => {
                    openPopup();
                    ReloadData();
                }, 100);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };
    const handleSelectedClose = () => {
        // Filter the Files based on the rowSelection
        const selectedCloseFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);
        if (selectedCloseFiles.length > 0) {
            const fileNames = selectedCloseFiles.map((file) => file.filename);
            CloseAPI(fileNames);
        }
    };

    const [globalFilter, setGlobalFilter] = React.useState("");

    const table = useReactTable({
        data,
        columns,
        initialState: {
            columnVisibility: initialcolumnVisibility,
        },
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    const tableContainerRef = React.useRef(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    const [questionDBs, setQuestionDBs] = useState(['']);

    useEffect(() => {
        const fetchInitialData = async () => {
            axios
                .get(`${API_URL}/${fetchFilewiseAPIURL}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoader(false);
                    }, 100);
                });
        };
        const fetchallQDB = async () => {
            axios
                .get(`${API_URL}/qdb/all/${sessionStorage.getItem("projectCode")}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    setQuestionDBs(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoader(false);
                    }, 100);
                });
        };
        fetchallQDB();
        fetchInitialData();
    }, [openPopup, setData, fetchFilewiseAPIURL]);

    return (
        <div className="m-2 border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            {/* <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={handleDeleteState}
            /> */}
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <>
                        {detailsState && (
                            <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                                <LogTable
                                    data={fileInfoData}
                                    filename={rowData.filename}
                                    process_code={process_code}
                                />
                            </div>
                        )}
                        {!detailsState && (
                            <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                                {jsonValue && (
                                    <pre className="pretxtwrap text-sm">
                                        {JSON.stringify(jsonValue, null, 2)}
                                    </pre>
                                )}
                                {!jsonValue && "No Response Found."}
                            </div>
                        )}
                    </>
                }
            />
            <div className="flex flex-wrap justify-between m-3 gap-2">
                <DebouncedInput
                    value={globalFilter ?? ""}
                    onChange={(value) => setGlobalFilter(String(value))}
                    placeholder="Search..."
                    wclass="w-full md:w-auto 2xl:w-[20rem]"
                />
                <div className="flex flex-wrap gap-2">
                    <IndexBasedRangeSelection startIndex={startIndex} setStartIndex={setStartIndex} endIndex={endIndex} setEndIndex={setEndIndex} handleSetSelection={handleSetSelection} max={data.length}/>
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={ReloadData}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                    <DropdownModal
                        icon={<ViewColumnsIcon className="h-5 w-auto" />}
                        body={table
                            .getAllLeafColumns()
                            .filter((column) =>
                                cols.map((col) => col.id).includes(column.id)
                            )
                            .map((column) => {
                                return (
                                    <div key={column.id} className="p-0.5 px-1">
                                        <label>
                                            <input
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                {...{
                                                    type: "checkbox",
                                                    checked:
                                                        column.getIsVisible(),
                                                    onChange:
                                                        column.getToggleVisibilityHandler(),
                                                }}
                                            />
                                            &nbsp;&nbsp;
                                            {columnNames[column.id]}
                                        </label>
                                    </div>
                                );
                            })}
                    />
                    <Menu as="div" className="relative">
                        <div>
                            <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                <span className="absolute -inset-1.5" />
                                <FunnelIcon className="h-5 w-auto" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div>
                                    {data.length > 0 &&
                                        table
                                            .getHeaderGroups()
                                            .map((headerGroup) => (
                                                <div
                                                    key={headerGroup.id}
                                                    className="space-y-1"
                                                >
                                                    {headerGroup.headers.map(
                                                        (header) => (
                                                            <div
                                                                key={header.id}
                                                            >
                                                                {header.column.getCanFilter() &&
                                                                    header.column
                                                                        .id ===
                                                                    "status" ? (
                                                                    <div>
                                                                        <label
                                                                            htmlFor="push-everything"
                                                                            className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                                                        >
                                                                            Status
                                                                        </label>
                                                                        <StatusFilter
                                                                            column={
                                                                                header.column
                                                                            }
                                                                            table={
                                                                                table
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            {Loader &&
                (!closeLoader ? <FetchingDataLoader /> : <ClosingDataLoader />)}
            {!Loader && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-processtable-h border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="text-sm lg:text-base bg-gray-200">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort() &&
                                                                        header
                                                                            .column
                                                                            .id !==
                                                                        "status"
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingTop}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr
                                                            key={row.id}
                                                            className="text-sm bg-white transition duration-300 ease-in-out hover:bg-gray-100"
                                                        >
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => {
                                                                    return (
                                                                        <td
                                                                            className="border p-2 whitespace-nowrap"
                                                                            key={cell.id}
                                                                        >
                                                                            {cell.column.id ===
                                                                                "index" && (
                                                                                    <center>
                                                                                        {renderTableCellIndex(
                                                                                            row
                                                                                        )}
                                                                                    </center>
                                                                                )}
                                                                            {cell.column.id !==
                                                                                "index" &&
                                                                                flexRender(
                                                                                    cell.column
                                                                                        .columnDef
                                                                                        .cell,
                                                                                    cell.getContext()
                                                                                )}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingBottom}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    {Object.keys(rowSelection).length > 0 && (
                        <div className="flex flex-wrap lg:justify-between gap-2 w-full p-3">
                            <div className="flex flex-wrap gap-2">
                            <button
                                type="button"
                                className="inline-flex whitespace-nowrap justify-center rounded-lg border border-transparent bg-blue-100 px-2 md:px-4 py-2 text-xs md:text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                onClick={handleSelectedClose}
                            >
                                Finalize
                            </button>
                            <DownloadRawRefinedResponseModal questionDBs={questionDBs} handleDownloadCSV={handleDownloadCSV}/>
                            {/* <button
                                type="button"
                                className="inline-flex whitespace-nowrap justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                onClick={handleDownloadCSV}
                            >
                                Download Latest Responses
                            </button> */}
                            <button
                                type="button"
                                className="inline-flex whitespace-nowrap justify-center rounded-lg border border-transparent bg-blue-100 px-2 md:px-4 py-2 text-xs md:text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                onClick={()=>handleDownloadLogs(true)}
                            >
                                Download Latest Logs
                            </button>
                            <button
                                type="button"
                                className="inline-flex md:hidden whitespace-nowrap justify-center rounded-lg border border-transparent bg-blue-100 px-2 md:px-4 py-2 text-xs md:text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                onClick={()=>handleDownloadLogs(false)}
                            >
                                Download All Logs
                            </button>
                            </div>
                            <button
                                type="button"
                                className="hidden md:inline-flex whitespace-nowrap justify-center rounded-lg border border-transparent bg-blue-100 px-2 md:px-4 py-2 text-xs md:text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                onClick={()=>handleDownloadLogs(false)}
                            >
                                Download All Logs
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
