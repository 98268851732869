import React, { useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronDoubleRightIcon,
    ChevronRightIcon,
} from "@heroicons/react/20/solid";
import {
    ArrowPathIcon,
    PencilIcon,
    TrashIcon,
    PlusCircleIcon
} from "@heroicons/react/24/outline";
import { initialpageSize } from "../Common/Contants";
import InputModal from "../Common/InputModal";
import Popup from "../Common/Popup";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import axios from "axios";
import { API_URL } from "../../imports";
import { DebouncedInput } from "../Common/Filters";
// import ReadModal from "../Common/ReadModal";
import { handleApiError } from "../Common/APIUtils";
import NoDataFound from "../Common/NoDataFound";
import PageSizeDropdown from "../Common/PageSizeDropdown";
import { formattedDate } from "../Common/CommonUtils";

export default function Regex() {
    let [isOpen, setIsOpen] = useState(false);
    // let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [edit, setEdit] = useState(false);
    let [show, setShow] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    function closeModal() {
        setIsOpen(false);
        setRowData([]);
        // setFiles([]);
    }

    function openModal(e, option) {
        setRowData(e);
        if (option === "edit") {
            setEdit(true);
        } else if (option === "create") {
            setEdit(false);
        }
        setIsOpen(true);
    }

    // function closeReadModal() {
    //     setIsOpenRead(false);
    // }

    // function openReadModal(e) {
    //     setRowData(e);
    //     setModalTitle(`Parameters Response of ${e.param_code}`);
    //     setIsOpenRead(true);
    // }

    const ReloadData = async () => {
        axios
            .get(
                `${API_URL}/regex/read_regex/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };

    const responseStateHandler = (responseMessage, responseState) => {
        setMsg(responseMessage);
        setIsSuccess(responseState);
        openPopup();
    };


    const handleCreateState = async () => {
        setIsOpen(false);
        if (!rowData.regex_pattern.trim()) {
            responseStateHandler("Unique Code Length is required", false);
            return;
        }

        try {
            const requestData = {
                regex_pattern: rowData.regex_pattern,
                split_keyword: rowData.split_keyword
            };

            await axios
                .post(
                    `${API_URL}/regex/regex_create/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };


    const handleEditState = async () => {
        setIsOpen(false);
        if (rowData.regex_pattern.trim() === "") {
            return;
        }

        const requestData = {
            regex_pattern: rowData.regex_pattern,
            split_keyword: rowData.split_keyword
        };

        try {
            await axios
                .put(
                    `${API_URL}/regex/update_regex/${sessionStorage.getItem(
                        "projectCode"
                    )}/${rowData.id}`,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState("");

    function closeStatusModal() {
        setIsOpenStatusModal(false);
    }

    function openStatusModal(e) {
        setRowData(e);
        setModalTitle(`Update Regex Status - ${e.project_code}`);
        setIsOpenStatusModal(true);
    }

    const handleStatusState = async () => {
        let statusData = {
            status:
                rowData.status === false || rowData.status === "false"
                    ? false
                    : true,
        };
        setIsOpenStatusModal(false);
        try {
            await axios
                .patch(
                    `${API_URL}/regex/regex_status/${sessionStorage.getItem(
                        "projectCode"
                    )}/${rowData.id}`,
                    statusData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    function closeConfirmPopup() {
        setIsOpenConfirmPopup(false);
    }

    function openConfirmPopup(e) {
        setRowData(e);
        setConfirmationMsg(
            "Are you sure that you want to delete Unique Code Length?"
        );
        setIsOpenConfirmPopup(true);
    }

    const handleDeleteState = async () => {
        setIsOpenConfirmPopup(false);
        try {
            await axios
                .delete(
                    `${API_URL}/regex/delete_regex/${sessionStorage.getItem(
                        "projectCode"
                    )}/${rowData.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 40,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "project_code",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>project Code</span>,
            },
            {
                accessorKey: "regex_pattern",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>Unique Code Length</span>,
            },
            {
                accessorKey: "split_keyword",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>Split File Keyword</span>,
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 300,
                header: "Created Date",
                cell: ({ row }) => (
                    <div>{formattedDate(row.original.creation_date)}</div>
                ),
            },
            {
                accessorKey: "creation_by",
                enableColumnFilter: false,
                size: 50,
                header: "Created By",
                cell: ({ row }) => (
                    <span
                        className="capitalize"
                        title={row.original.creation_by}
                    >
                        {row.original.creation_by !== null
                            ? row.original.creation_by.slice(0, 12) +
                            (row.original.creation_by.length > 12
                                ? "..."
                                : "")
                            : null}
                    </span>
                ),
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            onClick={() => openStatusModal(row.original)}
                            className={`${row.original.status === true
                                    ? "bg-green-300  text-green-900"
                                    : "bg-red-300 text-red-900"
                                } text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}
                        >
                            {row.original.status === true
                                ? "Active"
                                : "Inactive"}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        {/* <button
                            className="p-1 rounded-md border"
                            onClick={() => openReadModal(row.original)}
                        >
                            <EyeIcon className="h-4 text-green-800" />
                        </button> */}
                        <button
                            className="p-1 rounded-md border"
                            onClick={() => openModal(row.original, "edit")}
                        >
                            <PencilIcon className="h-4 text-blue-800" />
                        </button>
                        {/* <JsonDownloadButton
                            disabled={row.original.params ? false : true}
                            content={row.original.params}
                            fileName={row.original.param_code}
                        /> */}
                        <button
                            className="p-1 rounded-md border"
                            onClick={() => openConfirmPopup(row.original)}
                        >
                            <TrashIcon className="h-4 text-red-600" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const [data, setData] = useState([]);

    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const tableContainerRef = React.useRef(null);
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();
        return (
            <input
                type="text"
                value={columnFilterValue}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        const fetchInitialData = async () => {
            axios
                .get(
                    `${API_URL}/regex/read_regex/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    setData(response.data.data);
                    if (response.data.data.length === 0) {
                        setShow(false);
                    }
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setShow(false);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                });
        };
        fetchInitialData();
    }, []);

    return (
        <div className="border bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={handleDeleteState}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            {/* <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                        <pre className="pretxtwrap text-sm">
                            {JSON.stringify(rowData.params, null, 2)}
                        </pre>
                    </div>
                }
            /> */}
            <InputModal
                isOpen={isOpen}
                closeModal={closeModal}
                title={edit === false ? "Add Regex" : "Update Regex"}
                savefn={edit === false ? handleCreateState : handleEditState}
                modalInputs={
                    <div className="space-y-5">
                        <div>
                            <label
                                htmlFor="regex-pattern"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                String Before Unique Code
                            </label>
                            <input
                                type="text"
                                id="regex-pattern"
                                value={rowData.regex_pattern}
                                onChange={(e) => setRowData({ ...rowData, regex_pattern: e.target.value })}
                                placeholder={!edit?"Enter String Before Unique Code":"Enter Unique Code Length"}
                                className="mt-1 p-2 border rounded-md w-full"
                            />
                        </div>
                        <div>
                                <label
                                    htmlFor="split-keyword"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Split Keyword
                                </label>
                                <input
                                    type="text"
                                    id="split-keyword"
                                    value={rowData.split_keyword}
                                    onChange={(e) => setRowData({ ...rowData, split_keyword: e.target.value })}
                                    placeholder="Ex: call"
                                    className="mt-1 p-2 border rounded-md w-full"
                                />
                            </div>
                    </div>
                }
            />
            <InputModal
                isOpen={isOpenStatusModal}
                closeModal={closeStatusModal}
                title={modalTitle}
                savefn={handleStatusState}
                modalInputs={
                    <div className="flex gap-5">
                        <div className="flex items-center gap-x-2">
                            <input
                                id="status"
                                name="status"
                                type="radio"
                                value="true"
                                checked={
                                    rowData.status === true ||
                                    rowData.status === "true"
                                }
                                onChange={(e) =>
                                    setRowData({
                                        ...rowData,
                                        status: e.target.value,
                                    })
                                }
                                autoFocus={false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                            />
                            <label
                                htmlFor="push-everything"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Active
                            </label>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <input
                                id="status"
                                name="status"
                                type="radio"
                                value="false"
                                checked={
                                    rowData.status === false ||
                                    rowData.status === "false"
                                }
                                onChange={(e) =>
                                    setRowData({
                                        ...rowData,
                                        status: e.target.value,
                                    })
                                }
                                autoFocus={false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                            />
                            <label
                                htmlFor="push-email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Inactive
                            </label>
                        </div>
                    </div>
                }
            />
            <div className="flex justify-between m-3 gap-2">
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                </div>
                <div className="flex gap-2">
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => openModal("e", "create")}
                    >
                        <PlusCircleIcon className="h-5" />
                    </button>
                </div>
            </div>
            {!show && <NoDataFound />}
            {show && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h-config border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                        ? "cursor-pointer select-none justify-start"
                                                                        : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={
                                                                            table
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingTop}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr key={row.id}>
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => {
                                                                    return (
                                                                        <td
                                                                            className="border p-2 whitespace-nowrap"
                                                                            key={cell.id}
                                                                        >
                                                                            {flexRender(
                                                                                cell.column
                                                                                    .columnDef
                                                                                    .cell,
                                                                                cell.getContext()
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingBottom}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="flex gap-1 justify-between flex-row-reverse m-3">
                        <nav
                            className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900"
                            aria-label="Pagination"
                        >
                            <button
                                title="First Page"
                                onClick={() => setPageIndex(0)}
                                className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronDoubleLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                title="Previous Page"
                                onClick={() => setPageIndex(pageIndex - 1)}
                                disabled={pageIndex === 0}
                                aria-current="page"
                                className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            {pageNumbers.length===1 &&
                                <button
                                className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                key={1}
                                onClick={()=>setPageIndex(0)}
                                >
                                {1}
                                </button>
                            }
                            {pageNumbers.length>1 && pageNumbers.map((pageNumber, index) => {
                                if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                                if(table.getPageCount()!==0 && table.getPageCount()===1 && table.getPageCount()===pageNumber) {
                                    return (
                                    <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={index}
                                        onClick={()=>setPageIndex(pageNumber-1)}
                                    >
                                    {pageNumber}
                                    </button>
                                    );
                                }
                                else if(table.getPageCount()!==1 && table.getPageCount()>pageNumber) {
                                    return (
                                    <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={index}
                                        onClick={()=>setPageIndex(pageNumber)}
                                    >
                                    {pageNumber + 1}
                                    </button>
                                    );
                                }
                                else if(table.getPageCount()===0  && table.getPageCount()===pageNumber){
                                    return (
                                    <button
                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                        key={index}
                                        onClick={()=>setPageIndex(0)}
                                    >
                                    {1}
                                    </button>
                                    );
                                }
                                } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                                return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                                }
                                return null;
                            })}
                            <button
                                title="Next Page"
                                onClick={() => setPageIndex(pageIndex + 1)}
                                disabled={
                                    pageIndex === table.getPageCount() - 1
                                }
                                className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                title="Last Page"
                                onClick={() =>
                                    setPageIndex(table.getPageCount() - 1)
                                }
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </nav>
                        <PageSizeDropdown
                            dataLength={table.getPrePaginationRowModel().rows.length}
                            pageSize={pageSize}
                            handlePageChange={handlePageChange}
                            totalRows={table.getPrePaginationRowModel().rows.length}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
