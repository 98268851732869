import React, { useState, useEffect, useRef } from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import Popup from "../Common/Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import FetchingDataLoader from "../Common/FetchingDataLoader";
import { handleApiError } from "../Common/APIUtils";
import { useNavigate } from "react-router-dom";

function Directory() {
  let [data, setData] = useState([]);
  let [Loader, setLoader] = useState(true);
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [globalFilter, setGlobalFilter] = React.useState("");
  let [sorting, setSorting] = React.useState([]);

  const navigate = useNavigate();

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }


  const columns = React.useMemo(
    () => [
      {
        accessorKey: "client_name",
        enableColumnFilter: true,
        size: 1000,
        header: () => <span>Directory Name</span>,
      },
      {
        accessorKey: "id",
        header: "",
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className="flex gap-1 items-center justify-center">
            {sessionStorage.getItem('client')===row.original.client_code?
              <><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="#12B76A" />
              <path
                d="M11.4375 5.8125L7.0625 10.1875L4.875 8"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Current</span></>
              :
              <button className="border py-0.5 px-2 rounded border-black -my-0.5" onClick={()=>{localStorage.setItem('client',row.original.client_code);sessionStorage.setItem('client',row.original.client_code);navigate('/')}}>Switch</button>
            }
          </div>
        ),
      },
    ],
    [navigate]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const tableContainerRef = useRef(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: rows.length,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  useEffect(() => {
    const fetchInitialData = async () => {
      axios
        .get(`${API_URL}/users/directory`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    fetchInitialData();
  }, []);

  return (
    <div className="mx-1 mt-1 font-Inter text-sm">
      <Popup
          isOpen={isOpenPopup}
          msg={msg}
          closeModal={closePopup}
          isSuccess={false}
      />
      <p>
        Switching directories will reload the portal. The directory you choose
        will impact the projects, project group and processes that
        are available in the portal.
      </p>
      {Loader && <FetchingDataLoader />}
      {!Loader && (
        <div
          ref={tableContainerRef}
          className="max-h-[calc(100dvh-16.8rem)] md:max-h-[calc(100dvh-15rem)] xl:max-h-[calc(100dvh-13.8rem)] overflow-auto relative"
        >
          <table className="flex-inline w-full mt-5 border-b border-gray-300">
            <thead className="">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className="border-b border-gray-300 bgclr font-medium py-2 whitespace-nowrap"
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              className={`${
                                header.column.getCanSort()
                                  ? "cursor-pointer select-none justify-start"
                                  : "justify-center"
                              } flex`}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <div className="ms-0.5"><svg className="text-gray-600" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up-narrow-wide"><path d="m3 8 4-4 4 4"/><path d="M7 4v16"/><path d="M11 12h4"/><path d="M11 16h7"/><path d="M11 20h10"/></svg></div>,
                                desc: <div className="ms-0.5"><svg className="text-gray-600" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up-wide-narrow"><path d="m3 8 4-4 4 4"/><path d="M7 4v16"/><path d="M11 12h10"/><path d="M11 16h7"/><path d="M11 20h4"/></svg></div>,
                              }[header.column.getIsSorted()] || null}
                            </div>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.length === 0 ? (
                <tr>
                  <td
                    className="border-t border-gray-300 p-2"
                    colSpan={table.getHeaderGroups()[0].headers.length}
                    style={{ textAlign: "center" }}
                  >
                    No Directories Found
                  </td>
                </tr>
              ) : (
                <>
                  {virtualRows.map((virtualRow) => {
                    const row = rows[virtualRow.index];
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell, index) => {
                          return (
                            <td
                              className="border-t border-gray-300 py-2 whitespace-nowrap"
                              key={index}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Directory;
