import React, { Fragment, useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
    getFacetedUniqueValues,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { EyeIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { initialpageSize } from "../Common/Contants";
import Popup from "../Common/Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import FetchingDataLoader from "../Common/FetchingDataLoader";
import NoDataFound from "../Common/NoDataFound";
import { handleApiError } from "../Common/APIUtils";
import SearchableDropdown from "../Common/SearchableDropdown";
import ReadModal from "../Common/ReadModal";
import { Menu, Transition } from "@headlessui/react";
import { SelectTextFilterWithAllOption } from "../Common/Filters";
import PageSizeDropdown from "../Common/PageSizeDropdown";
import Pagination from "../Common/Pagination";

export default function ActivityLog() {
    let [isOpenRead, setIsOpenRead] = useState(false);
    let [rowData, setRowData] = useState([]);
    const [Loader, setLoader] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [sourceProject, setSourceProject] = useState({});
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    const handleFetchActivityLogsDatewise = () => {
        const params = {
            start_date: startDate,
            end_date: endDate,
        };

        if (sourceProject.project_code) {
            params.project_code = sourceProject.project_code;
        }
        if (selectedUser.user_id) {
            params.user_id = selectedUser.user_id;
        }

        axios
            .get(`${API_URL}/activity/timeline_based_activity`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                params,
            })
            .then((response) => {
                const dataWithProjectCodes = response.data.data.map((item) => ({
                    ...item,
                    project_code: item.project_code || 'NA',
                }));
                setData(dataWithProjectCodes);
                setFetchedData(dataWithProjectCodes);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            });
    };


    let [modalTitle, setModalTitle] = useState("");
    function closeReadModal() {
        setIsOpenRead(false);
    }
    function openReadModal(e) {
        setRowData(e);
        setModalTitle(`Result of ${e.activity}`);
        setIsOpenRead(true);
    }
    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "project_code",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Project Code</span>,
                cell: ({ row }) => (
                    <p>
                        {row.original.project_code
                            ? row.original.project_code
                            : "NA"}
                    </p>
                ),
            },
            {
                accessorKey: "activity",
                enableColumnFilter: true,
                size: 100,
                header: () => <span>Activity</span>,
            },
            {
                accessorKey: "entity",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Entity</span>,
            },
            {
                accessorKey: "operation",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Operation</span>,
            },
            {
                accessorKey: "user_id",
                enableColumnFilter: false,
                enableSorting: false,
                size: 200,
                header: () => <span>User</span>,
                cell: ({ row }) => (
                    <div className="text-start">
                        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-0 m-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                            {row.original.user_id} - {row.original.user_name}
                        </span>
                    </div>
                ),
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 150,
                header: "Created Date",
                cell: ({ row }) => <div>{row.original.creation_date}</div>,
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <button
                            className="p-1 rounded-md border"
                            onClick={() => openReadModal(row.original)}
                        >
                            <EyeIcon className="h-4 text-green-800" />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const [data, setData] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    const renderTableCellIndex = (row) => {
        return (
            table.getFilteredRowModel().rows.indexOf(row) + 1 ||
            table.getSortedRowModel().rows.indexOf(row) + 1 ||
            row.index + 1
        );
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await axios.get(`${API_URL}/activity/read_activity_log`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                });
                const dataWithProjectCodes = response.data.data.map((item) => ({
                    ...item,
                    project_code: item.project_code || 'NA',
                    user_id: item.user_id || 'NA'
                }));
                setData(dataWithProjectCodes);
                setFetchedData(dataWithProjectCodes);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                openPopup();
            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
        };

        const fetchAllProjects = async () => {
            axios
                .get(`${API_URL}/activity/allprojects_in_db`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    const updatedProjects = response.data.data.map(project => ({
                        ...project,
                        displayName: `${project.project_code} - ${project.project_name}`
                    }));
                    setProjectList(updatedProjects);
                    setSourceProject({});
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        const fetchAllUsers = async () => {
            axios
                .get(`${API_URL}/activity/allusers_in_db`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    const updatedUsers = response.data.data.map(user => ({
                        ...user,
                        displayName: `${user.user_id} - ${user.user_name}`
                    }));
                    setUserList(updatedUsers);
                    setSelectedUser({});
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        fetchInitialData();
        fetchAllProjects();
        fetchAllUsers();
    }, []);

    useEffect(() => {
        if (selectedUser.user_id || sourceProject.project_code) {
            if (selectedUser.user_id && sourceProject.project_code) {
                setData(
                    fetchedData.filter(
                        (item) =>
                            item.project_code === sourceProject?.project_code &&
                            item.user_id === selectedUser?.user_id
                    )
                );
            } else {
                setData(
                    fetchedData.filter(
                        (item) =>
                            item.project_code === sourceProject?.project_code ||
                            item.user_id === selectedUser?.user_id
                    )
                );
            }
        } else {
            setData(fetchedData);
        }
    }, [fetchedData]);
    useEffect(() => {
        const currentDate = new Date();
        const oneWeekAgo = new Date(
            currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        );
        const formattedStartDate = oneWeekAgo.toISOString().split("T")[0];
        const formattedEndDate = currentDate.toISOString().split("T")[0];
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    }, []);
    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ReadModal
                isOpen={isOpenRead}
                closeModal={closeReadModal}
                title={modalTitle}
                modalInputs={
                    <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
                        <pre className="pretxtwrap text-sm">
                            {JSON.stringify(rowData.result, null, 2)}
                        </pre>
                    </div>
                }
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex flex-col lg:flex-row m-1 justify-start lg:justify-between lg:items-end space-x-4 p-2">
                <div className="flex flex-col lg:flex-row lg:items-end gap-4">
                    <div>
                        <label htmlFor="sourceProject" className="text-sm">
                            Project
                        </label>

                        <SearchableDropdown
                            items={projectList}
                            selected={sourceProject}
                            setSelected={setSourceProject}
                            filterBy="displayName"
                            enableEmptySelectOption={true}
                            emptyOptionName="All Projects"
                            placeholder="All Projects"
                        />
                    </div>
                    <div>
                        <label htmlFor="sourceProject" className="text-sm">
                            User
                        </label>

                        <SearchableDropdown
                            items={userList}
                            selected={selectedUser}
                            setSelected={setSelectedUser}
                            filterBy="displayName"
                            enableEmptySelectOption={true}
                            emptyOptionName="All Users"
                            placeholder="All Users"
                        />
                    </div>

                    <div>
                        <label className="text-sm">Start Date</label>
                        <input
                            className="w-full border-gray-300 border rounded-md text-xs p-2.5 mt-1"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="text-sm">End Date</label>
                        <input
                            className="w-full border-gray-300 border rounded-md text-xs p-2.5 mt-1"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                            onClick={handleFetchActivityLogsDatewise}
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className="flex justify-end gap-2">
                    <Menu as="div" className="relative">
                        <div>
                            <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                <span className="absolute -inset-1.5" />
                                <FunnelIcon className="h-5 w-auto" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div>
                                    {data.length > 0 &&
                                        table
                                            .getHeaderGroups()
                                            .map((headerGroup) => (
                                                <div
                                                    key={headerGroup.id}
                                                    className="space-y-1"
                                                >
                                                    {headerGroup.headers.map(
                                                        (header) => (
                                                            <div
                                                                key={header.id}
                                                            >
                                                                {header.column.getCanFilter() &&
                                                                    header.column
                                                                        .id ===
                                                                    "activity" ? (
                                                                    <div>
                                                                        <label
                                                                            htmlFor="push-everything"
                                                                            className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                                                        >
                                                                            Activity
                                                                        </label>
                                                                        <SelectTextFilterWithAllOption
                                                                            column={
                                                                                header.column
                                                                            }
                                                                            table={
                                                                                table
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>

            {Loader && <FetchingDataLoader />}
            {!Loader && data.length === 0 && (
                <div className="resp-table-h-activity">
                    <NoDataFound />
                </div>
            )}
            {!Loader && data.length > 0 && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h-activity-log border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {paddingTop > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2 whitespace-nowrap"
                                            style={{
                                                height: `${paddingTop}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                                {virtualRows.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr key={row.id}>
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className="border p-2 whitespace-nowrap"
                                                            key={cell.id}
                                                        >
                                                            {cell.column.id === "index" && (
                                                                <center>{renderTableCellIndex(row)}</center>
                                                            )}
                                                            {cell.column.id !== "index" &&
                                                                flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext()
                                                                )}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                                {paddingBottom > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            style={{
                                                height: `${paddingBottom}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {
                        rows.length > 0 && (
                            <div className='flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3'>
                                {/* <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
                                    <button title='First Page' onClick={() => setPageIndex(0)}
                                        className="relative inline-flex items-center rounded-l-md px-1.5 md:px-2 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleLeftIcon className="h-3 md:h-5 w-3 md:w-5" aria-hidden="true" />
                                    </button>
                                    <button title='Previous Page'
                                        onClick={() => setPageIndex(pageIndex - 1)}
                                        disabled={pageIndex === 0}
                                        aria-current="page"
                                        className="relative inline-flex items-center px-1.5 md:px-2 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronLeftIcon className="h-3 md:h-5 w-3 md:w-5" aria-hidden="true" />
                                    </button>
                                    {pageNumbers.length === 1 &&
                                        <button
                                            className='relative inline-flex items-center px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                            key={1}
                                            onClick={() => setPageIndex(0)}
                                        >
                                            {1}
                                        </button>
                                    }
                                    {pageNumbers.length > 1 && pageNumbers.map((pageNumber, index) => {
                                        if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                                            if (table.getPageCount() !== 0 && table.getPageCount() === 1 && table.getPageCount() === pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(pageNumber - 1)}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                );
                                            }
                                            else if (table.getPageCount() !== 1 && table.getPageCount() > pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(pageNumber)}
                                                    >
                                                        {pageNumber + 1}
                                                    </button>
                                                );
                                            }
                                            else if (table.getPageCount() === 0 && table.getPageCount() === pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(0)}
                                                    >
                                                        {1}
                                                    </button>
                                                );
                                            }
                                        } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                                            return <button className='relative inline-flex items-center px-2 md:px-4 py-1.5 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                                        }
                                        return null;
                                    })}
                                    <button title='Next Page' onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === (table.getPageCount() - 1)}
                                        className="relative inline-flex items-center px-1 md:px-2 py-1 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronRightIcon className="h-3 md:h-5 w-3 md:w-5" aria-hidden="true" />
                                    </button>
                                    <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
                                        className="relative inline-flex items-center rounded-r-md px-1 md:px-2 py-1 md:py-2 text-xs md:text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleRightIcon className="h-3 md:h-5 w-3 md:w-5" aria-hidden="true" />
                                    </button>
                                </nav> */}
                                <Pagination
                                    pageIndex={pageIndex}
                                    setPageIndex={setPageIndex}
                                    pageNumbers={pageNumbers}
                                    pageCount={table.getPageCount()}
                                />
                                <PageSizeDropdown
                                    dataLength={table.getPrePaginationRowModel().rows.length}
                                    pageSize={pageSize}
                                    handlePageChange={handlePageChange}
                                    totalRows={table.getPrePaginationRowModel().rows.length}
                                />
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
}
