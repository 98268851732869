import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { convertBytesToMB } from "./CommonUtils";
import Popup from "./Popup";

function QDBPromptFileUploader({ files, setFiles, qdbno }) {
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const acceptedFileTypes = {
    "text/csv": [".csv"],
  };

  const maxFileSize = {
    ".csv": 30 * 1024 * 1024, // 30MB
  };

  function getFileExtension(filename) {
    return "." + filename.split(".").pop().toLowerCase();
  }

  function handleFileChange(acceptedFiles) {
    const validatedFiles = acceptedFiles.filter((file) => {
      const fileExtension = getFileExtension(file.name);
      const fileSize = file.size;

      if (!acceptedFileTypes[file.type]?.includes(fileExtension)) {
        setMsg("Invalid file type. Only csv files are allowed.");
        setIsSuccess(false);
        openPopup();
        return false;
      }

      if (fileSize > maxFileSize[fileExtension]) {
        setMsg(`File size exceeds the limit for ${fileExtension} files.`);
        setIsSuccess(false);
        openPopup();
        return false;
      }

      return true;
    });

    setFiles(validatedFiles);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: Math.max(...Object.values(maxFileSize)),
    onDrop: handleFileChange,
    multiple: false,
  });

  return (
    <div className="w-full md:w-1/2">
      <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900 pb-1"
      >
        {qdbno} Question DB File 
      </label>
      <div className="flex justify-center rounded-md border border-dashed border-gray-900 items-center h-[16.4rem] md:h-[calc(100vh-18.6rem)]">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className="text-center">
            <div className="flex justify-center text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-0 focus-within:border-white focus-within:ring-whitefocus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>{files.length < 1 ? "Upload" : "Re-Upload"} a file</span>
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">CSV up to 30MB</p>
            <aside>
              {files.map((file) => (
                <p
                  className="border py-3 px-2 mt-2 rounded-md text-sm"
                  key={file.path}
                >
                  {file.path} - {convertBytesToMB(file.size)}
                </p>
              ))}
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QDBPromptFileUploader;
