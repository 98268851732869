import React, { useState } from "react";
import ReadModal from "../Common/ReadModal";
import { Tab } from "@headlessui/react";
import QuestionDB from "./QuestionDB";
import Params from "./Params";
import FeaturesList from "./FeaturesList";
import Batches from "./Batches";
import ExecutionTime from "./ExecutionTime";
import Regex from "./Regex";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function ProjectConfigurationTabs({ tabs }) {
    return (
        <Tab.Group as="div" className="-mx-5">
            <Tab.List className="flex justify-start border-b overflow-auto">
                <div className="flex gap-2 md:gap-3">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            className={({ selected }) =>
                                classNames(
                                    "w-full px-3 md:px-5 font-medium leading-5 whitespace-nowrap cursor-default lg:cursor-pointer",
                                    "ring-white focus:outline-none focus:ring-0",
                                    selected
                                        ? "text-indigo-800 border-b-2 border-indigo-800 pb-2.5 lg:pb-3"
                                        : "text-black pb-3 lg:pb-3.5"
                                )
                            }
                        >
                            <h1 className="text-sm lg:text-base tracking-wider">
                                {tab.title}
                            </h1>
                        </Tab>
                    ))}
                </div>
            </Tab.List>
            <Tab.Panels className="overflow-auto bg-white md:bg-zinc-50 h-[calc(100vh-8.5rem)] md:h-[calc(100vh-8.5rem)] px-3 py-4">
                {tabs.map((tab, idx) => (
                    <Tab.Panel
                        key={idx}
                        className={classNames(
                            "bg-white md:bg-zinc-50",
                            "ring-white focus:outline-none focus:ring-0"
                        )}
                    >
                        {tab.content}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    );
}

function ProjectConfiguration() {
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
        //setRowId('');
    }

    function openModal() {
        setIsOpen(true);
    }

    const tabs = [
        {
            id: 1,
            title: "Params",
            content: <Params />,
        },
        {
            id: 2,
            title: "Question DB",
            content: <QuestionDB />,
        },
        {
            id: 3,
            title: "Features Listing",
            content: <FeaturesList />,
        },
        {
            id: 4,
            title: "Batching",
            content: <Batches />,
        },
        {
            id: 5,
            title: "Execution Time",
            content: <ExecutionTime />,
        },
        {
            id: 6,
            title: "Unique Code for Merging",
            content: <Regex />,
        },
    ];

    return (
        <div>
            <button
                type="button"
                onClick={openModal}
                className="inline-flex justify-center border border-blue-300 bg-blue-100 px-4 py-1.5 md:py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
            >
                Configuration
            </button>
            <ReadModal
                isOpen={isOpen}
                closeModal={closeModal}
                title="Configuration"
                modalInputs={<ProjectConfigurationTabs tabs={tabs} />}
                paddingYModal="pt-4"
            />
        </div>
    );
}

export default ProjectConfiguration;
