import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { EyeIcon, FunnelIcon } from '@heroicons/react/24/outline';
import ReadModal from '../Common/ReadModal';
import { DebouncedInput, FinalizedFilter, IndeterminateCheckbox, LogStatusFilter } from '../Common/Filters';
import Popup from '../Common/Popup';
import { API_URL } from "../../imports";
import axios from 'axios';
import { handleApiError } from '../Common/APIUtils';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import NoDataFound from '../Common/NoDataFound';
import { Menu, Transition } from '@headlessui/react';
import PageSizeDropdown from '../Common/PageSizeDropdown';
import { formattedDate } from '../Common/CommonUtils';
import Pagination from '../Common/Pagination';

function LogsMgmt() {
  const [selectedProcess, setSelectedProcess] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [currentProcess, setCurrentProcess] = useState('');
  const [currentProject, setCurrentProject] = useState('');
  let [show,setShow] = useState(false);
  const [data,setData]=useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [entryColumnState, setEntryColumnState] = useState('');
  const [jsonValue,setJsonValue]=useState(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);


  const handleViewFiles = async() => {
    if (selectedProcess && selectedProject) {
      // You can perform any action here related to viewing logs for the selected project
      try {
        const response = await axios.get(`${API_URL}/entries`, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
              project: selectedProject,
              process: selectedProcess,
              batch: 'All'
          },
        });
        setRowSelection({});
        setPageSize(10);
        setPageIndex(0);
        setEntryColumnState(selectedProcess); 
        setData(response.data.data);
        setShow(true);
        setCurrentProject(selectedProject);
        setCurrentProcess(selectedProcess);
    } catch (error) {
        setShow(false);
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        setIsSuccess(false);
        openPopup();
    } 
      
    } else {
      setShow(false);
    }
  };

  let [isOpen, setIsOpen] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeModal() {
    setIsOpen(false)
  }

  const getProcessResponse = useCallback(async(id, process_code, project_code) =>{
    axios.post(
            `${API_URL}/entries/getresponse`,
            { id: id, process_code: process_code, proj_code: project_code},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            }
        )
        .then((response) => {
            // Decode the base64 string
            const decodedJsonString = atob(response.data.data);
            setJsonValue(JSON.parse(decodedJsonString));
            // setJsonValue(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
        })
},[]);

const openReadModal = useCallback((e)=> {
    setIsOpen(true);
    getProcessResponse(e.id, currentProcess, currentProject)
    setModalTitle(`Showing ${e.filename}`);
},[currentProcess,currentProject, getProcessResponse])


  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  
    function closeConfirmPopup() {
      setIsOpenConfirmPopup(false);
    }
  
    function openConfirmPopup() {
      setConfirmationMsg("Are you sure that you want to revert finalize for selected entries?")
      setIsOpenConfirmPopup(true);
    }

    const ReloadData = async () => {
      if (currentProcess && currentProject) {
        // You can perform any action here related to viewing logs for the selected project
        try {
          const response = await axios.get(`${API_URL}/entries`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            params: {
                project: currentProject,
                process: currentProcess,
                batch: 'All'
            },
          });
          setRowSelection({});
          setEntryColumnState(currentProcess); 
          setData(response.data.data);
          setShow(true);
      } catch (error) {
          setShow(false);
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          setIsSuccess(false);
          openPopup();
      } 
        
      } else {
        setShow(false);
      }
  };
  
      const handleRevertState = async (revertData) => {
          setIsOpenConfirmPopup(false);
          try {
              const response = await axios.patch(`${API_URL}/entries/revertfinalize`,{ process_code: currentProcess, filesid_list: revertData }, {
                  headers: { 
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`
                  },
              });
              setMsg(response.data.message);
              setIsSuccess(true);
              openPopup();
              ReloadData();
          } catch (error) {
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              setIsSuccess(false);
              openPopup();
          }
      };

      const handleSelectedRevert = () => {
        // Filter the Files based on the rowSelection
        const selectedRevertFiles = Object.keys(rowSelection).filter((index) => rowSelection[index]).map((index) => data[index]);
        if (selectedRevertFiles.length > 0) {
          // Check if any file has closing !== true
          const hasUnclosedFile = selectedRevertFiles.some((file) => file.closing !== true);
          if (hasUnclosedFile) {
            setIsOpenConfirmPopup(false);
            // Display a message or handle the case where all files have closing !== true
            setMsg("Some of the selected files are not finalized already. Please Do select only finalized files.");  
            setIsSuccess(false);
            openPopup();
          } else {
            // Perform your revert operation
            const fileIds = selectedRevertFiles.map((file) => file.id);
            handleRevertState(fileIds);
          }
        }
      };

const TranscriptionEntrycolumns = React.useMemo(
  () => [
    {
      id: 'select',
      size:10,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <center className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </center>
      ),
    },
    {
      accessorKey: "index",
      enableColumnFilter: false,
      enableSorting: false,
      size: 50,
      header: "#",
      cell: ({ row }) => <center>{row.index + 1}</center>,
    },
    {
      accessorKey: 'filename',
      enableColumnFilter:false,
      size: 1000,
      header: () => <span>File Name</span>
    },
    {
      accessorKey: 'status',
      enableColumnFilter:true,
      size: 50,
      header: () => <span>Status</span>,
      cell: ({ row }) => <center>{row.original.status}</center>, 
    },
    {
      accessorKey: 'confidence_score',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Confidence Score</span>,
      cell: ({ row }) => (
          <center>
              {row.original.confidence_score === null
                  ? null
                  : row.original.confidence_score.toFixed(2)}
          </center>
      ), 
    },
    {
      accessorKey: 'merge',
      enableColumnFilter:false,
      size: 50,
      header: () => <span>Merged</span>,
      cell: ({ row }) => (
          <center>{row.original.merge !== '0' ? row.original.merge : null}</center>
      ),
    },
    {
      accessorKey: 'closing',
      enableColumnFilter:true,
      size: 50,
      header: () => <span>Finalized</span>,
      cell: ({ row }) => (
          <center>{row.original.closing === true ? 1 : null}</center>
      ),
    },
    {
      accessorKey: 'creation_date',
      enableColumnFilter:false,
      size: 50,
      header:'Execution Date',
      cell: ({ row }) => (
        <div>
            {formattedDate(row.original.creation_date)}
        </div>
      ),
    },
    {
      accessorKey:'id',
      header: 'Actions',
      size: 50,
      enableColumnFilter:false,
      enableSorting:false,
      cell:({ row }) => (
        <div className='flex gap-2 items-center justify-center'>
        <button className="p-1 rounded-md border" onClick={()=>openReadModal(row.original)}>
          <EyeIcon className='h-4 text-green-800'/>
        </button>
        </div>
       )
      },
  ],
  [openReadModal]
);

const RawRefinedEntrycolumns = React.useMemo(
  () => [
    {
      id: 'select',
      size:10,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <center className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </center>
      ),
    },
    {
      accessorKey: "index",
      enableColumnFilter: false,
      enableSorting: false,
      size: 50,
      header: "#",
      cell: ({ row }) => <center>{row.index + 1}</center>,
    },
    {
      accessorKey: 'filename',
      enableColumnFilter:false,
      size: 1000,
      header: () => <span>File Name</span>
    },
    {
      accessorKey: 'status',
      enableColumnFilter:true,
      size: 50,
      header: () => <span>Status</span>,
      cell: ({ row }) => <center>{row.original.status}</center>, 
    },
    {
      accessorKey: 'closing',
      enableColumnFilter:true,
      size: 50,
      header: () => <span>Finalized</span>,
      cell: ({ row }) => (
          <center>{row.original.closing === true ? 1 : null}</center>
      ),
    },
    {
      accessorKey: 'creation_date',
      enableColumnFilter:false,
      size: 50,
      header:'Execution Date',
      cell: ({ row }) => (
        <div>
            {formattedDate(row.original.creation_date)}
        </div>
      ),
    },
    {
      accessorKey:'id',
      header: 'Actions',
      size: 50,
      enableColumnFilter:false,
      enableSorting:false,
      cell:({ row }) => (
        <div className='flex gap-2 items-center justify-center'>
        <button className="p-1 rounded-md border" onClick={()=>openReadModal(row.original)}>
          <EyeIcon className='h-4 text-green-800'/>
        </button>
        </div>
       )
      },
  ],
  [openReadModal]
);

  const GetIdeasEntrycolumns = React.useMemo(
    () => [
      {
        id: 'select',
        size:10,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <center className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </center>
        ),
      },
      {
        accessorKey: "index",
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: "#",
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      {
        accessorKey: 'filename',
        enableColumnFilter:false,
        size: 1000,
        header: () => <span>File Name</span>
      },
      {
        accessorKey: 'status',
        enableColumnFilter:true,
        size: 50,
        header: () => <span>Status</span>,
        cell: ({ row }) => <center>{row.original.status}</center>,  
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter:false,
        size: 50,
        header:'Execution Date',
        cell: ({ row }) => (
          <div>
              {formattedDate(row.original.creation_date)}
          </div>
        ),
      },
      {
        accessorKey:'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter:false,
        enableSorting:false,
        cell:({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
          <button className="p-1 rounded-md border" onClick={()=>openReadModal(row.original)}>
            <EyeIcon className='h-4 text-green-800'/>
          </button>
          </div>
         )
        },
    ],
    [openReadModal]
  );

  const [processes,setProcesses]=useState([]);
  const [projects,setProjects]=useState([]);

useEffect(() => {
  const fetchProcess = async () => {
    axios.get(`${API_URL}/processes`,{
      headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    }).then(response => {
        setProcesses(response.data.data);
    })
    .catch(error => {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      openPopup();
    });
  };
  const fetchProjects = async () => {
      axios
          .get(`${API_URL}/projects`, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
          })
          .then((response) => {
              setProjects(response.data.data);
          })
          .catch((error) => {
              const errorMessage = handleApiError(error);
              setMsg(errorMessage);
              openPopup();
          });
  };
  fetchProcess();
  fetchProjects();
}, []); 
  
  const [globalFilter, setGlobalFilter] = React.useState("");


  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data,
    columns: entryColumnState === 'TRANSCRIPT'
    ? TranscriptionEntrycolumns
    : entryColumnState === 'GETIDEAS'
    ? GetIdeasEntrycolumns
    : RawRefinedEntrycolumns,
    state: {
      rowSelection,
      sorting,
      pagination,
      globalFilter
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  const renderTableCellIndex = (row) => {
    return table.getFilteredRowModel().rows.indexOf(row)+1 || table.getSortedRowModel().rows.indexOf(row)+1 || row.index+1;
  };

  const tableContainerRef = React.useRef(null);
  // Modify the pagination section
  const pageCount = Math.ceil(data.length / pageSize);
  const pageNumbers = [];

  for (let i = 0; i < pageCount; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (e) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    setPageIndex(0)
  };

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: pageSize,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div className="border border-gray-200 rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={handleSelectedRevert}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <ReadModal isOpen={isOpen} closeModal={closeModal} title={modalTitle}
        modalInputs={
          <div className="overflow-auto h-[calc(100vh-8rem)] md:h-[calc(100vh-8rem)]">
          {jsonValue && (
              <pre className="pretxtwrap text-sm">
                  {JSON.stringify(
                      jsonValue,
                      null,
                      2
                  )}
              </pre>
          )}
          {!jsonValue && "No Response Found."}
          </div>
        } 
      />
      <div className='flex flex-col md:flex-row gap-3 p-3 border-b'>
      <select
        id="project"
        name="project"
        className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
        value={selectedProject}
        onChange={(e) => setSelectedProject(e.target.value)}
      >
        <option value="">Select Project</option>
        {projects.map((item, index) => (
          <option key={index} value={item.project_code}>
            {item.project_name} - {item.project_code}
          </option>
        ))}
      </select>
                <select
                  id="process"
                  name="process"
                  className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={(e) => setSelectedProcess(e.target.value)}
                >
                  <option value="">Select Process</option>
                  {processes.slice(1).map((process,index) => (
                    <option key={index} value={process.process_code}>
                      {process.process_name}
                    </option>
                  ))}
                </select>
      <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={handleViewFiles}>
        View Logs
      </button>
      {show && Object.keys(rowSelection).length > 0 && currentProcess!=='PRETRANS' && currentProcess!=='GETIDEAS' &&
      <>
        <button className="rounded-md md:hidden cursor-default lg:cursor-pointer p-2 inline-flex text-sm bg-gray-100 border-gray-300 border" onClick={openConfirmPopup}>
          Revert Finalize
        </button>
      </>
      }
      </div>
      {!show && <div className='flex h-[calc(100vh-22.2rem)] md:h-[calc(100vh-17.2rem)] lg:h-[calc(100vh-16.2rem)] items-center justify-center'><p className='text-sm md:text-2xl p-4 lg:p-0'>Please select the project and process to view logs</p></div>}
      {show && data.length === 0 && <NoDataFound />}
      {show && data.length > 0 && (
      <>
      <div className="flex justify-between m-3 gap-2">
        <DebouncedInput value={globalFilter ?? ""}  onChange={(value) => setGlobalFilter(String(value))}  placeholder="Search..."/>
        <Menu as="div" className="relative">
            <div>
                <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                    <span className="absolute -inset-1.5" />
                    <FunnelIcon className="h-5 w-auto" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div>
                        {data.length > 0 && table.getHeaderGroups().map((headerGroup) => (
                          <div key={headerGroup.id} className="space-y-1">
                              {headerGroup.headers.map(
                                  (header) => (
                                      <div key={header.id}>
                                          {header.column.getCanFilter() &&
                                            header.column.id === "status" ? (
                                            <div>
                                              <label
                                                htmlFor="push-everything"
                                                className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                              >
                                                Status
                                              </label>
                                              <LogStatusFilter
                                                column={header.column}
                                                table={table}
                                              />
                                            </div>
                                          ) : null}
                                          {header.column.getCanFilter() &&
                                            header.column.id === "closing" ? (
                                            <div>
                                              <label
                                                htmlFor="push-everything"
                                                className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                              >
                                                Finalized
                                              </label>
                                              <FinalizedFilter
                                                column={header.column}
                                                table={table}
                                              />
                                            </div>
                                          ) : null}
                                      </div>
                                  )
                              )}
                          </div>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
          </Menu>
      </div>
      <div ref={tableContainerRef} className='overflow-auto resp-adminlogs border-y'>
        <table className='flex-inline w-full border-collapse'>
          <thead className='bg-gray-100'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className='border p-2 whitespace-nowrap'
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <>
                        <div
                          className={`${
                            header.column.getCanSort() && header.column.id!=='status'
                              ? 'cursor-pointer select-none justify-start'
                              : 'justify-center'
                          } flex`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] || null}
                        </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className='border p-2 whitespace-nowrap' key={cell.id}>
                        {cell.column.id === 'index' && <center>{renderTableCellIndex(row)}</center>}
                        {cell.column.id!=='index' && flexRender(cell.column.columnDef.cell,cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3'>
        {/* <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
            <button title='First Page' onClick={() => setPageIndex(0)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button title='Previous Page'
            onClick={() => setPageIndex(pageIndex-1)}
            disabled={pageIndex===0}
              aria-current="page"
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.length===1 &&
              <button
                className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                key={1}
                onClick={()=>setPageIndex(0)}
              >
              {1}
              </button>
            }
            {pageNumbers.length>1 && pageNumbers.map((pageNumber, index) => {
              if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                if(table.getPageCount()!==0 && table.getPageCount()===1 && table.getPageCount()===pageNumber) {
                  return (
                    <button
                      className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      key={index}
                      onClick={()=>setPageIndex(pageNumber-1)}
                    >
                    {pageNumber}
                    </button>
                  );
                }
                else if(table.getPageCount()!==1 && table.getPageCount()>pageNumber) {
                  return (
                    <button
                      className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      key={index}
                      onClick={()=>setPageIndex(pageNumber)}
                    >
                    {pageNumber + 1}
                    </button>
                  );
                }
                else if(table.getPageCount()===0  && table.getPageCount()===pageNumber){
                  return (
                    <button
                      className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      key={index}
                      onClick={()=>setPageIndex(0)}
                    >
                    {1}
                    </button>
                  );
                }
              } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
              }
              return null;
            })}
            <button title='Next Page' onClick={() => setPageIndex(pageIndex+1)}  disabled={pageIndex===(table.getPageCount() - 1)}
              className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
           </button>
            <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav> */}
          <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  pageNumbers={pageNumbers}
                  pageCount={table.getPageCount()}
                />
          <div>
          <div className="sm:col-span-3 flex gap-3">
              <div>
                <PageSizeDropdown
                  dataLength={table.getPrePaginationRowModel().rows.length}
                  pageSize={pageSize}
                  handlePageChange={handlePageChange}
                  totalRows={table.getPrePaginationRowModel().rows.length}
                />
              </div>
              {show && Object.keys(rowSelection).length > 0 && currentProcess!=='PRETRANS' &&  currentProcess!=='GETIDEAS' &&
                <>
                  <button className="rounded-md hidden cursor-default lg:cursor-pointer p-2 md:inline-flex text-sm bg-gray-100 border-gray-300 border"  onClick={openConfirmPopup}>
                    Revert Finalize
                  </button>
                </>
              }
            </div>
          </div>
        </div>
        </>
        )}
    </div>
  );
}

export default LogsMgmt