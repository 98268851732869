import React, { Fragment, useCallback, useState, useEffect, useRef } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ArrowPathIcon,
    TrashIcon,
    ArchiveBoxArrowDownIcon,
    ArrowsRightLeftIcon,
    EllipsisHorizontalIcon
} from "@heroicons/react/24/outline";
import Popup from "../Common/Popup";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import axios from "axios";
import { API_URL } from "../../imports";
import { DebouncedInput } from "../Common/Filters";
import FetchingDataLoader from "../Common/FetchingDataLoader";
import { handleApiError } from "../Common/APIUtils";
import ButtonsBar from "./ButtonsBar";
import { initialpageSize } from "../Common/Contants";
import PageSizeDropdown from "../Common/PageSizeDropdown";
import { Menu, Transition } from '@headlessui/react';
import ProcessingLoaderPopup from "../Common/ProcessingLoaderPopup";
import { Portal } from "react-portal";
import Pagination from "../Common/Pagination";

export default function ClosedProjectTriggers() {
    const [Loader, setLoader] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    let [onConfirmAction, setOnConfirmAction] = useState(null);
    let [isLoaderOpen, setIsLoaderOpen] = useState(false);
    const [data, setData] = useState([]);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0 });
    const [activeDropdown, setActiveDropdown] = useState(null);
    const buttonRefs = useRef({});
    const dropdownRef = useRef(null);

    const calculatePosition = useCallback((id) => {
        const buttonRef = buttonRefs.current[id];
        if (buttonRef) {
            const rect = buttonRef.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
            });
            setActiveDropdown((prev) => (prev === id ? null : id));
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setActiveDropdown(null);
          }
        };
        const handleKeyDown = (event) => {
          const keysToCloseDropdown = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp'];
          if (keysToCloseDropdown.includes(event.key)) {
            setActiveDropdown(null);
          }
        };
        const handleScroll = () => {
          setActiveDropdown(null);
        };
        if (activeDropdown !== null) {
          document.addEventListener("mousedown", handleClickOutside);
          document.addEventListener("keydown", handleKeyDown);
          window.addEventListener("scroll", handleScroll, true);
        }
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener("keydown", handleKeyDown);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, [activeDropdown]);

    const ReloadData = useCallback(async () => {
        setLoader(true);
        axios
            .get(`${API_URL}/db_archival/closed_project_list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            })
            .finally(() => {
                setLoader(false);
            });
    },[]);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    function closeConfirmPopup() {
        setIsOpenConfirmPopup(false);
    }

    const handleSpecificProjectArchival = useCallback(async (rowData) => {
        setConfirmationMsg(`Are you sure that you want to archive project ${rowData.project_name}?`);
        setIsOpenConfirmPopup(true);
        setOnConfirmAction(() => async () => {
            setIsOpenConfirmPopup(false);
            setIsLoaderOpen(true);
            try {
                await axios.get(
                    `${API_URL}/db_archival/archive_project/${rowData.project_code}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setMsg(`Project ${rowData.project_name} archived successfully.`);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setIsLoaderOpen(false);
            }
        });
    },[ReloadData]);

    const handleSpecificProjectRestoration = useCallback(async (rowData) => {
        setConfirmationMsg(`Are you sure that you want to restore project ${rowData.project_name}?`);
        setIsOpenConfirmPopup(true);
        setOnConfirmAction(() => async () => {
            setIsOpenConfirmPopup(false);
            setIsLoaderOpen(true);
            try {
                await axios.get(
                    `${API_URL}/db_archival/restore_project/${rowData.project_code}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setMsg(`Project ${rowData.project_name} restored successfully.`);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setIsLoaderOpen(false);
            }
        });
    },[ReloadData]);

    const handleSpecificProjectDeletion = useCallback(async(rowData) => {
        setConfirmationMsg(`Are you sure to permanently delete ${rowData.project_name}?`);
        setIsOpenConfirmPopup(true);
        setOnConfirmAction(() => async () => {
            setIsOpenConfirmPopup(false);
            setIsLoaderOpen(true);
            try {
                await axios.get(
                    `${API_URL}/db_archival/restore_project/${rowData.project_code}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setMsg(`Project ${rowData.project_name} restored successfully.`);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setIsLoaderOpen(false);
            }
        });
    },[ReloadData]);

    const handleTriggeredArchival = async () => {
        setConfirmationMsg(`Are you sure to archieve the closed pojects?`);
        setIsOpenConfirmPopup(true);
        setOnConfirmAction(() => async () => {
            setIsOpenConfirmPopup(false);
            setIsLoaderOpen(true);
            try {
                const response = await axios.get(
                    `${API_URL}/db_archival/triggered_archival`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setMsg(response.data.message);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setIsLoaderOpen(false);
            }
        });
    };

    const handleTriggeredRestoration = async () => {
        setConfirmationMsg(`Are you sure to restore the recently archived pojects?`);
        setIsOpenConfirmPopup(true);
        setOnConfirmAction(() => async () => {
            setIsOpenConfirmPopup(false);
            setIsLoaderOpen(true);
            try {
                const response = await axios.get(
                    `${API_URL}/db_archival/triggered_restoration`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setMsg(response.data.message);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setIsLoaderOpen(false);
            }
        });
    };

    const handleTriggeredDeletion = async () => {
        setConfirmationMsg(`Are you sure to permanently delete the archived pojects?`);
        setIsOpenConfirmPopup(true);
        setOnConfirmAction(() => async () => {
            setIsOpenConfirmPopup(false);
            setIsLoaderOpen(true);
            try {
                const response = await axios.get(
                    `${API_URL}/db_archival/triggered_deletion`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setMsg(response.data.message);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            } finally {
                setIsLoaderOpen(false);
            }
        });
    };

    const formattedDate = useCallback((dateStr) => {
        const date = new Date(dateStr);
        const formattedDateString = date
            .toLocaleString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            })
            .replace(/\//g, "/")
            .replace(",", " ");
        const [month, day, yearAndTime] = formattedDateString.split("/");
        return `${day}-${month}-${yearAndTime}`;
    }, []);

    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "project_name",
                enableColumnFilter: false,
                size: 250,
                header: () => <span>Project</span>,
            },
            {
                accessorKey: "closed_date",
                enableColumnFilter: false,
                size: 100,
                header: "Closed Date",
                cell: ({ row }) => (
                    <div>
                        {row.original.closed_date &&
                            formattedDate(row.original.closed_date)}
                    </div>
                ),
            },
            {
                accessorKey: "archival_date",
                enableColumnFilter: false,
                size: 100,
                header: "Archival Date",
                cell: ({ row }) => (
                    <div>
                        {row.original.archival_date &&
                            formattedDate(row.original.archival_date)}
                    </div>
                ),
            },
            {
                accessorKey: "restoration_date",
                enableColumnFilter: false,
                size: 100,
                header: "Restoration Date",
                cell: ({ row }) => (
                    <div>
                        {row.original.restoration_date &&
                            formattedDate(row.original.restoration_date)}
                    </div>
                ),
            },
            {
                accessorKey: "deletion_date",
                enableColumnFilter: false,
                size: 100,
                header: "Deletion Date",
                cell: ({ row }) => (
                    <div>
                        {row.original.deletion_date &&
                            formattedDate(row.original.deletion_date)}
                    </div>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <Menu as="div">
                            <div ref={(el) => (buttonRefs.current[row.original.id] = el)}>
                                <Menu.Button className="border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        calculatePosition(row.original.id)}
                                    }
                                >
                                    <EllipsisHorizontalIcon className="h-4 text-black" />
                                </Menu.Button>
                            </div>
                            <Portal>
                                <Transition
                                    as={Fragment}
                                    show={activeDropdown === row.original.id}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items 
                                        ref={dropdownRef}
                                        className="absolute z-50 right-8 md:right-12 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        style={{
                                            top: `${dropdownPosition.top}px`,
                                        }}
                                    >
                                        {row.original.deletion_date ? (
                                            <>
                                                <button
                                                    className="flex items-center px-4 py-2 w-full text-sm cursor-not-allowed"
                                                    disabled
                                                >
                                                    <ArchiveBoxArrowDownIcon className="h-4 text-gray-400 mr-2" />
                                                    <span className="text-gray-400">Archive</span>
                                                </button>
                                                <button
                                                    className="flex items-center px-4 py-2 w-full text-sm cursor-not-allowed"
                                                    disabled
                                                >
                                                    <ArrowsRightLeftIcon className="h-4 text-gray-400 mr-2" />
                                                    <span className="text-gray-400">Restore</span>
                                                </button>
                                                <button
                                                    className="flex items-center px-4 py-2 w-full text-sm cursor-not-allowed"
                                                    disabled
                                                >
                                                    <TrashIcon className="h-4 text-gray-400 mr-2" />
                                                    <span className="text-gray-400">Delete</span>
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    className="flex items-center hover:bg-gray-200 hover:text-blue-800 px-4 py-2 w-full text-sm"
                                                    onClick={() => {
                                                        handleSpecificProjectArchival(row.original)
                                                        setActiveDropdown(null)
                                                    }}
                                                >
                                                    <ArchiveBoxArrowDownIcon className="h-4 text-blue-800 mr-2" />
                                                    <span className="">Archive</span>
                                                </button>
                                                <button
                                                    className="flex items-center hover:bg-gray-200 hover:text-green-800 px-4 py-2 w-full text-sm"
                                                    onClick={() => {
                                                        handleSpecificProjectRestoration(row.original)
                                                        setActiveDropdown(null)
                                                    }}
                                                >
                                                    <ArrowsRightLeftIcon className="h-4 text-green-800 mr-2" />
                                                    <span>Restore</span>
                                                </button>
                                                <button
                                                    className="flex items-center hover:bg-gray-200 hover:text-red-800 px-4 py-2 w-full text-sm"
                                                    onClick={() => {
                                                        handleSpecificProjectDeletion(row.original)
                                                        setActiveDropdown(null)
                                                    }}
                                                >
                                                    <TrashIcon className="h-4 text-red-800 mr-2" />
                                                    <span>Delete</span>
                                                </button>
                                            </>
                                        )}
                                    </Menu.Items>
                                </Transition>
                            </Portal>
                        </Menu>
                    </div>

                ),
            },
        ],
        [formattedDate,handleSpecificProjectArchival,handleSpecificProjectDeletion,handleSpecificProjectRestoration,activeDropdown, calculatePosition, dropdownPosition.top]
    );

    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
            pagination,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };
    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/db_archival/closed_project_list`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setData(response.data.data);
                setLoader(false);
            } catch (error) {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
                setLoader(false);
            }
        };
        fetchInitialData();
    }, []);
    
    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ProcessingLoaderPopup isOpen={isLoaderOpen} message="Processing your request..." />
            <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={closeConfirmPopup}
                onStateChange={onConfirmAction}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex flex-col md:flex-row md:justify-between justify-between m-3 gap-2">
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                </div>
                <div className="flex items-center gap-2 justify-between md:justify-center">
                    <ButtonsBar
                        onArchiveClick={handleTriggeredArchival}
                        onRestoreClick={handleTriggeredRestoration}
                        onDeleteClick={handleTriggeredDeletion}
                    />
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-block text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                </div>
            </div>
            {Loader && <FetchingDataLoader />}
            {!Loader && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h-archive border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    data.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        ) :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingTop}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr key={row.id}>
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => {
                                                                    return (
                                                                        <td
                                                                            className="border p-2 whitespace-nowrap"
                                                                            key={cell.id}
                                                                        >
                                                                            {flexRender(
                                                                                cell.column
                                                                                    .columnDef
                                                                                    .cell,
                                                                                cell.getContext()
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingBottom}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        rows.length > 0 && (
                            <div className="flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3">
                                <Pagination
                                    pageIndex={pageIndex}
                                    setPageIndex={setPageIndex}
                                    pageNumbers={pageNumbers}
                                    pageCount={table.getPageCount()}
                                />
                                <PageSizeDropdown
                                    dataLength={table.getPrePaginationRowModel().rows.length}
                                    pageSize={pageSize}
                                    handlePageChange={handlePageChange}
                                    totalRows={table.getPrePaginationRowModel().rows.length}
                                />
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
}
