import React from 'react'

function Footer() {
  return (
    <footer className='bottom-0 absolute w-full bg-white'>
      <p className="p-2 tracking-widest text-start text-xs">
        {new Date().getFullYear()} <span className='font-sans'>&copy;</span> Copyrights - Inxite Out
      </p>
    </footer>
  )
}

export default Footer