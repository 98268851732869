import { DocumentCheckIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function IndexBasedRangeSelection({startIndex,setStartIndex,endIndex,setEndIndex,handleSetSelection,max}) {
  return (
    <>
        <input
            type="number"
            placeholder="Start Index"
            step={1}
            min={1}
            max={max}
            className="font-GoogleSans w-[9.14rem] md:w-32 rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={startIndex === undefined ? '' : startIndex}
            onChange={(e) => setStartIndex(parseInt(e.target.value, 10))}
        />
        <input
            type="number"
            placeholder="End Index"
            step={1}
            min={1}
            max={max}
            className="font-GoogleSans w-[9.14rem] md:w-32 rounded-md border text-gray-900 ring-0 ring-inset ring-white border-gray-300 placeholder:text-gray-400 focus:border-gray-300 focus:ring-inset focus:ring-0 focus:ring-white text-sm"
            value={endIndex === undefined ? '' : endIndex}
            onChange={(e) => setEndIndex(parseInt(e.target.value, 10))}
        />
        <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={handleSetSelection}><DocumentCheckIcon className='h-5 w-5 flex lg:hidden'/><span className='hidden lg:flex'>Select Rows</span></button>
    </>
  )
}
