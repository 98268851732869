'use client'

import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function DownloadRawRefinedResponseModal({questionDBs,handleDownloadCSV}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedQDBs, setSelectedQDBs] = useState([])

  const toggleSelection = (db) => {
    setSelectedQDBs(prev =>
      prev.includes(db) ? prev.filter(item => item !== db) : [...prev, db]
    )
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex whitespace-nowrap justify-center rounded-lg border border-transparent bg-blue-100 px-2 md:px-4 py-2 text-xs md:text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
        onClick={() => setIsOpen(true)}
    >
        Download Latest Responses
    </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-start mt-36 m-3 md:mx-0 md:mb-0 justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full font-GoogleSans max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between text-base tracking-wide leading-6 bg-gray-100 py-4 px-4"
                  >
                    Select QuestionDBs
                    <button
                    onClick={() => setIsOpen(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  </Dialog.Title>
                  
                  <div className="pt-2.5 pb-2 px-4 max-h-[16ch] 2xl:max-h-[18ch] overflow-auto">
                    <div className="flex flex-wrap gap-2">
                      {questionDBs.map((db) => (
                        <button
                          key={db}
                          onClick={() => toggleSelection(db)}
                          className={`px-3 py-2 rounded-md text-sm tracking-wide transition-colors duration-200 ease-in-out ${
                            selectedQDBs.includes(db)
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                          }`}
                        >
                          {db}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="pt-3 bg-gray-100 flex justify-end space-x-2 px-3 pb-2.5">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm tracking-wide text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={()=>handleDownloadCSV(selectedQDBs)}
                    >
                      Proceed
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}