import React, { useCallback, useEffect, useState } from "react";
// import { useTable } from "react-table";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import axios from "axios";
import { API_URL } from "../../imports";
import Popup from "./Popup";
import { handleApiError } from "./APIUtils";
import { DebouncedInput, IndeterminateCheckbox } from "./Filters";
import SearchableDropdown from "./SearchableDropdown";
import CopyTranscriptsFileUploader from "./CopyTranscriptsFileUploader";
import NoDataFound from "./NoDataFound";

export default function CopyTranscripts() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }
    const [projectList, SetProjectList] = useState([]);
    const [sourceProject, SetSourceProject] = useState([]);
    const [destinationProject, SetDestinationProject] = useState([]);

    // const handleSourceSelection = (value) => {
    //     SetSourceProject(value);
    //     ReloadData(value);
    // };

    // const handleDestinationSelection = (value) => {
    //     SetDestinationProject(value);
    // };
    // const [rowSelection, setRowSelection] = React.useState({});
    const [Loader, setLoader] = useState(true);
    let [isSuccess, setIsSuccess] = useState(false);

    const ReloadData = useCallback(async (project_code) => {
        setRowSelection({});
        setLoader(true);
        axios
            .get(`${API_URL}/entries/transcriptfiles/${project_code}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
            })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            })
            .finally(() => {
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            });
    }, []);
    const [sorting, setSorting] = React.useState([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [rowSelection, setRowSelection] = React.useState({});
    const columns = React.useMemo(
        () => [
            {
                id: "select",
                size: 10,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <center className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </center>
                ),
            },
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 50,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "filename",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>File Name</span>,
            },
        ],
        []
    );
    const tableContainerRef = React.useRef(null);
    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();

        return (
            <input
                type="text"
                value={columnFilterValue || ""}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }
    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
            globalFilter,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: data.length,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;
    // const [selectedFilesToProcess, SetSelectedFilesToProcess] = useState([]);

    const TranscriptCopy = () => {
        const selectedFiles = Object.keys(rowSelection)
            .filter((index) => rowSelection[index])
            .map((index) => data[index]);

        if (selectedFiles.length > 0) {
            const fileInfo = selectedFiles.map((file) => {
                const container = {};
                container["id"] = file.id;
                container["filename"] = file.filename;
                return container;
            });

            const payload = {
                file_id: fileInfo.map((file) => file.id),
                destination_project_code: destinationProject.project_code,
            };

            setRowSelection({});
            setLoader(true);

            axios
                .post(`${API_URL}/entries/copyTranscripts`, payload, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    setMsg(response.data.message);
                    setIsSuccess(true);
                    openPopup();
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoader(false);
                    }, 100);
                });
        }
    };

    useEffect(() => {
        const fetchActiveProjects = async () => {
            axios
                .get(`${API_URL}/entries/activeprojects`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                    params: {
                      client: sessionStorage.getItem('client')
                    }
                })
                .then((response) => {
                    SetProjectList(response.data.data);
                    SetSourceProject(response.data.data[0]);
                    SetDestinationProject(response.data.data[1]);
                    ReloadData(response.data.data[0].project_code);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    openPopup();
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchActiveProjects();
    }, [ReloadData]);

    useEffect(() => {
        if (sourceProject !== undefined && sourceProject.length !== 0) {
            ReloadData(sourceProject.project_code);
            SetDestinationProject(
                projectList.filter(
                    (project) =>
                        project.project_code !== sourceProject.project_code
                )[0]
            );
        }
    }, [sourceProject, ReloadData, projectList]);

    return (
        <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <div className="flex flex-col xl:flex-row">
                <div className="w-full xl:w-1/2 border-r">
                    <div className="flex flex-col md:flex-row p-3 gap-3">
                        <div className="flex flex-col space-y-1">
                            <label htmlFor="sourceProject" className="text-sm">
                                Source Project
                            </label>
                            <SearchableDropdown
                                items={projectList}
                                selected={sourceProject}
                                setSelected={SetSourceProject}
                                filterBy="project_name"
                            />
                            {/* <select
                            id="sourceProject"
                            name="sourceProject"
                            className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs text-xs md:text-sm sm:leading-6"
                            value={sourceProject}
                            onChange={(e) =>
                                handleSourceSelection(e.target.value)
                            }
                        >
                            {projectList.map((item, index) => (
                                <option key={index} value={item.project_code}>
                                    {item.project_name} - {item.project_code}
                                </option>
                            ))}
                        </select> */}
                        </div>
                        <div className="flex flex-col space-y-1">
                            <label
                                htmlFor="destinationProject"
                                className="text-sm"
                            >
                                Destination Project
                            </label>
                            <SearchableDropdown
                                items={projectList.filter(
                                    (project) =>
                                        project.project_code !==
                                        sourceProject.project_code
                                )}
                                selected={destinationProject}
                                setSelected={SetDestinationProject}
                                filterBy="project_name"
                            />
                            {/* <select
                            id="destinationProject"
                            name="destinationProject"
                            className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs text-xs md:text-sm sm:leading-6"
                            value={destinationProject}
                            onChange={(e) =>
                                handleDestinationSelection(e.target.value)
                            }
                        >
                            {filteredProjectList.map((item, index) => (
                                <option key={index} value={item.project_code}>
                                    {item.project_name} - {item.project_code}
                                </option>
                            ))}
                        </select> */}
                        </div>
                    </div>

                    <div className="overflow-auto">
                        {/* {selectedFilesToProcess.length === 0 && (
                        <p className="px-3 py-4">
                            Please select the files to copy transcripts from
                            source project to destination project.
                        </p>
                    )} */}
                        {/* {selectedFilesToProcess.length > 0 && ( */}
                        {data.length === 0 && (
                            <p className="px-3 py-4">
                                No Finalized Transcript files available in the
                                project {sourceProject.project_name}
                            </p>
                        )}

                        {data.length !== 0 && (
                            <div className="border overflow-auto mx-3 rounded-md">
                                <div className="flex justify-between m-3 gap-2">
                                    <DebouncedInput
                                        value={globalFilter ?? ""}
                                        onChange={(value) =>
                                            setGlobalFilter(String(value))
                                        }
                                        placeholder="Search..."
                                    />
                                </div>
                                {
                                    rows.length > 0 ? (
                                        <>
                                            <div
                                                ref={tableContainerRef}
                                                className="overflow-auto h-[calc(100vh-27.5rem)]"
                                            >
                                                <table className="flex-inline w-full border-collapse text-sm">
                                                    <thead className="border-b">
                                                        {table
                                                            .getHeaderGroups()
                                                            .map((headerGroup) => (
                                                                <tr key={headerGroup.id}>
                                                                    {headerGroup.headers.map(
                                                                        (header) => {
                                                                            return (
                                                                                <th
                                                                                    className="border bg-gray-100 p-2 whitespace-nowrap"
                                                                                    key={
                                                                                        header.id
                                                                                    }
                                                                                    colSpan={
                                                                                        header.colSpan
                                                                                    }
                                                                                    style={{
                                                                                        width: header.getSize(),
                                                                                    }}
                                                                                >
                                                                                    {header.isPlaceholder ? null : (
                                                                                        <>
                                                                                            <div
                                                                                                className={`${header.column.getCanSort()
                                                                                                    ? "cursor-pointer select-none justify-start"
                                                                                                    : "justify-center"
                                                                                                    } flex`}
                                                                                                onClick={header.column.getToggleSortingHandler()}
                                                                                            >
                                                                                                {flexRender(
                                                                                                    header
                                                                                                        .column
                                                                                                        .columnDef
                                                                                                        .header,
                                                                                                    header.getContext()
                                                                                                )}
                                                                                                {{
                                                                                                    asc: " 🔼",
                                                                                                    desc: " 🔽",
                                                                                                }[
                                                                                                    header.column.getIsSorted()
                                                                                                ] ||
                                                                                                    null}
                                                                                            </div>
                                                                                            {header.column.getCanFilter() ? (
                                                                                                <div className="mt-2">
                                                                                                    <Filter
                                                                                                        column={
                                                                                                            header.column
                                                                                                        }
                                                                                                        table={
                                                                                                            table
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </>
                                                                                    )}
                                                                                </th>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tr>
                                                            ))}
                                                    </thead>
                                                    <tbody>
                                                        {paddingTop > 0 && (
                                                            <tr>
                                                                <td
                                                                    className="border p-3"
                                                                    style={{
                                                                        height: `${paddingTop}px`,
                                                                    }}
                                                                />
                                                            </tr>
                                                        )}
                                                        {virtualRows.map((virtualRow) => {
                                                            const row =
                                                                rows[virtualRow.index];
                                                            return (
                                                                <tr
                                                                    key={row.id}
                                                                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                                >
                                                                    {row
                                                                        .getVisibleCells()
                                                                        .map((cell) => {
                                                                            return (
                                                                                <td
                                                                                    className="border p-2 whitespace-nowrap"
                                                                                    key={
                                                                                        cell.id
                                                                                    }
                                                                                >
                                                                                    {flexRender(
                                                                                        cell
                                                                                            .column
                                                                                            .columnDef
                                                                                            .cell,
                                                                                        cell.getContext()
                                                                                    )}
                                                                                </td>
                                                                            );
                                                                        })}
                                                                </tr>
                                                            );
                                                        })}
                                                        {paddingBottom > 0 && (
                                                            <tr>
                                                                <td
                                                                    className="border p-3"
                                                                    style={{
                                                                        height: `${paddingBottom}px`,
                                                                    }}
                                                                />
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) :
                                        (
                                            <NoDataFound />
                                        )
                                }
                            </div>
                        )}
                        {/* {rows.length === 0  && <NoDataFound />} */}
                        {Object.keys(rowSelection).length > 0 && (
                            <div className="flex justify-end lg:justify-start gap-2 m-3">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-lg border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                    onClick={TranscriptCopy}
                                >
                                    Copy Transcripts
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full mt-4 xl:mt-0 xl:w-1/2 border-t xl:border-none">
                    <CopyTranscriptsFileUploader
                        projectList={projectList}
                        sourceProject={sourceProject}
                        SetSourceProject={SetSourceProject}
                    />
                </div>
            </div>
        </div>
    );
}
