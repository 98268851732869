import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedUniqueValues
} from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { ArrowPathIcon, PencilIcon, TrashIcon, PlusCircleIcon, FunnelIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { initialpageSize } from '../Common/Contants';
import InputModal from '../Common/InputModal';
import Popup from '../Common/Popup';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import axios from 'axios';
import { API_URL } from '../../imports'
import { DebouncedInput, ProjectStatusFilter, SelectSelectedClientBasedTextFilter } from '../Common/Filters';
import FetchingDataLoader from '../Common/FetchingDataLoader';
import { handleApiError } from '../Common/APIUtils';
import PageSizeDropdown from '../Common/PageSizeDropdown';
import { formattedDate } from '../Common/CommonUtils';
import { Menu, Transition } from '@headlessui/react';
import { Portal } from "react-portal";
import Pagination from '../Common/Pagination';

export default function ProjectSetup() {
  let [isOpen, setIsOpen] = useState(false);
  let [rowData, setRowData] = useState([]);
  let [groups, setGroups] = useState([]);
  let [tags, setTags] = useState([]);
  let [selectedTag, setSelectedTag] = useState("");
  let [edit, setEdit] = useState(false);
  const [Loader, setLoader] = useState(true)
  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [editGPT, setEditGPT] = useState(false);
  let [client, setClient] = useState([]);
  let [assignMe, setAssignMe] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0 });
  const [activeDropdown, setActiveDropdown] = useState(null);
  const buttonRefs = useRef({});
  const dropdownRef = useRef(null);

  const calculatePosition = useCallback((id) => {
    const buttonRef = buttonRefs.current[id];
    if (buttonRef) {
        const rect = buttonRef.getBoundingClientRect();
        setDropdownPosition({
            top: rect.bottom + window.scrollY,
        });
        setActiveDropdown((prev) => (prev === id ? null : id));
    }
}, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    const handleKeyDown = (event) => {
      const keysToCloseDropdown = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp'];
      if (keysToCloseDropdown.includes(event.key)) {
        setActiveDropdown(null);
      }
    };
    const handleScroll = () => {
      setActiveDropdown(null);
    };
  
    if (activeDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [activeDropdown]);


  function closePopup() {
    setIsOpenPopup(false);
  }

  function openPopup() {
    setIsOpenPopup(true);
  }

  function closeModal() {
    setIsOpen(false);
    setRowData([]);
  }

  function openModal(e, option) {
    setRowData(e);
    if (option === 'edit') {
      if (localStorage.getItem('level') === '2') { setAssignMe(e.assignMyself) }
      setEditGPT(false);
      setEdit(true);
      setSelectedTag(e.proj_tag_code ? JSON.parse(e.proj_tag_code?.replace(/'/g, '"'))[0] : "");
    }
    else if (option === 'create') {
      if (localStorage.getItem('level') === '2') { setAssignMe(false) }
      setEditGPT(true);
      setEdit(false);
    }
    setIsOpen(true);
  }

  const ReloadData = async (loadprops) => {
    setLoader(loadprops);
    axios.get(`${API_URL}/projects/activegrouptag`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        client: sessionStorage.getItem('client')
      }
    })
      .then((response) => {
        setGroups(response.data.data.project_grp_list);
        setTags(response.data.data.project_tag_list);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        openPopup();
      });
    axios.get(`${API_URL}/projects`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        client: sessionStorage.getItem('client')
      }
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        const errorMessage = handleApiError(error);
        setMsg(errorMessage);
        openPopup();
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 100);
      });
  };

  const handleCreateState = async () => {
    setIsOpen(false);
    const formattedProjTagCode = rowData.proj_tag_code ? [rowData.proj_tag_code] : null;
    const formattedProjGrpCode = rowData.proj_grp_code ? rowData.proj_grp_code : null;
    const formattedProjGPTKey = rowData.gpt_key ? rowData.gpt_key : null;

    let create_form = { project_name: rowData.project_name, proj_grp_code: formattedProjGrpCode, proj_tag_code: formattedProjTagCode, gpt_key: formattedProjGPTKey, ...(localStorage.getItem('level') === '2' && { assignMyself: assignMe }), ...(localStorage.getItem('role') === 'ADMIN' && { client_code: rowData.client_code }) };
    try {
      await axios
        .post(`${API_URL}/projects`, create_form, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          }
        })
        .then((response) => {
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const handleEditState = async () => {
    const formattedProjTagCode = selectedTag ? [selectedTag] : null;
    const formattedProjGrpCode = rowData.proj_grp_code ? rowData.proj_grp_code : null;
    const formattedProjGPTKey = rowData.gpt_key ? rowData.gpt_key : null;
    let status = { project_name: rowData.project_name, proj_grp_code: formattedProjGrpCode, proj_tag_code: formattedProjTagCode, gpt_key: formattedProjGPTKey, ...(localStorage.getItem('level') === '2' && { assignMyself: assignMe }), ...(localStorage.getItem('role') === 'ADMIN' && { client_code: rowData.client_code }) };
    try {
      await axios
        .patch(`${API_URL}/projects/${rowData.project_code}`, status, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpen(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpen(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  let [modalTitle, setModalTitle] = useState('');

  function closeStatusModal() {
    setIsOpenStatusModal(false);
  }

  function openStatusModal(e) {
    setRowData(e);
    setModalTitle(`Update Project Status - ${e.project_code}`)
    setIsOpenStatusModal(true);
  }

  const handleStatusState = async () => {
    let statusData = { status: rowData.status };
    try {
      await axios
        .patch(`${API_URL}/projects/status/${rowData.project_code}`, statusData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenStatusModal(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenStatusModal(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  function closeConfirmPopup() {
    setIsOpenConfirmPopup(false);
  }

  function openConfirmPopup(e) {
    setRowData(e);
    setConfirmationMsg("Are you sure that you want to delete the project?")
    setIsOpenConfirmPopup(true);
  }

  const handleDeleteState = async () => {
    try {
      await axios
        .delete(`${API_URL}/projects/${rowData.project_code}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          setIsOpenConfirmPopup(false);
          setMsg(response.data.message);
          setIsSuccess(true);
          ReloadData(false);
          setIsOpenPopup(true);
        });
    } catch (error) {
      setIsOpenConfirmPopup(false);
      const errorMessage = handleApiError(error);
      setMsg(errorMessage);
      setIsSuccess(false);
      setIsOpenPopup(true);
    }
  };

  const [sorting, setSorting] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'index',
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        header: '#',
        cell: ({ row }) => <center>{row.index + 1}</center>,
      },
      localStorage.getItem('role') === 'ADMIN' && {
        accessorKey: 'client_name',
        enableColumnFilter: true,
        size: 100,
        header: 'Client'
      },
      {
        accessorKey: 'project_name',
        enableColumnFilter: false,
        size: 250,
        header: () => <span>Project Name</span>
      },
      {
        accessorKey: 'project_code',
        enableColumnFilter: false,
        size: 250,
        header: () => <span>Project Code</span>,
      },
      {
        accessorKey: 'proj_grp_code',
        enableColumnFilter: true,
        size: 150,
        header: () => <span>Group Code</span>
      },
      {
        accessorKey: 'proj_tag_code',
        enableColumnFilter: false,
        size: 150,
        header: () => <span>Tag Code</span>,
        cell: ({ row }) => (
          <div className="text-start">
            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-0 m-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
              {row.original.proj_tag_code ? JSON.parse(row.original.proj_tag_code?.replace(/'/g, '"'))[0] : ""}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'creation_date',
        enableColumnFilter: false,
        size: 100,
        header: 'Created Date',
        cell: ({ row }) => (
          <div>{formattedDate(row.original.creation_date)}</div>
        )
      },
      {
        accessorKey: 'creation_by',
        enableColumnFilter: false,
        size: 50,
        header: 'Created By',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        enableColumnFilter: true,
        filterFn: 'weakEquals',
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex justify-center'>
            <div
              onClick={() => openStatusModal(row.original)}
              className={`${row.original.status === 1 ? 'bg-green-300 text-green-900' : row.original.status === 2 ? 'bg-yellow-300 text-yellow-900' : 'bg-red-300 text-red-900'} text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}
            >
              {row.original.status === 1 && "Open"}
              {row.original.status === 0 && "Hold"}
              {row.original.status === 2 && "Close"}
            </div>
          </div>
        )
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        size: 50,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className='flex gap-2 items-center justify-center'>
            <Menu as="div">
              <div ref={(el) => (buttonRefs.current[row.original.id] = el)}>
                <Menu.Button className="border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    calculatePosition(row.original.id)
                  }
                  }
                >
                  <EllipsisHorizontalIcon className="h-4 text-black" />
                </Menu.Button>
              </div>
              <Portal>
                <Transition
                  as={Fragment}
                  show={activeDropdown === row.original.id}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items ref={dropdownRef}
                    className="absolute z-50 right-8 md:right-12 mt-1 w-fit rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{
                      top: `${dropdownPosition.top}px`,
                    }}
                  >
                    <button
                      className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-blue-800 w-full text-sm"
                      onClick={() => {
                        openModal(row.original, "edit")
                        setActiveDropdown(null)
                      }}
                    >
                      <PencilIcon className="h-4 text-blue-800 mr-2" />
                      <span>Edit</span>
                    </button>
                    {localStorage.getItem('role') === 'ADMIN' &&
                      <button className="flex items-center px-4 py-2 hover:bg-gray-200 hover:text-red-800 w-full text-sm"
                        onClick={() => {
                          openConfirmPopup(row.original)
                          setActiveDropdown(null)
                        }}>
                        <TrashIcon className='h-4 text-red-800 mr-2' />
                        <span>Delete</span>
                      </button>}
                  </Menu.Items>
                </Transition>
              </Portal>
            </Menu>
          </div>
        )
      },
    ].filter(Boolean),
    [activeDropdown, calculatePosition, dropdownPosition.top]
  );

  const [data, setData] = useState([]);

  const [globalFilter, setGlobalFilter] = React.useState('');
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(initialpageSize);


  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
  });

  const tableContainerRef = React.useRef(null);
  // Modify the pagination section
  const pageCount = Math.ceil(data.length / pageSize);
  const pageNumbers = [];

  for (let i = 0; i < pageCount; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (e) => {
    const newPageSize = Number(e.target.value);
    setPageSize(newPageSize);
    setPageIndex(0)
  };

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: pageSize,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const renderTableCellIndex = (row) => {
    return (
      table.getFilteredRowModel().rows.indexOf(row) + 1 ||
      table.getSortedRowModel().rows.indexOf(row) + 1 ||
      row.index + 1
    );
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      axios
        .get(`${API_URL}/projects`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          }
        })
        .then((response) => {
          console.log(response.data.data)
          setData(response.data.data);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    const fetchActiveOptions = async () => {
      axios
        .get(`${API_URL}/projects/activegrouptag`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            client: sessionStorage.getItem('client')
          }
        })
        .then((response) => {
          setGroups(response.data.data.project_grp_list);
          setTags(response.data.data.project_tag_list);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        });
    };
    const fetchClientData = async () => {
      axios
        .get(`${API_URL}/clients`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          const filteredClients = response.data.data.filter(client => client.status === true);
          setClient(filteredClients);
        })
        .catch((error) => {
          const errorMessage = handleApiError(error);
          setMsg(errorMessage);
          openPopup();
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 100);
        });
    };
    fetchClientData();
    fetchInitialData();
    fetchActiveOptions();
  }, []);

  const [clname, setclname] = useState('');

  return (
    <div className="border rounded-lg bg-white font-GoogleSans tracking-wider overflow-hidden">
      <ConfirmationPopup
        isOpenConfirmPopup={isOpenConfirmPopup}
        confirmationMsg={confirmationMsg}
        closeConfirmPopup={closeConfirmPopup}
        onStateChange={handleDeleteState}
      />
      <Popup
        isOpen={isOpenPopup}
        msg={msg}
        closeModal={closePopup}
        isSuccess={isSuccess}
      />
      <InputModal isOpen={isOpen} closeModal={closeModal} title={edit === false ? 'Create project' : 'Edit project'} savefn={edit === false ? handleCreateState : handleEditState}
        modalInputs={
          <div className='space-y-2'>
            {localStorage.getItem('role') === 'ADMIN' &&
              <div>
                <label htmlFor="client" className="block text-sm font-medium leading-6 text-gray-900">
                  Client
                </label>
                <select
                  id="client"
                  name="client"
                  required
                  className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                  value={rowData.client_code}
                  onChange={(e) => setRowData({ ...rowData, client_code: e.target.value })}
                >
                  <option value="">Select Client</option>
                  {client.map((item, index) => (
                    <option key={index} value={item.client_code}>
                      {item.client_name}
                    </option>
                  ))}
                </select>
              </div>}
            <div>
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Project Name
              </label>
              <input type="text" className='block w-full rounded-md border border-gray-300 py-1.5 mt-1 ring-0 sm:text-sm sm:leading-6' defaultValue={rowData.project_name} onChange={(e) => setRowData({ ...rowData, project_name: e.target.value })} />
            </div>
            <div>
              <label htmlFor="gpt-key" className="block text-sm font-medium leading-6 text-gray-900">
                GPT Key
              </label>
              <div className='flex items-center gap-x-2'>
                <input
                  type='text'
                  required
                  id='gpt-key'
                  disabled={!editGPT}
                  value={rowData.gpt_key}
                  onChange={(e) => setRowData({ ...rowData, gpt_key: e.target.value })}
                  className={`block w-full rounded-md border border-gray-300 disabled:bg-gray-300 py-1.5 mt-1 ring-0 sm:text-sm sm:leading-6`}
                />
                {edit && <div className='rounded-md border border-gray-300 p-2 text-blue-600' onClick={() => { setRowData({ ...rowData, gpt_key: '' }); setEditGPT(true); }}><PencilIcon className='h-5 w-auto' /></div>}
              </div>
            </div>
            <div>
              <select
                id="project"
                name="project"
                className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                value={rowData.proj_grp_code}
                onChange={(e) => setRowData({ ...rowData, proj_grp_code: e.target.value })}
              >
                <option value="">Select Project Group</option>
                {groups.map((item, index) => (
                  <option key={index} value={item.proj_grp_code}>
                    {item.proj_grp_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="project"
                name="project"
                className="block w-auto md:w-full rounded-md border-0 py-2 mt-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs lg:max-w-full sm:text-sm sm:leading-6"
                value={edit === false ? rowData.proj_tag_code : selectedTag}
                onChange={(e) => edit === false ? setRowData({ ...rowData, proj_tag_code: e.target.value }) : setSelectedTag(e.target.value)}
              >
                <option value="">Select Project Tag</option>
                {tags.map((item, index) => (
                  <option key={index} value={item.proj_tag_code}>
                    {item.proj_tag_name}
                  </option>
                ))}
              </select>
            </div>
            {localStorage.getItem('level') === '2' &&
              <label htmlFor="assign_me" className="text-sm font-medium leading-6 text-gray-900 flex items-center gap-0.5 pt-1">
                <input
                  id="assign_me"
                  name="assign_me"
                  type="checkbox"
                  checked={assignMe}
                  onChange={(e) => setAssignMe(e.target.checked)}
                  className="h-4 w-4 me-1.5 rounded border text-indigo-600 focus:ring-indigo-600"
                />Assign Myself to this project
              </label>
            }
          </div>
        }
      />
      <InputModal isOpen={isOpenStatusModal} closeModal={closeStatusModal} title={modalTitle} savefn={handleStatusState}
        modalInputs={
          <div className='flex gap-5'>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value={1}
                checked={rowData.status === 1 || rowData.status === "1"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                Open
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value={0}
                checked={rowData.status === 0 || rowData.status === "0"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                Hold
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="status"
                name="status"
                type="radio"
                value={2}
                checked={rowData.status === 2 || rowData.status === "2"}
                onChange={(e) =>
                  setRowData({
                    ...rowData,
                    status: e.target.value,
                  })
                }
                autoFocus={false}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
              />
              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                Close
              </label>
            </div>
          </div>
        }
      />
      <div className='flex justify-between m-3 gap-2'>
        <div>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder="Search..."
          />
        </div>
        <div className='flex gap-2'>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => ReloadData(true)}>
            <ArrowPathIcon className='h-5 text-green-700' />
          </button>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="relative justify-center border border-gray-300 rounded-md p-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                <span className="absolute -inset-1.5" />
                <FunnelIcon className="h-5 w-auto" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="fixed right-8 z-30 mt-3 w-fit px-2 pb-2 space-y-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                  {data.length > 0 &&
                    table
                      .getHeaderGroups()
                      .map((headerGroup) => (
                        <div
                          key={headerGroup.id}
                          className="space-y-1"
                        >
                          {headerGroup.headers.map(
                            (header) => (
                              <div
                                key={header.id}
                              >
                                {header.column.getCanFilter() &&
                                  header.column.id === "status" ? (
                                  <div>
                                    <label
                                      htmlFor="push-everything"
                                      className="block text-xs font-bold tracking-widest leading-6 text-gray-400 ps-1"
                                    >
                                      Status
                                    </label>
                                    <ProjectStatusFilter
                                      column={
                                        header.column
                                      }
                                      table={
                                        table
                                      }
                                    />
                                  </div>
                                ) : null}
                                {header.column.getCanFilter() &&
                                  (header.column.id === "client_name" ||
                                    header.column.id === "proj_grp_code") ? (
                                  <div>
                                    <label
                                      htmlFor="push-everything"
                                      className="block text-xs font-medium tracking-widest leading-6 text-gray-400 ps-1"
                                    >
                                      {header.column.id === "client_name" ? "Client" : ""}
                                      {header.column.id === "proj_grp_code" ? "Group Code" : ""}
                                    </label>
                                    <SelectSelectedClientBasedTextFilter
                                      column={header.column}
                                      data={data}
                                      clname={clname}
                                      setclname={setclname}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <button className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border" onClick={() => openModal('e', 'create')}>
            <PlusCircleIcon className='h-5' />
          </button>
        </div>
      </div>
      {Loader && <FetchingDataLoader />}
      {!Loader &&
        <>
          <div ref={tableContainerRef} className='resp-table-h border-y overflow-auto relative'>
            <table className='flex-inline w-full border-collapse'>
              <thead className='bg-gray-100'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className='border p-2 whitespace-nowrap'
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={`${header.column.getCanSort()
                                  ? 'cursor-pointer select-none justify-start'
                                  : 'justify-center'
                                  } flex`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] || null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  rows.length === 0 ?
                    (
                      <tr>
                        <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                          No Data Found
                        </td>
                      </tr>
                    )
                    :
                    (
                      <>
                        {paddingTop > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingTop}px` }} />
                          </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                          const row = rows[virtualRow.index];
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td className='border p-2 whitespace-nowrap' key={cell.id}>
                                    {cell.column.id === "index" && (
                                      <center>{renderTableCellIndex(row)}</center>
                                    )}
                                    {cell.column.id !== "index" &&
                                      flexRender(
                                        cell.column
                                          .columnDef
                                          .cell,
                                        cell.getContext()
                                      )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        {paddingBottom > 0 && (
                          <tr>
                            <td className='border p-2' style={{ height: `${paddingBottom}px` }} />
                          </tr>
                        )}
                      </>
                    )
                }

              </tbody>
            </table>
          </div>
          {
            rows.length > 0 && (
              <div className='flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-3'>
                {/* <nav className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900" aria-label="Pagination">
                  <button title='First Page' onClick={() => setPageIndex(0)}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button title='Previous Page'
                    onClick={() => setPageIndex(pageIndex - 1)}
                    disabled={pageIndex === 0}
                    aria-current="page"
                    className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {pageNumbers.length === 1 &&
                    <button
                      className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      key={1}
                      onClick={() => setPageIndex(0)}
                    >
                      {1}
                    </button>
                  }
                  {pageNumbers.length > 1 && pageNumbers.map((pageNumber, index) => {
                    if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                      if (table.getPageCount() !== 0 && table.getPageCount() === 1 && table.getPageCount() === pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(pageNumber - 1)}
                          >
                            {pageNumber}
                          </button>
                        );
                      }
                      else if (table.getPageCount() !== 1 && table.getPageCount() > pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(pageNumber)}
                          >
                            {pageNumber + 1}
                          </button>
                        );
                      }
                      else if (table.getPageCount() === 0 && table.getPageCount() === pageNumber) {
                        return (
                          <button
                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            key={index}
                            onClick={() => setPageIndex(0)}
                          >
                            {1}
                          </button>
                        );
                      }
                    } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                      return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                    }
                    return null;
                  })}
                  <button title='Next Page' onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === (table.getPageCount() - 1)}
                    className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button title='Last Page' onClick={() => setPageIndex(table.getPageCount() - 1)}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav> */}
                <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  pageNumbers={pageNumbers}
                  pageCount={table.getPageCount()}
                />
                <PageSizeDropdown
                  dataLength={table.getPrePaginationRowModel().rows.length}
                  pageSize={pageSize}
                  handlePageChange={handlePageChange}
                  totalRows={table.getPrePaginationRowModel().rows.length}
                />
              </div>
            )
          }
        </>
      }
    </div >
  );
}