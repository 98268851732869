import React, { useEffect, useState, Fragment } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import {
    ArrowPathIcon,
    PencilIcon,
    TrashIcon,
    PlusCircleIcon,
    EllipsisHorizontalIcon,
    ServerIcon,
    NoSymbolIcon
} from "@heroicons/react/24/outline";
import { initialpageSize } from "../Common/Contants";
import InputModal from "../Common/InputModal";
import Popup from "../Common/Popup";
import axios from "axios";
import { API_URL } from "../../imports";
import { DebouncedInput } from "../Common/Filters";
import { handleApiError } from "../Common/APIUtils";
import FetchingDataLoader from "../Common/FetchingDataLoader";
import PageSizeDropdown from "../Common/PageSizeDropdown";
import { formattedDate } from "../Common/CommonUtils";
import { Menu, Transition } from "@headlessui/react";
import ServiceStartConfirmationPopup from "../Common/ServiceStartConfirmationPopup";
import Pagination from "../Common/Pagination";

function ServerUrlMgmt() {
    const keyCodeList = [
        { id: 1, keycode: "TRANSCRIPT" },
        { id: 2, keycode: "GETANSWERS" },
        { id: 3, keycode: "GETIDEAS" },
        { id: 4, keycode: "GETOPINIONS" }
    ];

    const serviceMapping = {
        'TRANSCRIPT': 'vti.service',
        'GETANSWERS': 'gds.service',
        'GETIDEAS': 'vti_idea.service'
    };

    let [isOpen, setIsOpen] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [edit, setEdit] = useState(false);
    const [Loader, setLoader] = useState(true)
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    const [actionType, setActionType] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [isStarting, setIsStarting] = useState(false);

    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    function closeModal() {
        setIsOpen(false);
        setRowData([]);
    }

    function openModal(e, option) {
        setRowData(e);
        if (option === "edit") {
            setEdit(true);
        } else if (option === "create") {
            setEdit(false);
        }
        setIsOpen(true);
    }

    const ReloadData = async () => {
        setLoader(true);
        axios.get(`${API_URL}/serverUrl/read_serverUrl`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                )}`,
            },
        })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const responseStateHandler = (responseMessage, responseState) => {
        setMsg(responseMessage);
        setIsSuccess(responseState);
        openPopup();
    };

    const handleCreateState = async () => {
        closeModal();
        if (!rowData.server_url || !rowData.keycode) {
            responseStateHandler("Service URL and Keycode is required", false);
            return;
        }

        try {
            const requestData = {
                key_code: rowData.keycode,
                server_url: rowData.server_url,
            };

            await axios
                .post(`${API_URL}/serverUrl/server_url_create`, requestData, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                })
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };
    const handleEditState = async () => {
        closeModal();
        if (rowData.server_url.trim() === "") {
            return;
        }

        const requestData = {
            server_url: rowData.server_url,
        };

        try {
            await axios
                .put(
                    `${API_URL}/serverUrl/update_server_info/${rowData.id}`,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    let [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
    let [modalTitle, setModalTitle] = useState("");

    function closeStatusModal() {
        setIsOpenStatusModal(false);
    }

    function openStatusModal(e) {
        setRowData(e);
        setModalTitle(`Update Service URL - ${e.keycode}`);
        setIsOpenStatusModal(true);
    }

    const handleStatusState = async () => {
        let statusData = {
            status:
                rowData.status === false || rowData.status === "false"
                    ? false
                    : true,
        };
        setIsOpenStatusModal(false);
        try {
            await axios
                .patch(
                    `${API_URL}/serverUrl/server_url_status/${rowData.id}`,
                    statusData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    function closeConfirmPopup() {
        setIsOpenConfirmPopup(false);
    }

    function openConfirmPopup(e) {
        setRowData(e);
        setConfirmationMsg(
            "Are you sure that you want to delete Service URL information?"
        );
        setIsOpenConfirmPopup(true);
    }

    const handleDeleteState = async () => {
        setIsOpenConfirmPopup(false);
        try {
            await axios
                .delete(
                    `${API_URL}/serverUrl/delete_server_url/${rowData.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    responseStateHandler(response.data.message, true);
                    ReloadData();
                });
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const [sorting, setSorting] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 40,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "keycode",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Key Code</span>,
            },
            {
                accessorKey: "server_url",
                enableColumnFilter: false,
                size: 450,
                header: () => <span>Service URL</span>,
                cell: ({ row }) => (
                    <div className="flex">{row.original.server_url}&nbsp;&nbsp;
                        {row.original.status === true &&
                            <div className={`${row.original.server_url_status === true
                                ? "bg-green-300  text-green-900"
                                : "bg-red-300 text-red-900"
                                } text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}
                            >
                                {row.original.server_url_status === true
                                    ? "Running"
                                    : "Stopped"}
                            </div>
                        }
                    </div>
                )
            },
            {
                accessorKey: "creation_by",
                enableColumnFilter: false,
                size: 50,
                header: "Created By",
                cell: ({ row }) => (
                    <span
                        className="capitalize"
                        title={row.original.creation_by}
                    >
                        {row.original.creation_by !== null
                            ? row.original.creation_by.slice(0, 12) +
                            (row.original.creation_by.length > 12
                                ? "..."
                                : "")
                            : null}
                    </span>
                ),
            },
            {
                accessorKey: "creation_date",
                enableColumnFilter: false,
                size: 50,
                header: "Created Date",
                cell: ({ row }) => (
                    <div>{formattedDate(row.original.creation_date)}</div>
                ),
            },
            {
                accessorKey: "modified_by",
                enableColumnFilter: false,
                size: 50,
                header: "Modification By",
                cell: ({ row }) => (
                    <span
                        className="capitalize"
                        title={row.original.modified_by}
                    >
                        {row.original.modified_by !== null
                            ? row.original.modified_by.slice(0, 12) +
                            (row.original.modified_by.length > 12
                                ? "..."
                                : "")
                            : null}
                    </span>
                ),
            },
            {
                accessorKey: "modified_date",
                enableColumnFilter: false,
                size: 50,
                header: "Modification Date",
                cell: ({ row }) => (
                    <div>
                        {row.original.modified_date
                            ? formattedDate(row.original.modified_date)
                            : ""}
                    </div>
                ),
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex justify-center">
                        <div
                            onClick={() => openStatusModal(row.original)}
                            className={`${row.original.status === true
                                ? "bg-green-300  text-green-900"
                                : "bg-red-300 text-red-900"
                                } text-xs w-fit px-2 py-1 font-bold rounded-xl cursor-default lg:cursor-pointer`}
                        >
                            {row.original.status === true
                                ? "Active"
                                : "Inactive"}
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "id",
                header: "Actions",
                size: 50,
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="flex gap-2 items-center justify-center">
                        <Menu as="div" className="relative">
                            <div>
                                <Menu.Button className="relative border bg-gray-50 rounded-md p-1 text-blue-800 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer">
                                    <EllipsisHorizontalIcon className="h-4 text-black" />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="fixed z-30 right-8 md:right-12 mt-1 w-fit px-2 py-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <button
                                        className="flex items-center px-4 py-2 w-full text-sm"
                                        onClick={() => openModal(row.original, "edit")}
                                    >
                                        <PencilIcon className="h-4 text-blue-800 mr-2 rounded-md border" />
                                        <span>Edit</span>
                                    </button>
                                    <button
                                        className="flex items-center px-4 py-2 w-full text-sm"
                                        onClick={() => openConfirmPopup(row.original)}
                                    >
                                        <TrashIcon className="h-4 text-red-600 mr-2 rounded-md border" />
                                        <span>Delete</span>
                                    </button>
                                    <>
                                        {row.original.server_url_status && row.original.status && row.original.service_url_status ? (
                                            <>
                                                <button
                                                    className="flex items-center px-4 py-2 text-sm w-full"
                                                    onClick={() => handleUrlClick(row.original, "restart")}
                                                >
                                                    <ServerIcon className="h-4 text-black mr-2 rounded-md border" />
                                                    <span>Restart Service</span>
                                                </button>
                                                <button
                                                    className="flex items-center px-4 py-2 text-sm w-full"
                                                    onClick={() => handleUrlClick(row.original, "stop")}
                                                >
                                                    <NoSymbolIcon className="h-4 text-red-600 mr-2 rounded-md border" />
                                                    <span>Stop Service</span>
                                                </button>
                                            </>
                                        ) : row.original.server_url_status && row.original.status && !row.original.service_url_status ? (
                                            <button
                                                className="flex items-center px-4 py-2 text-sm w-full"
                                                onClick={() => handleUrlClick(row.original, "start")}
                                            >
                                                <ServerIcon className="h-4 text-black mr-2 rounded-md border" />
                                                <span>Start Service</span>
                                            </button>
                                        ) : row.original.server_url_status === false && row.original.status ? (
                                            null
                                        ) : null}
                                    </>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                ),
            },
        ],
        []
    );

    const [data, setData] = useState([]);

    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();
        return (
            <input
                type="text"
                value={columnFilterValue}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        const fetchInitialData = async () => {
            axios.get(`${API_URL}/serverUrl/read_serverUrl`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
                .then((response) => {
                    setData(response.data.data);
                })
                .catch((error) => {
                    const errorMessage = handleApiError(error);
                    setMsg(errorMessage);
                    setIsSuccess(false);
                    openPopup();
                })
                .finally(() => {
                    setLoader(false);
                });
        };
        fetchInitialData();
    }, []);

    const handleUrlClick = (service, action) => {
        setSelectedService(service);

        switch (action) {
            case "start":
                setConfirmationMsg("Do you want to start the service?");
                setActionType("start");
                break;
            case "restart":
                setConfirmationMsg("Do you want to restart the service?");
                setActionType("restart");
                break;
            case "stop":
                setConfirmationMsg("Do you want to stop the service?");
                setActionType("stop");
                break;
            default:
                break;
        }
        setIsOpenConfirmPopup(true);
    };

    const handleStateChange = async (actionType) => {
        if (selectedService) {
            if (['start', 'restart', 'stop'].includes(actionType)) {
                setIsStarting(true);
            }
            try {
                const serviceName = serviceMapping[selectedService.keycode];
                let response;
                if (actionType === 'start') {
                    response = await axios.get(`${API_URL}/ec2_service/start`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                        params: {
                            service_name: serviceName
                        }
                    });
                } else if (actionType === 'restart') {
                    response = await axios.get(`${API_URL}/ec2_service/restart`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                        params: {
                            service_name: serviceName
                        }
                    });
                } else if (actionType === 'stop') {
                    response = await axios.get(`${API_URL}/ec2_service/stop`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        },
                        params: {
                            service_name: serviceName
                        }
                    });
                }
                if (response.data.status === 'error') {
                    setMsg(response.data.message);
                    setIsSuccess(false);
                } else {
                    setMsg(response.data.message);
                    setIsSuccess(true);
                }
            } catch (error) {
                console.error('Error occurred:', error.message);
                console.log(error.response);
            } finally {
                closeConfirmPopup()
                setIsStarting(false);
            }

        }
    };

    return (
        <div className="border bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ServiceStartConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                confirmationMsg={confirmationMsg}
                closeConfirmPopup={() => setIsOpenConfirmPopup(false)}
                callback={actionType === 'delete' ? handleDeleteState : handleStateChange}
                checkVisible={false}
                checkState={false}
                onCheckChange={() => { }}
                actionType={actionType}
                isStarting={isStarting}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            <InputModal
                isOpen={isOpen}
                closeModal={closeModal}
                title={
                    edit === false ? "Add Service URL" : "Update Service URL"
                }
                savefn={edit === false ? handleCreateState : handleEditState}
                modalInputs={
                    <div className="space-y-5">
                        {!edit && (
                            <div>
                                <label htmlFor="keycode" className="block text-sm font-medium leading-6 text-gray-900">
                                    Service name
                                </label>
                                <select
                                    id="keycode"
                                    name="keycode"
                                    className="block mt-1 w-auto md:w-full md:tracking-wider rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                                    value={rowData.keycode}
                                    onChange={(e) => setRowData({ ...rowData, keycode: e.target.value })}
                                >
                                    <option value="">Select Service</option>
                                    {keyCodeList.map((item, index) => (
                                        <option key={index} value={item.keycode}>
                                            {item.keycode}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                            <label
                                htmlFor="server-url"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Service URL
                            </label>
                            <input
                                type="text"
                                id="server-url"
                                value={rowData.server_url}
                                onChange={(e) => setRowData({ ...rowData, server_url: e.target.value })}
                                placeholder="Enter Service URL"
                                className="mt-1 p-2 border rounded-md w-full"
                            />
                        </div>
                    </div>
                }
            />
            <InputModal
                isOpen={isOpenStatusModal}
                closeModal={closeStatusModal}
                title={modalTitle}
                savefn={handleStatusState}
                modalInputs={
                    <div className="flex gap-5">
                        <div className="flex items-center gap-x-2">
                            <input
                                id="status"
                                name="status"
                                type="radio"
                                value="true"
                                checked={
                                    rowData.status === true ||
                                    rowData.status === "true"
                                }
                                onChange={(e) =>
                                    setRowData({
                                        ...rowData,
                                        status: e.target.value,
                                    })
                                }
                                autoFocus={false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                            />
                            <label
                                htmlFor="push-everything"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Active
                            </label>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <input
                                id="status"
                                name="status"
                                type="radio"
                                value="false"
                                checked={
                                    rowData.status === false ||
                                    rowData.status === "false"
                                }
                                onChange={(e) =>
                                    setRowData({
                                        ...rowData,
                                        status: e.target.value,
                                    })
                                }
                                autoFocus={false}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:outline-none focus:ring-0 focus:border-none"
                            />
                            <label
                                htmlFor="push-email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Inactive
                            </label>
                        </div>
                    </div>
                }
            />
            <div className="flex justify-between m-3 gap-2">
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search..."
                    />
                </div>
                <div className="flex gap-2">
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                    {localStorage.getItem('role') !== 'NONADMIN' && <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => openModal("e", "create")}
                    >
                        <PlusCircleIcon className="h-5" />
                    </button>
                    }
                </div>
            </div>
            {Loader && <FetchingDataLoader />}
            {/* {!Loader && data.length===0 && <NoDataFound/>} */}
            {!Loader && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={
                                                                            table
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {
                                    rows.length === 0 ?
                                        (
                                            <tr>
                                                <td className='border p-2' colSpan={table.getHeaderGroups()[0].headers.length} style={{ textAlign: 'center' }}>
                                                    No Data Found
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            <>
                                                {paddingTop > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingTop}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                                {virtualRows.map((virtualRow) => {
                                                    const row = rows[virtualRow.index];
                                                    return (
                                                        <tr key={row.id}>
                                                            {row
                                                                .getVisibleCells()
                                                                .map((cell) => {
                                                                    return (
                                                                        <td
                                                                            className="border p-2 whitespace-nowrap"
                                                                            key={cell.id}
                                                                        >
                                                                            {flexRender(
                                                                                cell.column
                                                                                    .columnDef
                                                                                    .cell,
                                                                                cell.getContext()
                                                                            )}
                                                                        </td>
                                                                    );
                                                                })}
                                                        </tr>
                                                    );
                                                })}
                                                {paddingBottom > 0 && (
                                                    <tr>
                                                        <td
                                                            className="border p-2"
                                                            style={{
                                                                height: `${paddingBottom}px`,
                                                            }}
                                                        />
                                                    </tr>
                                                )}
                                            </>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        rows.length > 0 && (
                            <div className="flex gap-1 justify-between flex-row-reverse m-3">
                                {/* <nav
                                    className="isolate inline-flex -space-x-px mx-2 md:m-0 bg-white rounded-md md:shadow-sm text-gray-900"
                                    aria-label="Pagination"
                                >
                                    <button
                                        title="First Page"
                                        onClick={() => setPageIndex(0)}
                                        className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <button
                                        title="Previous Page"
                                        onClick={() => setPageIndex(pageIndex - 1)}
                                        disabled={pageIndex === 0}
                                        aria-current="page"
                                        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronLeftIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    {pageNumbers.length === 1 &&
                                        <button
                                            className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                            key={1}
                                            onClick={() => setPageIndex(0)}
                                        >
                                            {1}
                                        </button>
                                    }
                                    {pageNumbers.length > 1 && pageNumbers.map((pageNumber, index) => {
                                        if (pageNumber === 0 || pageNumber === table.getPageCount() - 1 || Math.abs(pageNumber - pageIndex) <= 1) {
                                            if (table.getPageCount() !== 0 && table.getPageCount() === 1 && table.getPageCount() === pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(pageNumber - 1)}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                );
                                            }
                                            else if (table.getPageCount() !== 1 && table.getPageCount() > pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(pageNumber)}
                                                    >
                                                        {pageNumber + 1}
                                                    </button>
                                                );
                                            }
                                            else if (table.getPageCount() === 0 && table.getPageCount() === pageNumber) {
                                                return (
                                                    <button
                                                        className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                                        key={index}
                                                        onClick={() => setPageIndex(0)}
                                                    >
                                                        {1}
                                                    </button>
                                                );
                                            }
                                        } else if (pageNumber === 1 || pageNumber === table.getPageCount() - 2) {
                                            return <button className='relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0' key={index}>...</button>;
                                        }
                                        return null;
                                    })}
                                    <button
                                        title="Next Page"
                                        onClick={() => setPageIndex(pageIndex + 1)}
                                        disabled={
                                            pageIndex === table.getPageCount() - 1
                                        }
                                        className="relative inline-flex items-center px-2 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <button
                                        title="Last Page"
                                        onClick={() =>
                                            setPageIndex(table.getPageCount() - 1)
                                        }
                                        className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                    >
                                        <ChevronDoubleRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </nav> */}
                                <Pagination
                                    pageIndex={pageIndex}
                                    setPageIndex={setPageIndex}
                                    pageNumbers={pageNumbers}
                                    pageCount={table.getPageCount()}
                                />
                                {/* <div>
                                    <div className="sm:col-span-3">
                                        <div className="">
                                            <select
                                                id="records"
                                                name="records"
                                                value={pageSize}
                                                onChange={handlePageChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                {pageSizes.map((page) => (
                                                    <option
                                                        className="p-1"
                                                        key={page}
                                                        value={page}
                                                    >
                                                        Show {page}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <PageSizeDropdown
                                    dataLength={table.getPrePaginationRowModel().rows.length}
                                    pageSize={pageSize}
                                    handlePageChange={handlePageChange}
                                    totalRows={table.getPrePaginationRowModel().rows.length}
                                />
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
}

export default ServerUrlMgmt;
