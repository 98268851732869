import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import images, { API_URL } from '../../imports'
import axios from 'axios';
import Popup from '../Common/Popup';
import { handleApiError } from '../Common/APIUtils';
import { connect } from 'react-redux';

function Projects({ projectFilterOptions }) {

  const threshold = 20;

  const BreakText = (text) => {
    const words = text.split(' ');

    if (words.some(word => word.length > threshold)) {
      return <p className="text-center break-all hyphens-auto">{text}</p>;
    } else {
      return <p className="text-center break-words">{text}</p>;
    }
  };

  const formatProjectDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [month, year] = formattedDate.split(' ');
    return `${month}, ${year}`;
  };

  const storeProject = (project) => {
    sessionStorage.setItem('projectCode', project.project_code);
    sessionStorage.setItem('projectName', project.project_name);
    sessionStorage.setItem('projectStatus', project.permission_status);
  };

  const [projects, setProjects]=useState([]);
  const [projectData, setProjectData]=useState([]);

useEffect(()=>{
const filteredProjects = projectData?.filter(project => {
  let projectGroupMatch = !projectFilterOptions.proj_grp_code || project.proj_grp_code === projectFilterOptions.proj_grp_code;
  let projectMatch = !projectFilterOptions.project_code || project.project_code === projectFilterOptions.project_code;
  // let projectTagMatch = !projectFilterOptions.proj_tag_code || project.proj_tag_code === projectFilterOptions.proj_tag_code;
  let statusMatch = projectFilterOptions.project_status === undefined || project.project_status === JSON.parse(projectFilterOptions.project_status);
  return projectGroupMatch && projectMatch && statusMatch;
});
setProjects(filteredProjects);
},[projectData,projectFilterOptions])

  let [isOpenPopup, setIsOpenPopup] = useState(false);
  let [msg, setMsg] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);

  const [Loader,setLoader] = useState(true);

  function closePopup() {
      setIsOpenPopup(false);
  }

  const openPopup = useCallback(() => {
      setIsOpenPopup(true);
  }, [setIsOpenPopup]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_URL}/projectusers/projects`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                params: {
                  client: sessionStorage.getItem('client')
                }
            });
            setProjectData(response.data.data);
            setProjects(response.data.data);
        } catch (error) {
            const errorMessage = handleApiError(error);
            setMsg(errorMessage);
            setIsSuccess(false);
            openPopup();
        }
        finally{
          setTimeout(() => {
            setLoader(false);
          }, 500);
        }
    };

    fetchData();
  }, [openPopup]);

  useEffect(()=>{
    sessionStorage.removeItem('projectName');
    sessionStorage.removeItem('projectCode');
    sessionStorage.removeItem('projectStatus');
  },[])

  return (
    <div>
      <Popup isOpen={isOpenPopup} msg={msg} closeModal={closePopup} isSuccess={isSuccess}/>
      {Loader? <p className='text-lg tracking-wider px-1'>Loading Projects...</p>
      :
      <>
      {projects.length===0 && <p className='text-lg tracking-wider px-1'>No Projects assigned to You</p>}
      {projects.length>0 &&
      <div className='grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'>
      {projects.map((project, index) => (
        <Link
          to={`/projects/${project.project_name}`}
          key={index}
          onClick={() => storeProject(project)} // Storing the project code in sessionStorage when the link is clicked
          className='relative bg-indigo-900 rounded-md font-bold cursor-default lg:cursor-pointer flex flex-col tracking-widest space-y-4 items-center text-white py-16 px-3'
        >
          {project.project_status!==1 &&
          <>
          <div className={`absolute top-0 right-0 rounded-t-md ${project.project_status===1?'active-triangle-right':'inactive-triangle-right'}`}></div>
          <p className={`absolute ${project.project_status===1?'-right-0.5 top-0.5 rotate-[45.5deg]':'right-1 top-0 rotate-[45.5deg]'} text-[0.60rem]`}>{project.project_status===0?'Hold':'Close'}</p>
          </>
          }
          <img width="40" height="40" src={images['group-of-projects.png']} alt="group-of-projects"/>
          {BreakText(project.project_name)}
          {/* <p className='absolute -top-4 left-0 rounded-tl-md rounded-br-md py-1 px-2 bg-black text-xs'>{project.creation_date}</p> */}
          {/* <p className='absolute -top-2 left-2 rounded-md py-1 px-2 bg-black text-xs'>{project.creation_date}</p> */}
          <p className='rounded-full py-1 px-2 bg-white text-indigo-900 text-xs' title={project.creation_date}>{formatProjectDate(project.creation_date)}</p>
        </Link>
      ))}
      </div>
      }
      </>
      }
    </div>
  )
}

// Define mapStateToProps to map Redux state to component props
const mapStateToProps = (state) => {
  return {
    projectFilterOptions: state, // adjust this based on your Redux state structure
  };
};

export default connect(mapStateToProps)(Projects);