import React, { useEffect, useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "@tanstack/react-virtual";
import { ArrowPathIcon, PlusCircleIcon, DocumentArrowUpIcon } from "@heroicons/react/24/outline";
import { initialpageSize } from "../Common/Contants";
import InputModal from "../Common/InputModal";
import Popup from "../Common/Popup";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import axios from "axios";
import { API_URL } from "../../imports";
import { handleApiError } from "../Common/APIUtils";
import NoDataFound from "../Common/NoDataFound";
import PageSizeDropdown from "../Common/PageSizeDropdown";
import { formattedDate } from "../Common/CommonUtils";
import Pagination from "../Common/Pagination";

export default function Batches() {
    let [isOpen, setIsOpen] = useState(false);
    let [rowData, setRowData] = useState([]);
    let [isOpenPopup, setIsOpenPopup] = useState(false);
    let [msg, setMsg] = useState(false);
    let [isSuccess, setIsSuccess] = useState(false);
    let [show, setShow] = useState(true);
    let [modalType, setModalType] = useState("");


    function closePopup() {
        setIsOpenPopup(false);
    }

    function openPopup() {
        setIsOpenPopup(true);
    }

    function closeModal() {
        setIsOpen(false);
        setRowData([]);
        setFiles([]);
        setModalType("");
    }

    function openModal(type) {
        // setRowData(e);
        setIsOpen(true);
        setModalType(type);
    }
    const [selectedBatchprop, setSelectedBatchprop] = useState("Select Batch");
    const ReloadData = async () => {
        axios
            .get(
                `${API_URL}/Batching/fetchFiles/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setData(response.data.data);
                setSelectedBatchprop("Select Batch");
                if (response.data.data.length === 0) {
                    setShow(false);
                } else {
                    setShow(true);
                }
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                setShow(false);
                openPopup();
            });
        GetBatchSize();
        GetNumberOfBatches();
    };

    const UpdateBatches = async () => {
        axios
            .get(
                `${API_URL}/Batching/updateBatches/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setMsg(response.data.message);
                setIsSuccess(true);
                openPopup();
                ReloadData();
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
        ReloadData();
    };

    const [getBatchSize, setGetBatchSize] = useState(null);
    const GetBatchSize = async () => {
        axios
            .get(
                `${API_URL}/Batching/getBatchSize/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setGetBatchSize(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };

    const [files, setFiles] = useState([]);

    const responseStateHandler = (responseMessage, responseState) => {
        setMsg(responseMessage);
        setIsSuccess(responseState);
        openPopup();
    };

    const [filePerBatch, setFilePerBatch] = useState(0);
    const handleCreateState = async () => {
        if (filePerBatch > 0) {
            try {
                const response = await axios.post(
                    `${API_URL}/Batching/createBatches/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    { batchsize: filePerBatch },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                responseStateHandler(response.data.message, true);
                ReloadData();
            } catch (error) {
                const errorMessage = handleApiError(error);
                responseStateHandler(errorMessage, false);
            }
        } else {
            responseStateHandler("No Batch Size Provided", false);
        }
    };

    const [batches, setBatches] = useState([]);
    const GetNumberOfBatches = async () => {
        axios
            .get(
                `${API_URL}/Batching/getNumberOfBatches/${sessionStorage.getItem(
                    "projectCode"
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setBatches(response.data.data);
            })
            .catch((error) => {
                const errorMessage = handleApiError(error);
                setMsg(errorMessage);
                setIsSuccess(false);
                openPopup();
            });
    };

    const handleSelectedBatch = async (selectedBatch) => {
        setSelectedBatchprop(selectedBatch);
        if (!selectedBatch || isNaN(selectedBatch)) {
            ReloadData();
            return;
        }
        if (selectedBatch !== "Select Batch") {
            try {
                const response = await axios.post(
                    `${API_URL}/Batching/getBatchWiseData/${sessionStorage.getItem(
                        "projectCode"
                    )}`,
                    { batchnumber: selectedBatch },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "access_token"
                            )}`,
                        },
                    }
                );
                setData(response.data.data);
                if (response.data.data.length === 0) {
                    setShow(false);
                } else {
                    setShow(true);
                }
            } catch (error) {
                const errorMessage = handleApiError(error);
                setShow(false);
                responseStateHandler(errorMessage, false);
            }
        }
    };

    const handleBatchProcessing = async () => {
        if (files.length === 0) {
            responseStateHandler("No file selected", false);
            return;
        }

        const formData = new FormData();
        Array.from(files).forEach((file) => {
            formData.append("file", file);
        });

        try {
            const response = await axios.post(
                `${API_URL}/Batching/uploadExcel/${sessionStorage.getItem("projectCode")}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                }
            );
            responseStateHandler(response.data.message, true);
            ReloadData();
        } catch (error) {
            const errorMessage = handleApiError(error);
            responseStateHandler(errorMessage, false);
        }
    };

    const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
    function closeConfirmPopup() {
        setIsOpenConfirmPopup(false);
    }

    const [sorting, setSorting] = React.useState([]);
    const columns = React.useMemo(
        () => [
            {
                accessorKey: "index",
                enableColumnFilter: false,
                enableSorting: false,
                size: 40,
                header: "#",
                cell: ({ row }) => <center>{row.index + 1}</center>,
            },
            {
                accessorKey: "filename",
                enableColumnFilter: false,
                size: 1000,
                header: () => <span>Filename</span>,
                cell: ({ row }) => <div>{row.original.filename}</div>,
            },
            {
                accessorKey: "filesize",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Filesize</span>,
                cell: ({ row }) => <div>{row.original.filesize}</div>,
            },
            {
                accessorKey: "batch_no",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Batch No.</span>,
                cell: ({ row }) => <center>{row.original.batch_no}</center>,
            },
            {
                accessorKey: "is_unique",
                enableColumnFilter: false,
                size: 100,
                header: () => <span>Is Unique</span>,
                cell: ({ row }) => (
                    <center>{row.original.is_unique ? "1" : ""}</center>
                ),
            },
            {
                accessorKey: "modified_date",
                enableColumnFilter: false,
                size: 100,
                header: "Modified Date",
                cell: ({ row }) => (
                    <div>
                        {formattedDate(row.original.modified_date)}
                    </div>
                ),
            },
        ],
        []
    );

    const [data, setData] = useState([]);

    const [globalFilter, setGlobalFilter] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialpageSize);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
    });

    const tableContainerRef = React.useRef(null);
    // Modify the pagination section
    const pageCount = Math.ceil(data.length / pageSize);
    const pageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(i);
    }

    const handlePageChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    function Filter({ column }) {
        const columnFilterValue = column.getFilterValue();
        return (
            <input
                type="text"
                value={columnFilterValue}
                onChange={(e) => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                className="w-full border rounded-md text-xs"
            />
        );
    }

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: pageSize,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0
            ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
            : 0;

    useEffect(() => {
        ReloadData();
    }, []);
    useEffect(() => {
        GetBatchSize();
    }, []);

    return (
        <div className="border bg-white font-GoogleSans tracking-wider overflow-hidden">
            <ConfirmationPopup
                isOpenConfirmPopup={isOpenConfirmPopup}
                closeConfirmPopup={closeConfirmPopup}
            />
            <Popup
                isOpen={isOpenPopup}
                msg={msg}
                closeModal={closePopup}
                isSuccess={isSuccess}
            />
            {modalType === "upload" && (
                <InputModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    title={"Batch Configuration"}
                    savefn={handleCreateState}
                    modalInputs={
                        <div className="space-y-5">
                            <>
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Number of files per Batch
                                    </label>
                                    <input
                                        type="number"
                                        name="filesPerBatch"
                                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                                        onChange={(e) =>
                                            setFilePerBatch(e.target.value)
                                        }
                                    />
                                </div>
                            </>
                        </div>
                    }
                />)}
            {modalType === "config" && (
                <InputModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    title={"Upload Excel File"}
                    savefn={handleBatchProcessing}
                    modalInputs={
                        <div className="space-y-5">
                            <div>
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Upload Excel File
                                </label>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                                    onChange={(e) => setFiles(e.target.files[0])}
                                />
                            </div>
                        </div>
                    }
                />)}
            <div
                className={`flex ${getBatchSize === null || getBatchSize === 0
                    ? ""
                    : "justify-between"
                    } m-3 gap-2 flex-row flex-wrap`}
            >
                {getBatchSize !== null && getBatchSize !== 0 && (
                    <div>
                        <form className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <div className="flex col-span-1 gap-2 items-center">
                                <label
                                    htmlFor="batch-size"
                                    className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
                                >
                                    Batch Size
                                </label>
                                <input
                                    type="text"
                                    id="batch-size"
                                    name="batch-size"
                                    disabled
                                    readOnly
                                    defaultValue={getBatchSize}
                                    className="p-1.5 border rounded-md w-full disabled:bg-gray-100"
                                />
                            </div>

                            <div className="col-span-1">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-lg border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 cursor-default lg:cursor-pointer"
                                    onClick={() => UpdateBatches()}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <div className="flex gap-2">
                    {getBatchSize !== null && getBatchSize !== 0 && (
                        <div className="col-span-1">
                            <select
                                id="batch"
                                name="batch"
                                className="block w-auto md:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                value={selectedBatchprop}
                                onChange={(e) =>
                                    handleSelectedBatch(e.target.value)
                                }
                            >
                                <option defaultValue={"Select Batch"}>
                                    Select Batch
                                </option>
                                {batches.map((batch, index) => (
                                    <option key={index} value={batch}>
                                        {batch}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => ReloadData()}
                    >
                        <ArrowPathIcon className="h-5 text-green-700" />
                    </button>
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => openModal("config")}
                    >
                        <DocumentArrowUpIcon className="h-5" />
                    </button>
                    <button
                        className="rounded-md cursor-default lg:cursor-pointer p-2 inline-flex text-sm border-gray-300 border"
                        onClick={() => openModal("upload")}
                    >
                        <PlusCircleIcon className="h-5" />
                    </button>
                </div>
            </div>
            {!show && <NoDataFound />}
            {show && (
                <>
                    <div
                        ref={tableContainerRef}
                        className="resp-table-h-config border-y overflow-auto"
                    >
                        <table className="flex-inline w-full border-collapse">
                            <thead className="shadow bg-gray-100">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    className="border p-2 whitespace-nowrap"
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                className={`${header.column.getCanSort()
                                                                    ? "cursor-pointer select-none justify-start"
                                                                    : "justify-center"
                                                                    } flex`}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        .header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[
                                                                    header.column.getIsSorted()
                                                                ] || null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div className="mt-2">
                                                                    <Filter
                                                                        column={
                                                                            header.column
                                                                        }
                                                                        table={
                                                                            table
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {paddingTop > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            style={{
                                                height: `${paddingTop}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                                {virtualRows.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (
                                        <tr key={row.id}>
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td
                                                            className="border p-2 whitespace-nowrap"
                                                            key={cell.id}
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                                {paddingBottom > 0 && (
                                    <tr>
                                        <td
                                            className="border p-2"
                                            style={{
                                                height: `${paddingBottom}px`,
                                            }}
                                        />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex gap-1 flex-wrap md:flex-nowrap justify-between flex-row-reverse m-1 md:m-3">
                        <Pagination
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            pageNumbers={pageNumbers}
                            pageCount={table.getPageCount()}
                        />
                        <PageSizeDropdown
                            dataLength={table.getPrePaginationRowModel().rows.length}
                            pageSize={pageSize}
                            handlePageChange={handlePageChange}
                            totalRows={table.getPrePaginationRowModel().rows.length}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
